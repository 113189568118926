import {FileType, getAllowedExtensions} from '@root/features/fileUpload'
import {FC, memo, useCallback, useMemo, useRef} from 'react'
import {FileUploader, FileUploaderItem, uploaderStatuses} from 'ui-kit'
import {isEmpty, isString} from 'lodash'
import {useIsMounted} from '@root/hooks/useIsMounted'
import {fileServiceApi} from '@root/api/fileServiceApi'
import useNotify from '@root/hooks/useNotify'
import {AxiosResponse, isAxiosError} from 'axios'
import {formatToMb} from 'ui-kit/src/FIleUploader/helpers'

type Props = {
  type: FileType
  multiple?: boolean
  onChange?: (items: FileUploaderItem[]) => void
  value: FileUploaderItem[]
}

export function mapFileBackToFormUri(x: FileUploaderItem): string {
  return x.objectURL
}

export function mapUrlToFileUploaderItem(url: string) {
  return {
    status: uploaderStatuses.done,
    objectURL: url,
    file: new File([''], url.split('/').pop(), {type: 'image/png'}), // TODO: STUB BECAUSE OF THIS SHITTY FileUploader component. File should be null
  }
}

type FileMap = Map<string, FileUploaderItem>

function getFileKey(value: FileUploaderItem) {
  return value.file ? value.file.name : value.objectURL
}

function getFilesMap(value: FileUploaderItem[]) {
  const map: FileMap = new Map()

  for (const x of value) {
    if (isEmpty(x)) {
      continue
    }
    map.set(getFileKey(x), x)
  }

  return map
}

const TmpFileUpload: FC<Props> = ({value = [], type, onChange, multiple = false}) => {
  const accept = getAllowedExtensions(type)
  const isMountedRef = useIsMounted()
  const {notifyError} = useNotify()

  const filesMap = useMemo(() => getFilesMap(value), [value])

  const onChangeCallback = useMemo(
    () => (callback: (prev: FileMap) => FileMap) => {
      onChange(Array.from(callback(filesMap).values()))
    },
    [filesMap, onChange]
  )

  const callbackRef = useRef(onChangeCallback)
  callbackRef.current = onChangeCallback

  const maxFileSizeMb = type === FileType.IMAGE ? 5 : 10

  const upload = useCallback(
    async (file: File) => {
      const mutateFile = (mutateValue: (current: FileUploaderItem) => FileUploaderItem) => {
        if (!isMountedRef.current) {
          return
        }

        callbackRef.current((prev) => {
          const newMap = new Map(prev)
          const prevVal = newMap.get(file.name)

          if (prevVal) {
            newMap.set(file.name, mutateValue(prevVal))
            return newMap
          }

          return prev
        })
      }

      const onError = (details?: string) => {
        mutateFile((current) => ({...current, status: uploaderStatuses.error}))

        let errMsg = 'Не удалось загрузить файл на сервер.'

        if (details) {
          errMsg += ` \nДетали: ${details}`
        }

        notifyError(errMsg)
      }

      const notifyErrorWithDetails = (response: AxiosResponse) => {
        onError(isString(response?.data) ? response.data : undefined)
      }

      try {
        const response = await fileServiceApi.uploadTmpFile(type, file)

        if (response.status !== 201) {
          console.error('Not 201 status code')

          notifyErrorWithDetails(response)

          return
        }

        mutateFile((current) => ({
          ...current,
          status: uploaderStatuses.done,
          objectURL: response.data,
        }))
      } catch (e) {
        console.error('Unable to upload file', e)

        if (isAxiosError(e)) {
          notifyErrorWithDetails(e.response)
        } else {
          console.error('not an axios error', e)

          throw e
        }
      }
    },
    [isMountedRef, notifyError, type]
  )

  const handleSelect = useCallback(
    (files: File[]) => {
      const newFilesSet = new Set(files.map((x) => x.name))

      const result: FileUploaderItem[] = value
      const toCreateArr: File[] = []
      const toRemoveSet: Set<string> = new Set()

      for (const existing of filesMap.keys()) {
        if (newFilesSet.has(existing)) {
          return
        }

        toRemoveSet.add(existing)
      }

      for (const file of files) {
        if (file === null) {
          continue
        }

        if (isEmpty(file.name)) {
          continue
        }

        const found = filesMap.get(file.name)

        if (isEmpty(found)) {
          toCreateArr.push(file)
        }
      }

      if (!isEmpty(toCreateArr)) {
        for (const toCreate of toCreateArr) {
          // TODO: FIXME AND REMOVE REPLACE FileUploader
          if (formatToMb(toCreate.size) > maxFileSizeMb) {
            result.push({
              file: toCreate,
              status: uploaderStatuses.error,
            })
          } else {
            result.push({
              file: toCreate,
              status: uploaderStatuses.loading,
            })

            upload(toCreate).then()
          }
        }
      }

      onChange(result.filter((x) => !toRemoveSet.has(x.file.name)))
    },
    [maxFileSizeMb, filesMap, onChange, upload, value]
  )

  const handleRemove = useCallback(
    (file: File) => {
      const newMap = new Map(filesMap)

      newMap.delete(file.name)
      onChange(Array.from(newMap.values()))
    },
    [filesMap, onChange]
  )

  return (
    <FileUploader
      accept={accept}
      items={value}
      maxFileSize={maxFileSizeMb}
      multiple={multiple}
      width="100%"
      onRemove={handleRemove}
      onSelect={handleSelect}
    />
  )
}

export default memo(TmpFileUpload)
