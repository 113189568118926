import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'
import {emptyApi} from '../emptyApi'
import {ReportTags, LaunchesTags} from '../tags'
import {components} from '@root/openapi'

export type CreateReportResponse = OpenApiResponseType<'/v1/reports', 'post'>
export type ChangeReportConfigurationResponse = OpenApiResponseType<
  '/v1/reports/{reportId}/change-configuration',
  'post'
>
export type GetReportsListResponse = OpenApiResponseType<'/v1/reports/list', 'post'>
export type GetReportByIdResponse = OpenApiResponseType<'/v1/reports/{reportId}'>
export type GetReportFileByNameResponse = OpenApiResponseType<'/v1/reports/files/{nameInStore}'>
export type UpdateReportResponse = OpenApiResponseType<'/v1/reports/{reportId}', 'patch'>
export type DeleteReportResponse = OpenApiResponseType<'/v1/reports/{reportId}', 'delete'>
export type LockReportResponse = OpenApiResponseType<'/v1/reports/{reportId}/edit', 'post'>
export type UnlockReportResponse = OpenApiResponseType<'/v1/reports/{reportId}/unlock', 'post'>
export type GetReportsByIdResponse = OpenApiResponseType<'/v1/reports/by-id', 'post'>
export type GetReportStatisticsImageLinksResponse =
  OpenApiResponseType<'/v1/reports/statistics-images/{reportId}'>

export type CreateReportPayload = OpenApiRequestBody<'/v1/reports'>

export type ChangeReportConfigurationPayload = {
  reportId: string
} & OpenApiRequestBody<'/v1/reports/{reportId}/change-configuration'>

export type GetReportsListPayload = OpenApiRequestBody<'/v1/reports/list'>
export type UpdateReportPayload = {
  reportId: string
  // updateData: OpenApiRequestBody<'/v1/reports/{reportId}', 'patch'>
  formData: any // fixme
}

export type GetReportsByIdPayload = components['schemas']['IdListRequest']['ids']

export const reportsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getReportById: build.query<GetReportByIdResponse, string>({
      query: (reportId) => `/reports/${reportId}`,
      providesTags: (result) => (result ? [{type: ReportTags.ReportById, id: result.id}] : []),
    }),

    getReportFileByName: build.query<GetReportFileByNameResponse, string>({
      query: (nameInStore) => {
        return {
          url: `/reports/files/${nameInStore}`,
          responseHandler: async (response) => await response.blob(),
        }
      },
      providesTags: [ReportTags.UnionReportsList, ReportTags.Reports],
    }),

    createReport: build.mutation<CreateReportResponse, CreateReportPayload>({
      query: ({launchId, configurationId}) => ({
        url: '/reports',
        method: 'POST',
        body: {launchId, configurationId},
      }),
      invalidatesTags: [LaunchesTags.Launches, ReportTags.UnionReportsList, ReportTags.Reports],
    }),

    updateReport: build.mutation<UpdateReportResponse, UpdateReportPayload>({
      query: ({reportId, formData}) => ({
        url: `/reports/${reportId}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: (_result, _error, {reportId}) => [
        {type: ReportTags.ReportById, id: reportId},
        ReportTags.Reports,
        ReportTags.UnionReportsList,
      ],
    }),

    lockReport: build.mutation<LockReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}/edit`,
        method: 'POST',
      }),
      invalidatesTags: [ReportTags.UnionReportsList, ReportTags.Reports],
    }),

    unlockReport: build.mutation<UnlockReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}/unlock`,
        method: 'POST',
      }),
      async onQueryStarted(reportId, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled
          // Дожидаемся выполнения запросов, чтобы инвалидация сработала
          await Promise.all(dispatch(emptyApi.util.getRunningQueriesThunk()))
          dispatch(emptyApi.util.invalidateTags([ReportTags.UnionReportsList, ReportTags.Reports]))
        } catch {
          console.warn('unable to invalidate queries')
        }
      },
      invalidatesTags: [ReportTags.UnionReportsList, ReportTags.Reports],
    }),

    changeReportConfiguration: build.mutation<
      ChangeReportConfigurationResponse,
      ChangeReportConfigurationPayload
    >({
      query: ({reportId, configurationId}) => ({
        url: `/reports/${reportId}/change-configuration`,
        method: 'POST',
        body: {configurationId},
      }),
      invalidatesTags: (_result, _error, {reportId}) => [
        {type: ReportTags.ReportById, id: reportId},
        ReportTags.Reports,
        ReportTags.UnionReportsList,
      ],
    }),

    getReports: build.query<GetReportsListResponse, GetReportsListPayload>({
      query: (filter) => ({
        url: '/reports/list',
        method: 'POST',
        body: filter,
      }),
      providesTags: [ReportTags.UnionReportsList, ReportTags.Reports],
    }),

    getReportsById: build.query<GetReportsByIdResponse, GetReportsByIdPayload>({
      query: (filter) => ({
        url: '/reports/by-id',
        method: 'POST',
        body: {ids: filter},
      }),
    }),

    deleteReport: build.mutation<DeleteReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, reportId) => [
        {type: ReportTags.ReportById, id: reportId},
        ReportTags.Reports,
        ReportTags.UnionReportsList,
      ],
    }),

    getReportStatisticsImageLinks: build.query<GetReportStatisticsImageLinksResponse, string>({
      query: (reportId) => ({
        url: `reports/statistics-images/${reportId}`,
      }),
    }),
  }),
  overrideExisting: 'throw' as unknown as boolean,
})

export const {
  useGetReportByIdQuery,
  useGetReportFileByNameQuery,
  useLockReportMutation,
  useUnlockReportMutation,
  useCreateReportMutation,
  useUpdateReportMutation,
  useChangeReportConfigurationMutation,
  useGetReportsQuery,
  useGetReportsByIdQuery,
  useDeleteReportMutation,
} = reportsApi

export default reportsApi
