import {Typography} from '@x5-react-uikit/core'
import {CSSProperties, FC, PropsWithChildren} from 'react'

type Props = PropsWithChildren<{
  style?: CSSProperties
  typographyStyle?: CSSProperties
  title?: string
  noPadding?: boolean
}>

const CellTypography: FC<Props> = ({children, style, typographyStyle, title, noPadding}) => (
  <div style={{...(style ?? {}), maxWidth: '100%'}}>
    <Typography
      style={{
        paddingLeft: noPadding ? 0 : '12px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        whiteSpace: 'pre-wrap',
        ...(typographyStyle ?? {}),
      }}
      title={title}
      variant="p2"
    >
      {children}
    </Typography>
  </div>
)

export default CellTypography
