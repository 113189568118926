import {Switch} from '@x5-react-uikit/core'
import {Controller, ControllerProps, FieldPath, FieldValues} from 'react-hook-form'
import {SwitchProps} from '@root/components/kit'

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  size?: SwitchProps['size']
  inputProps: Omit<SwitchProps, 'size' | 'checked' | 'onChange'>
} & Pick<ControllerProps<TFieldValues>, 'control'>
function FormInputSwitcher<TFieldValues extends FieldValues>({
  name,
  control,
  ...inputProps
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}}) => {
        return <Switch checked={value} size="small" onChange={onChange} {...inputProps} />
      }}
    />
  )
}

export default FormInputSwitcher
