import {FileWithComment} from '@root/features/fileUpload'
import {FC, memo, useState} from 'react'
import FlexboxColumn from '@root/components/FlexboxColumn'
import LoaderFrame from '@root/components/Loader'
import {Typography} from '@x5-react-uikit/core'
import {useInView} from '@react-spring/web'
import ProtectedImage from '@root/components/files/ProtectedImage'
import {Box} from 'ui-kit'
import TextUrlEnhancer from '@root/components/TextUrlEnhancer'

type Props = {
  value: FileWithComment
}

const ImageWithComment: FC<Props> = ({value}) => {
  const [imageRef, isImageInView] = useInView()
  const [loading, setLoading] = useState(false)

  return (
    <div ref={imageRef}>
      <FlexboxColumn sx={{gap: '16px'}}>
        <div>
          <Typography variant="h3">{value.name}</Typography>

          {loading && <LoaderFrame backgroundColor="grey" height="30px" />}

          <Box sx={{my: 'x8'}}>
            <ProtectedImage
              alt={value.name}
              shouldFetch={isImageInView}
              src={value.url}
              style={{maxWidth: '100%'}}
              onLoadingChange={setLoading}
            />
          </Box>

          <FlexboxColumn sx={{gap: '16px'}}>
            <Typography variant="p1">Комментарий:</Typography>
            <Typography style={{whiteSpace: 'pre-wrap'}} variant="p1">
              <TextUrlEnhancer text={value.comment} />
            </Typography>
          </FlexboxColumn>
        </div>
      </FlexboxColumn>
    </div>
  )
}

export default memo(ImageWithComment)
