import {useAuthGuard} from '@root/hooks/useAuthGuard'
import {useGetReportByIdQuery} from '@root/redux/api/report/reportsApi'
import {isClientErrorCode} from '@root/utils'
import {useParams} from 'react-router-dom'

const useGetReportGuard = () => {
  const {reportId} = useParams()
  const reportQueryResult = useGetReportByIdQuery(reportId)

  const isClientError = isClientErrorCode((reportQueryResult.error as any)?.status)

  useAuthGuard({
    redirectUri: '/reports',
    hasAccess: reportQueryResult.isLoading || !isClientError,
  })

  return {
    report: reportQueryResult.data,
    error: reportQueryResult.error,
    isChecking: reportQueryResult.isLoading || isClientError,
  }
}
export default useGetReportGuard
