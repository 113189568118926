import {FieldNamesMarkedBoolean} from 'react-hook-form'

export function getFormDirtyValues<
  TFormValues extends object,
  TDirtyValues extends Partial<Readonly<FieldNamesMarkedBoolean<TFormValues>>>,
>(dirtyFields: TDirtyValues | boolean | unknown[], allValues: TFormValues) {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getFormDirtyValues(dirtyFields[key], allValues[key]),
    ])
  )
}
