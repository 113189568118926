export enum MetaValueType {
  text = 'text',
  table = 'table',
}

type WithId = {
  id: string
}

export type BaseMeta<Type extends MetaValueType = MetaValueType> = WithId & {
  type: Type
}

type TableColumnMeta = WithId & {
  label: string
  type: MetaValueType.text
  rows: string[]
}

export type TableMeta = BaseMeta<MetaValueType.table> & {
  addButtonLabel?: string
  columns: Record<string, TableColumnMeta>
}

export type MetaObject = TableMeta
