import {AppTableState} from '@root/components/appTable'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {System} from '@root/features/systems/types'
import {components, FilterItemType, FilterWithSortPagedRequestSort} from '@root/openapi'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {GetSystemListPayload} from '@root/redux/api/systemsApi'

type FilterItem = components['schemas']['FilterItem']

// TODO: Same code structure as from LaunchHistoryTable's fieldMapper. Could be refactored, but later

function sortValueMapper(
  tableFormat: ExtractConstValues<typeof sortDirections>
): FilterWithSortPagedRequestSort {
  switch (tableFormat) {
    case 'asc':
      return FilterWithSortPagedRequestSort.ASC
    case 'desc':
      return FilterWithSortPagedRequestSort.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof System): string {
  return field
}

function getExpressionForField(
  field: FilterCollectorFilter
): [string, components['schemas']['FilterItem']] {
  const fieldName = field.name as keyof System
  const value = field.value as any

  switch (fieldName) {
    case 'name':
      return ['name', {value, type: FilterItemType.LIKE}]
    case 'tag':
      return ['tag', {value, type: FilterItemType.LIKE}]
    case 'cod':
      return ['cod', {value, type: FilterItemType.LIKE}]
    case 'mfsmStatus':
      return ['mfsmStatus', {value, type: FilterItemType.EQUAL}]
    case 'criticalityClass':
      return ['criticalityClass', {value, type: FilterItemType.EQUAL}]
    case 'status':
      return ['status', {value, type: FilterItemType.EQUAL}]
    case 'expirationDate':
      return ['expirationDate', {value, type: FilterItemType.EQUAL}]
    default:
      throw new Error('Out of range')
  }
}

export function fieldMapper(tableState: AppTableState<System>): GetLaunchesPayload['filter'] {
  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, expression]: [string, FilterItem]) => !isEmpty(expression.value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  const sortExpressions: GetSystemListPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  return {
    filters: filterExpressions,
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }
}
