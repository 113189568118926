import {useLazyVerifyCppQuery, VerifyCppResponse} from '@root/redux/api/budgetApi'
import {useEffect, useMemo} from 'react'

export type CppVerification = {
  isCppVerifying: boolean
  cppVerified: boolean | null
  cppVerifyData: VerifyCppResponse
}

const useVerifyCppCode = (cpp: string): CppVerification => {
  const [cppVerify, cppVerifyQueryResult] = useLazyVerifyCppQuery()
  useEffect(() => {
    if (cpp) cppVerify({cpp})
  }, [cpp, cppVerify])

  const cppVerified = useMemo<CppVerification['cppVerified']>(() => {
    if (cppVerifyQueryResult.isFetching || cppVerifyQueryResult.isUninitialized) return null

    if (!cppVerifyQueryResult.isError) return true

    return (cppVerifyQueryResult.error as any).status === 404 ? false : null
  }, [cppVerifyQueryResult])

  return {
    isCppVerifying: cppVerifyQueryResult.isFetching,
    cppVerified,
    cppVerifyData: cppVerifyQueryResult.currentData,
  }
}

export default useVerifyCppCode
