import type {FC, PropsWithChildren} from 'react'
import {Tooltip} from 'ui-kit'

type Props = PropsWithChildren<{
  email: string
}>

export const EditorTooltip: FC<Props> = ({email, children}) => {
  return (
    <Tooltip
      doNotClone
      usePortal
      content={`${email} сейчас редактирует отчет. Редактирование или удаление недоступно.`}
      zIndex={100000}
    >
      {children}
    </Tooltip>
  )
}
