import {getFormIndiciesById} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {
  GetReceiveOwnersResponse,
  useGetBudgetOwnersQuery,
  useVerifyBudgetMutation,
  VerifyCppResponse,
} from '@root/redux/api/budgetApi'
import {useGetTaskFormDataQuery} from '@root/redux/api/formsApi'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import useVerifyCppCode, {CppVerification} from './useVerifyCppCode'

export type FinportalData = {
  cpp: string
  cppVerified: CppVerification['cppVerified']
  cppVerifyData: VerifyCppResponse | null
  isCppVerifying: boolean
  workCost: number
  isBudgetVerifying: boolean
  budgetOwners: GetReceiveOwnersResponse['receiveOwners'] | null
  budgetVerify: () => Promise<VerifyCppResponse>
  budgetResetVerify: () => void
  budgetVerifyResult: VerifyCppResponse | null
}

const useFinportalData = (): FinportalData => {
  const {taskId} = useParams()
  const {currentData: task} = useGetTaskByIdQuery(taskId)
  const {currentData: taskFormData} = useGetTaskFormDataQuery({taskId})
  const finportalParams = useMemo(() => {
    if (!taskFormData?.length || !task) return
    const {stepIndex, fieldIndex} = getFormIndiciesById(taskFormData, 'budget_source')
    return {
      cpp: taskFormData[stepIndex].fields[fieldIndex].values.text[0].value,
      workCost: task.workCost,
    }
  }, [task, taskFormData])

  const {cppVerified, cppVerifyData, isCppVerifying} = useVerifyCppCode(finportalParams?.cpp)

  const {currentData: budgetOwnersData} = useGetBudgetOwnersQuery(
    {cpp: finportalParams?.cpp},
    {skip: !cppVerified}
  )

  const [
    budgetVerify,
    {isLoading: isBudgetVerifying, data: budgetVerifyResult, reset: budgetResetVerify},
  ] = useVerifyBudgetMutation()

  return useMemo(() => {
    return {
      ...finportalParams,
      cppVerified,
      cppVerifyData,
      isCppVerifying,
      isBudgetVerifying,
      budgetOwners: budgetOwnersData?.receiveOwners,
      budgetVerifyResult,
      budgetVerify: () => budgetVerify(finportalParams).unwrap(),
      // .then((result) => !result && notifyWarning('Данные по СПП не получены'))
      // .catch(() => notifyWarning('Данные по СПП не получены')),
      budgetResetVerify,
    }
  }, [
    budgetOwnersData?.receiveOwners,
    budgetResetVerify,
    budgetVerify,
    budgetVerifyResult,
    cppVerified,
    cppVerifyData,
    finportalParams,
    isBudgetVerifying,
    isCppVerifying,
  ])
}

export default useFinportalData
