import {FC, useMemo} from 'react'
import {UnionReport} from '@root/features/reports/types'
import {canCompare, CompareErrorReason} from '@root/features/reports/reportComparison'
import {BarChart as BarChartIcon} from '@x5-react-uikit/icons'
import {Button, Tooltip} from 'ui-kit'
import {result} from '@root/core/result'
import styled from '@emotion/styled'
import RouterLink from '@root/components/RouterLink'

type Props = {
  reports: UnionReport[]
}

const messageMap: Record<CompareErrorReason, string> = {
  [CompareErrorReason.Min]: 'Не менее 2 отчетов',
  [CompareErrorReason.Max]: 'Не больше 5 отчетов',
  [CompareErrorReason.DifferentSystems]: 'Отчеты из разных ИС не сравниваются',
  [CompareErrorReason.NotReport]: 'Выбраны сравнительные отчеты',
}

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

function validator(reports: UnionReport[]) {
  if (reports.length > 0) {
    return canCompare(reports)
  }

  return result.ok(null)
}

export const CompareButton: FC<Props> = ({reports}) => {
  const validationResult = useMemo(() => validator(reports), [reports])

  const errorMessages = useMemo(() => {
    const messageNodesRes = result.mapError(validationResult, (codes) => (
      <StyledList>
        {codes.map((x) => (
          <div key={x}>{messageMap[x]}</div>
        ))}
      </StyledList>
    ))

    if (result.isError(messageNodesRes)) {
      return messageNodesRes.value
    }

    return null
  }, [validationResult])

  const disabled = useMemo(() => {
    if (reports.length > 0) {
      return result.isError(validationResult)
    }

    return true
  }, [reports.length, validationResult])

  const link = useMemo(() => {
    if (disabled) {
      return null
    }

    const searchParams = new URLSearchParams(reports.map((x) => ['id', x.id]))

    return '/reports/comparative/create?' + searchParams
  }, [disabled, reports])

  return (
    <Tooltip doNotClone usePortal content={errorMessages} placement="top-end">
      <RouterLink noUnderline disabled={disabled} to={link}>
        <Button disabled={disabled} startIcon={<BarChartIcon size="small" />} variant="text">
          В сравнение
        </Button>
      </RouterLink>
    </Tooltip>
  )
}
