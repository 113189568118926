import FlexboxColumn from '@root/components/FlexboxColumn'
import {Typography} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import GrafanaUrlArray from '@root/components/inputs/GrafanaUrlArray'
import FileWithCommentArray from '@root/components/inputs/FileWithCommentArray'
import {useComparativeReportContext} from '@root/pages/report/ComparativeViewEditPage/context'
import {FileType} from '@root/features/fileUpload'
import {EditableField} from '@root/pages/report/ComparativeViewEditPage/components/EditableField'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'

import type {FormData} from '../types'
import {FC, memo} from 'react'
import {ComparativeReport} from '@root/features/reports/types'
import {isEmpty} from 'lodash'
import ImageWithComment from '@root/components/files/ImageWithComment'
import {RenderDivider} from 'ui-kit/src/RenderDivider'
import TextUrlEnhancer from '@root/components/TextUrlEnhancer'

type GrafanaIframeArrayProps = {
  graphs: ComparativeReport['data']['graphs']
}
const GrafanaIframeArray: FC<GrafanaIframeArrayProps> = ({graphs}) => {
  return (
    <>
      {graphs.map((stat, statIndex) => {
        const last = statIndex === graphs.length - 1
        return (
          <FlexboxColumn key={statIndex} sx={{gap: '24px'}}>
            <FlexboxColumn sx={{gap: '16px'}}>
              <Typography variant="h3">{stat.graphName}</Typography>
              <iframe src={stat.graphUrl} style={{height: '400px'}} title={stat.graphName}></iframe>

              <FlexboxColumn sx={{gap: '16px'}}>
                <Typography variant="p1">Комментарий:</Typography>
                <Typography style={{whiteSpace: 'pre-wrap'}} variant="p1">
                  <TextUrlEnhancer text={stat.comment} />
                </Typography>
              </FlexboxColumn>
            </FlexboxColumn>
            {!last && <Divider />}
          </FlexboxColumn>
        )
      })}
    </>
  )
}

export const StatisticsTab = memo(() => {
  const {comparativeReport, control, isEdit} = useComparativeReportContext()

  const graphs = comparativeReport?.data?.graphs ?? []
  const images = comparativeReport?.data?.files ?? []

  return (
    <FlexboxColumn style={{gap: '24px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        {isEdit ? (
          <>
            <GrafanaUrlArray control={control} limit={5} name="reportData.graphs" />

            <Divider />

            <FileWithCommentArray
              control={control}
              fileType={FileType.IMAGE}
              limit={5}
              name="reportData.files"
            />
          </>
        ) : (
          <>
            {!isEmpty(graphs) && (
              <>
                <GrafanaIframeArray graphs={graphs} />
                <Divider />
              </>
            )}
            {!isEmpty(images) && (
              <>
                <RenderDivider arr={images} getKey={(x) => x.url}>
                  {(image) => <ImageWithComment value={image} />}
                </RenderDivider>
                <Divider />
              </>
            )}
          </>
        )}
      </FlexboxColumn>

      <FlexboxColumn sx={{gap: '12px'}}>
        <Typography style={{fontWeight: 700}} variant="p1">
          Общий комментарий:
        </Typography>
        <EditableField value={comparativeReport.data.commonData?.statisticsComment}>
          <FormInputText<FormData>
            textarea
            control={control}
            label="Общий комментарий"
            name="reportData.commonData.statisticsComment"
            width="520px"
          />
        </EditableField>
      </FlexboxColumn>
    </FlexboxColumn>
  )
})
