import {useAuthGuard} from '@root/hooks/useAuthGuard'
import {useCanEditReport} from './useCanEditReport'
import type {Report, ComparativeReport} from '../types'

export const useEditReportGuard = (
  report: Report | ComparativeReport | undefined | null,
  disable: boolean = false
) => {
  const canEdit = useCanEditReport(report)

  useAuthGuard({
    redirectUri: '/reports',
    hasAccess: disable || canEdit,
  })
}
