import {Link as LinkButton, Tooltip} from '@x5-react-uikit/core'
import LoaderFrame from '@root/components/Loader'
import VariablesModal from '@root/pages/report/ReportCreatePage/components/VariablesModal'
import {getTasksFilter} from '@root/pages/SpecialistToolsPage/utils'
import {FC, useCallback, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useGetLaunchesQuery} from '@root/redux/api/launchesApi'
import {useGetSystemActiveTaskQuery} from '@root/redux/api/systemsApi'
import {useGetTasksByFilterQuery} from '@root/redux/api/tasksApi'
import {useGetAllRolesQuery} from '@root/redux/api/userApi'
import ErrorMessage from '../stubs/ErrorMessage'
import LaunchesEmptyMessage from '../stubs/LaunchesEmptyMessage'
import {
  AppTable,
  AppTableTab,
  SortTextKind,
  useAppTable,
  ImprovedCellCopy,
  type AppTableColumn,
} from '@root/components/appTable'
import {ReadStatus} from '@root/components/ReadStatus'
import {tableContext, TableContextProvider} from './context'
import {type LaunchInfo, LaunchTabKind} from './types'

import {FilterCollectorTemplateSettingsCombobox} from './components/FilterCollectorTemplateSettingsCombobox'
import {FilterCollectorLaunchAuthorCombobox} from './components/FilterCollectorLaunchAuthorCombobox'
import {FilterCollectorLaunchStatusSelect} from './components/FilterCollectorLaunchStatusSelect'
import {fieldMapper} from '@root/pages/SpecialistToolsPage/components/launchHistoryTab/fileldMapper'
import {GitlabStatuses} from '../../constants'
import {LaunchStatusTextMap} from '@root/features/launchHistory/launchStatusType'

const tabs: AppTableTab<LaunchTabKind>[] = [
  {
    label: 'Все тесты',
    value: LaunchTabKind.all,
  },
  {
    label: 'С отчетом',
    value: LaunchTabKind.withReport,
  },
  {
    label: 'Без отчета',
    value: LaunchTabKind.withoutReport,
  },
]

const LaunchCreatorCell: FC<{creator: LaunchInfo['creator']}> = ({creator}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  return (
    <ImprovedCellCopy
      copiedText={creator.email}
      shownText={creator.email || ''}
      style={{padding: '16px 12px'}}
      successMessage="Почта скопирована в буфер обмена"
    />
  )
}

const ReportActionsCell: FC<{launch: LaunchInfo}> = ({launch}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const {systemId, taskId} = ctx

  const navigate = useNavigate()

  const handleOpenReport = useCallback(() => {
    window.open(`/reports/${launch.reportId}`, '_blank')
  }, [launch.reportId])

  const handleCreateReport = useCallback(() => {
    if (!launch.id) {
      throw new Error('Launch id was null')
    }

    navigate(`/reports/create?systemId=${systemId}&taskId=${taskId}&launchId=${launch.id}`)
  }, [launch.id, navigate, systemId, taskId])

  if (launch.reportId) {
    return (
      <LinkButton style={{lineHeight: '16px'}} onClick={handleOpenReport}>
        Посмотреть
      </LinkButton>
    )
  }

  const createDisabledMsg =
    launch.status !== GitlabStatuses.SUCCESS &&
    `Формирование отчёта недоступно для запуска со статусом "${LaunchStatusTextMap[launch.status]}"`

  return (
    <Tooltip content={createDisabledMsg}>
      <div>
        <LinkButton
          disabled={!!createDisabledMsg}
          style={{lineHeight: '16px'}}
          onClick={handleCreateReport}
        >
          Сформировать
        </LinkButton>
      </div>
    </Tooltip>
  )
}

const SettingsTemplateButton: FC<{launch: LaunchInfo}> = ({launch}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const {setLaunchModal} = ctx

  const handleClick = useCallback(() => setLaunchModal(launch), [launch, setLaunchModal])

  return (
    <LinkButton style={{lineHeight: '16px'}} onClick={handleClick}>
      {launch.variablesTemplateName}
    </LinkButton>
  )
}

const columns: AppTableColumn<LaunchInfo>[] = [
  {
    dataIndex: 'id',
    title: 'ID',
    filterableOptions: {
      type: 'text',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.default,
    },
    width: '6.66666%', // (80 / 1200)
    renderCell: (_val, row, _col) => <ReadStatus isNew={false}>{row.pipelineId}</ReadStatus>,
    bodyCellStyle: {
      paddingLeft: 8,
    },
    headCellStyle: {
      paddingLeft: 8,
    },
  },
  {
    dataIndex: 'creator',
    title: 'Автор',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorLaunchAuthorCombobox,
    },
    width: '12.5%', // (150 / 1200),
    renderCell: (creator: LaunchInfo['creator']) => <LaunchCreatorCell creator={creator} />,
    bodyCellStyle: {
      padding: 0,
    },
  },
  {
    dataIndex: 'taskNumber',
    title: 'Номер заявки',
    sortable: {
      enabled: true,
      text: SortTextKind.default,
    },
    filterableOptions: {
      type: 'text',
    },
    width: '11.66666%', // (140 / 1200)
    renderCell: (taskNumber) => `#${taskNumber}`,
  },
  {
    dataIndex: 'variablesTemplateName',
    title: 'Шаблон настроек',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabeticalCyrillic,
    },
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorTemplateSettingsCombobox,
    },
    width: '16.66666%', // (200 / 1200)
    renderCell: (_val, row) => <SettingsTemplateButton launch={row} />,
  },
  {
    dataIndex: 'status',
    title: 'Статус',
    sortable: {
      enabled: true,
      text: SortTextKind.lifeCycle,
    },
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorLaunchStatusSelect,
    },
    width: '13.33333%', // (160 / 1200)
    renderCell: (_val, row) => (row.status ? LaunchStatusTextMap[row.status] : ''),
  },
  {
    dataIndex: 'createdAt',
    title: 'Дата начала',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
    filterableOptions: {
      type: 'date',
    },
    width: '13.33333%', // (160 / 1200)
    valueRenderType: 'dateTime',
  },
  {
    dataIndex: 'finishedAt',
    title: 'Дата окончания',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
    filterableOptions: {
      type: 'date',
    },
    width: '13.33333%', // (160 / 1200)
    valueRenderType: 'dateTime',
  },
  {
    key: 'report',
    title: 'Отчёт',
    width: '12.5%', // (150 / 1200)
    renderCell: (_val, row, _col) => <ReportActionsCell launch={row} />,
  },
]

function getId(row: LaunchInfo) {
  if (!row.id) {
    throw new Error('Id was null!')
  }
  return row.id
}

const LaunchHistoryTable: FC<{systemId: string}> = ({systemId}) => {
  const {tableState, tableProps} = useAppTable<LaunchInfo, LaunchTabKind>({
    pages: {
      currentPage: 1,
      pageSize: 20,
    },
    filterValues: [],
    currentTab: LaunchTabKind.all,
  })

  const {data: rolesMap} = useGetAllRolesQuery()

  const {
    currentData: tasks,
    isFetching: isTasksFetching,
    isError: isTasksError,
    error: tasksError,
  } = useGetTasksByFilterQuery(getTasksFilter(systemId) as any, {
    skip: !systemId,
  })

  const {currentData: activeTask} = useGetSystemActiveTaskQuery(systemId, {skip: !systemId})

  const task = activeTask || tasks?.items[0]

  const {
    data: cachedLaunches,
    currentData: launches,
    isFetching: isLaunchesFetching,
    isError: isLaunchesError,
  } = useGetLaunchesQuery(
    {systemId, taskId: task?.id, filter: fieldMapper(tableState)},
    {skip: !systemId || !task}
  )

  const [launchModal, setLaunchModal] = useState<LaunchInfo | null>(null)

  if (isTasksError) {
    console.error(tasksError)
    return <ErrorMessage />
  }

  if (isTasksFetching) return <LoaderFrame />

  return (
    <TableContextProvider value={{setLaunchModal, systemId, taskId: task?.id, rolesMap}}>
      <AppTable<LaunchInfo>
        noSideBorders
        cachedData={cachedLaunches?.items ?? []}
        columns={columns}
        components={{
          FilterCollector: {
            size: 'large',
          },
          Cell: {
            style: {
              padding: '16px 12px',
            },
          },
          HeadCell: {
            style: {
              padding: '12px',
            },
          },
        }}
        customEmpty={<LaunchesEmptyMessage />}
        customErrorStub={<ErrorMessage />}
        data={launches?.items ?? []}
        error={isLaunchesError}
        getId={getId}
        loading={isLaunchesFetching}
        pagination={
          cachedLaunches
            ? {
                currentPage: tableState.pages?.currentPage ?? 1,
                pageSize: tableState.pages?.pageSize ?? 20,
                totalCount: cachedLaunches.totalElements,
                totalPages: cachedLaunches.totalPages,
              }
            : undefined
        }
        style={{
          width: '1200px',
          maxWidth: '1200px',
          minHeight: 400,
        }}
        tabs={{
          current: tableState.currentTab,
          list: tabs,
        }}
        {...tableProps}
      />
      <VariablesModal
        launch={launchModal}
        open={!!launchModal}
        onClose={() => setLaunchModal(null)}
        onOpenReport={
          launchModal?.reportId &&
          (() => window.open(`/reports/launch/${launchModal.id}`, '_blank'))
        }
      />
    </TableContextProvider>
  )
}

export default LaunchHistoryTable
