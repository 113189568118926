import log from 'loglevel'
import {LOG_LEVEL} from '@root/constants'

function formatMessage(scope: string) {
  return `[${scope}]`
}

log.setLevel(LOG_LEVEL ?? 'error')

export const createLogger = (scope: string) => ({
  trace: (...value: unknown[]) => {
    log.trace('[TRACE]', formatMessage(scope), ...value)
  },
  debug: (...value: unknown[]) => {
    log.debug('[DEBUG]', formatMessage(scope), ...value)
  },
  info: (...value: unknown[]) => {
    log.info('[INFO]', formatMessage(scope), ...value)
  },
  warn: (...value: unknown[]) => {
    log.warn('[WARN]', formatMessage(scope), ...value)
  },
  error: (...value: unknown[]) => {
    log.error('[ERROR]', formatMessage(scope), ...value)
  },
})
