import {TaskErrorReason, type TaskError} from './types'
import {isObject} from 'lodash'
import {parseEnum} from '@root/utils'

export function isTaskError(data: unknown): data is TaskError {
  if (!isObject(data)) {
    return false
  }

  try {
    parseEnum(TaskErrorReason, (data as TaskError).reason)

    return true
  } catch (err) {
    console.error(err)

    return false
  }
}
