import {useDeleteReportMutation} from '@root/redux/api/report/reportsApi'
import {FC, useCallback} from 'react'
import ConfirmReportDeleteModal from '@root/features/reports/components/ConfirmReportDeleteModal'
import {UnionReport} from '@root/features/reports/types'
import {useDeleteComparativeReportMutation} from '@root/redux/api/report/comparativeReportApi'
import {isReportComparative} from '@root/features/reports/reportComparison'

type Props = {
  report: UnionReport
  onClose: () => void
}

const DeleteReportModal: FC<Props> = ({report, onClose}) => {
  const [deleteReport] = useDeleteReportMutation()
  const [deleteComparativeReport] = useDeleteComparativeReportMutation()

  const deleteAction = useCallback(
    async (id: string) => {
      if (isReportComparative(report)) {
        await deleteComparativeReport(id).unwrap()
      } else {
        await deleteReport(id).unwrap()
      }
    },
    [deleteComparativeReport, deleteReport, report]
  )

  return (
    <ConfirmReportDeleteModal
      deleteAction={deleteAction}
      open={report != null}
      reportId={report?.id}
      onClose={onClose}
    />
  )
}

export default DeleteReportModal
