import {Link, Typography} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {useParams} from 'react-router-dom'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'
import {Fragment} from 'react'

const ConfStatisticsTab = () => {
  const {configurationId} = useParams()
  const {data: configuration} = useGetConfigurationByIdQuery({configurationId})

  const handleOpenUrl = (_url) => {
    window.open(configuration.kaitenCardLink, '_blank')
  }

  return (
    <FlexboxColumn sx={{mt: 'x12', gap: '40px'}}>
      {configuration?.statistics.graphs?.map((stat, statIndex) => {
        const last = statIndex === configuration.statistics.graphs.length - 1
        return (
          <Fragment key={statIndex}>
            <FlexboxColumn sx={{gap: '16px'}}>
              <Typography variant="h3">{stat.graphName}</Typography>
              {stat.graphUrl ? (
                <Link style={{width: 'fit-content'}} onClick={() => handleOpenUrl(stat.graphUrl)}>
                  {stat.graphUrl}
                </Link>
              ) : (
                '-'
              )}
            </FlexboxColumn>
            {!last && <Divider />}
          </Fragment>
        )
      })}
    </FlexboxColumn>
  )
}

export default ConfStatisticsTab
