import {FC, useCallback} from 'react'
import ConfirmReportDeleteModal from '@root/features/reports/components/ConfirmReportDeleteModal'
import {useDeleteComparativeReportMutation} from '@root/redux/api/report/comparativeReportApi'
import {useNavigate} from 'react-router-dom'

type Props = {
  reportId: string
  open: boolean
  onClose: () => void
}

export const DeleteReportModal: FC<Props> = ({reportId, open, onClose}) => {
  const [deleteReport] = useDeleteComparativeReportMutation()
  const navigate = useNavigate()

  const deleteAction = useCallback(
    async (id: string) => {
      try {
        await deleteReport(id).unwrap()

        navigate('/reports')
      } catch (e) {
        console.error('Unable to delete report', e)
      }
    },
    [deleteReport, navigate]
  )

  return (
    <ConfirmReportDeleteModal
      deleteAction={deleteAction}
      open={open}
      reportId={reportId}
      onClose={onClose}
    />
  )
}
