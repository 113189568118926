import {FC, memo, PropsWithChildren, ReactElement, ReactNode} from 'react'
import {AbstractTextUrlEnhancer, ImplProps} from '@root/components/AbstractTextUrlEnhancer'
import {isEmpty, isString} from 'lodash'
import {Link, LinkProps} from 'ui-kit'

type Props = ImplProps<LinkProps, PropsWithChildren<object>, undefined> & {
  noDataStub?: ReactNode
}

const TextUrlEnhancer: FC<Props> = ({noDataStub = <></>, text, children, ...other}) => {
  let textValue: string | null = null
  if (isString(children)) {
    textValue = children
  } else if (!isEmpty(text)) {
    textValue = text
  } else if (children) {
    return children as ReactElement
  } else {
    return <>{noDataStub}</>
  }

  return (
    <AbstractTextUrlEnhancer
      LinkComponent={({href, children}) => (
        <Link href={href} rel="noopener noreferrer nofollow" target="_blank">
          {children}
        </Link>
      )}
      text={textValue}
      TextComponent={({children}) => <span>{children}</span>}
      {...other}
    />
  )
}

export default memo(TextUrlEnhancer)
