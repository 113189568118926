import type {FC} from 'react'
import {Svg, SVGProps, Path} from '@react-pdf/renderer'

export const ArrowUp: FC<SVGProps> = ({...other}) => (
  <Svg fill="none" height="5" viewBox="0 0 8 5" width="8" {...other}>
    <Path
      d="M1.41823 4.19318L4.0049 1.60652L6.59156 4.19318C6.85156 4.45318 7.27156 4.45318 7.53156 4.19318C7.79156 3.93318 7.79156 3.51318 7.53156 3.25318L4.47156 0.193182C4.21156 -0.0668179 3.79156 -0.0668179 3.53156 0.193182L0.471563 3.25318C0.211563 3.51318 0.211563 3.93318 0.471563 4.19318C0.731563 4.44652 1.15823 4.45318 1.41823 4.19318Z"
      fill="#50565E"
    />
  </Svg>
)
