import type {EndpointBuilder} from '@reduxjs/toolkit/src/query/endpointDefinitions'
import type {BaseQueryFn} from '@reduxjs/toolkit/src/query/baseQueryTypes'
import {OpenApiResponseType, SetAction} from '@root/core/helperTypes'
import {paths} from '@root/openapi'
import dayjs from 'dayjs'
import {memo} from 'react'
import {isFunction} from 'lodash'
import {NotFoundServerErrorBody} from '@root/core/types'

export const formattedDate = (date: string) => new Date(Date.parse(date)).toLocaleString('ru')

export const formatDateTimeRuLocale = (dateTime: string) =>
  dayjs(dateTime).format('DD.MM.YYYY в HH:mm')
export const formatDateRuLocale = (date: string) => dayjs(date).format('DD.MM.YYYY')

export const NOT_IMPLEMENTED = () => alert('Не реализовано.')

export const copyToClipboard = async (
  text: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  try {
    await navigator.clipboard.writeText(text)
    onSuccess?.()
  } catch (error) {
    console.error(error)
    onError?.()
  }
}

export function promiseRejectDelay(delay: number) {
  return new Promise((_, reject) => setTimeout(() => reject('timeout'), delay))
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function enhanceBuilder<
  TQuery extends string,
  TReducer extends string,
  TUri extends keyof paths,
>(builder: EndpointBuilder<BaseQueryFn, TQuery, TReducer>, uri: TUri) {
  return builder.query<OpenApiResponseType<TUri>, string>({
    query: () => uri,
  })
}

export function wrapStringEllipsis(input: string, maxLength: number) {
  if (maxLength < 3) {
    throw new Error('Max length must be greater than 3')
  }

  if (input.length > maxLength) {
    return input.substring(0, maxLength - 3) + '...'
  }

  return input
}

export function parseEnum<T extends Record<string, string | number>>(
  enumType: T,
  value: string | number
): T[keyof T] {
  const enumKey = Object.keys(enumType).find((key) => enumType[key] === value)

  if (enumKey) {
    return enumType[enumKey] as T[keyof T]
  } else {
    throw new Error(`Invalid value '${value}' for enum`)
  }
}

export function saveFile(data: Blob, filename: string) {
  const url = URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(url)
  a.remove()
}

export function id<T>(x: T) {
  return x
}

export function allEqual<T>(arr: T[]) {
  return new Set(arr).size == 1
}

export const genericMemo: <T>(component: T) => T = memo

export function extractValueFromAction<T>(prevValue: T, actionArg: Parameters<SetAction<T>>[0]) {
  if (isFunction(actionArg)) {
    return actionArg(prevValue)
  } else {
    return actionArg
  }
}

export const isClientErrorCode = (status: number) => [400, 404, 403].includes(status)

export function isNotFoundError(error: unknown): error is NotFoundServerErrorBody {
  return (error as NotFoundServerErrorBody).status === 404
}

export function toPromise<T>(value: T): Promise<T> {
  return new Promise((resolve) => {
    return resolve(value)
  })
}

export function memoize<Input, Result>(fn: (input: Input) => Result) {
  const memoMap = new Map<Input, Result>()
  return function (input: Input): Result {
    if (memoMap.has(input)) return memoMap.get(input)!

    const result = fn(input)
    memoMap.set(input, result)
    return result
  }
}

export const addHttpProtocolIfNotPresent = (url: string) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url

type EnhanceTextUrlResult = {type: 'string'; value: string} | {type: 'url'; url: string}

const uriRegex =
  /^([a-z][a-z0-9+\-.]*):(\/\/((((%[0-9a-f][0-9a-f]|[a-z0-9\-._~]|[!$&'()*+,;=]|:)*)@)?(\[(((([0-9a-f]{1,4}):){6,6}((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|::(([0-9a-f]{1,4}):){5,5}((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|([0-9a-f]{1,4})?::(([0-9a-f]{1,4}):){4,4}((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|((([0-9a-f]{1,4}):)?([0-9a-f]{1,4}))?::(([0-9a-f]{1,4}):){3,3}((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|((([0-9a-f]{1,4}):){0,2}([0-9a-f]{1,4}))?::(([0-9a-f]{1,4}):){2,2}((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|((([0-9a-f]{1,4}):){0,3}([0-9a-f]{1,4}))?::([0-9a-f]{1,4}):((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|((([0-9a-f]{1,4}):){0,4}([0-9a-f]{1,4}))?::((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([0-9a-f]{1,4}):([0-9a-f]{1,4}))|((([0-9a-f]{1,4}):){0,5}([0-9a-f]{1,4}))?::([0-9a-f]{1,4})|((([0-9a-f]{1,4}):){0,6}([0-9a-f]{1,4}))?::)|v[a-f0-9]+\.([a-z0-9\-._~]|[!$&'()*+,;=]|:)+)]|(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3,3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([a-z0-9\-._~]|%[0-9a-f][0-9a-f]|[!$&'()*+,;=])*)(:([0-9]+))?)((\/([a-z0-9\-._~!$&'()*+,;=:@]|(%[a-f0-9]{2,2}))*)*))(\?([a-z0-9\-._~!$&'()*+,;=:@\\/?]|(%[a-f0-9]{2,2}))*)?(#([a-z0-9\-._~!$&'()*+,;=:@\\/?]|(%[a-f0-9]{2,2}))*)?$/gim

export function enhanceTextWithUrl(input: string): EnhanceTextUrlResult[] {
  if (!input) {
    return []
  }

  const results: EnhanceTextUrlResult[] = []
  let lastIndex = 0

  let match: RegExpExecArray | null
  while ((match = uriRegex.exec(input)) !== null) {
    // Push the text part before the URL if it exists
    if (lastIndex < match.index) {
      results.push({
        type: 'string',
        value: input.slice(lastIndex, match.index),
      })
    }

    // Add the matched URL
    const fullUrl = match[0]
    results.push({
      type: 'url',
      url: fullUrl.startsWith('http') ? fullUrl : `http://${fullUrl}`,
    })

    lastIndex = uriRegex.lastIndex
  }

  // Push any remaining text after the last URL
  if (lastIndex < input.length) {
    results.push({
      type: 'string',
      value: input.slice(lastIndex),
    })
  }

  return results
}

export function replaceSchemaToWs(url: string) {
  return url.replace(/^.*:\/\//, 'ws://')
}
