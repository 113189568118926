import type {Report} from '../types'
import {FC} from 'react'
import {styles} from './common'
import {Text, View, ViewProps} from '@react-pdf/renderer'

type Props = {
  report: Report
} & ViewProps

export const VariablesList: FC<Props> = ({report, ...rest}) => (
  <View {...rest} style={styles.testVariables}>
    {report.variables.map(({key, value}, i) => (
      <View key={i} style={styles.testVariableItem}>
        <Text style={styles.testVariableItemTextIndex}>{i + 1}.</Text>
        <Text style={styles.testVariableItemTextKey}>{key}:</Text>
        <Text style={styles.testVariableItemTextValue}>{value}</Text>
      </View>
    ))}
  </View>
)
