import {
  ComponentType,
  CSSProperties,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {animated, useSpring} from '@react-spring/web'
import styled from '@emotion/styled'
import {useDimensions} from '@root/hooks/useDimensions'

export type ExpanderNodeProps = {
  open: boolean
}

type Props = HTMLProps<HTMLDivElement> & {
  expanderNode: ComponentType<ExpanderNodeProps>
  defaultOpen?: boolean
  overflowElementStyle?: CSSProperties
}

const StyledExpander = styled.div`
  cursor: pointer;
`

export const SpringAccordion = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({children, expanderNode: ExpanderNode, defaultOpen, overflowElementStyle, ...other}, ref) => {
    const {dimensions, ref: contentRef, refresh} = useDimensions<HTMLDivElement>()
    const [shouldAnimate, setShouldAnimate] = useState(!defaultOpen)
    const [open, setOpen] = useState(defaultOpen)
    const [isAnimating, setAnimating] = useState(false)
    const [applyHeight, setApplyHeight] = useState(false)

    const isMounted = useRef(true)
    const dimensionHeightRef = useRef(dimensions?.height)

    dimensionHeightRef.current = dimensions?.height ?? contentRef.current?.offsetHeight ?? 0

    const [style, api] = useSpring(
      () => ({
        height: '0px',
        config: {
          duration: shouldAnimate ? undefined : 0,
        },
      }),
      [shouldAnimate]
    )

    useEffect(() => {
      isMounted.current = true
      return () => {
        isMounted.current = false
      }
    }, [])

    useEffect(() => {
      api.start({
        to: {
          height: (open ? dimensionHeightRef.current : 0) + 'px',
        },
        from: {
          height: (open ? 0 : dimensionHeightRef.current) + 'px',
        },
        onStart: () => {
          if (!isMounted.current) {
            return
          }

          setAnimating(true)
          refresh()
        },
        onRest: () => {
          if (!isMounted.current) {
            return
          }

          refresh()
          setAnimating(false)
        },
      })
    }, [api, open, refresh])

    const handleToggleClick = useCallback(() => {
      refresh()
      setAnimating(true)
      setShouldAnimate(true)
      setOpen((prev) => !prev)
    }, [refresh])

    useEffect(() => {
      setApplyHeight(isAnimating || !open)
    }, [isAnimating, open])

    return (
      <div ref={ref} {...other}>
        <StyledExpander onClick={handleToggleClick}>
          <ExpanderNode open={open} />
        </StyledExpander>

        <animated.div
          style={{
            overflow: 'hidden',
            width: '100%',
            height: applyHeight ? style.height : 'auto',
            ...(overflowElementStyle ?? {}),
          }}
        >
          <div ref={contentRef}>{children}</div>
        </animated.div>
      </div>
    )
  }
)

export default SpringAccordion
