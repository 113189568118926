import {useCallback, useEffect} from 'react'
import type {Report, ComparativeReport} from '../types'
import {useNavigate} from 'react-router-dom'
import useNotify from '@root/hooks/useNotify'
import {useTeardownEffect} from '@root/hooks/useTeardownEffect'

type Params = {
  disable?: boolean
  fallbackUrl: string
  report: Report | ComparativeReport
  sendLock: (id: string) => Promise<boolean>
  sendUnlock: (id: string) => Promise<boolean>
}

export const useLockReport = ({
  sendLock,
  sendUnlock,
  fallbackUrl,
  report,
  disable = false,
}: Params) => {
  const navigate = useNavigate()
  const {notifyError} = useNotify()

  useEffect(() => {
    if (disable || report == null) {
      return
    }

    const fallback = () => {
      notifyError('Текущий отчет уже кто-то редактирует.')
      navigate(fallbackUrl)
    }

    const action = () => {
      sendLock(report.id)
        .then((locked) => {
          if (locked) {
            return
          }

          console.error('Unable to acquire edit lock, server responded false')
          fallback()
        })
        .catch((e) => {
          console.error('Unable to acquire edit lock', e)
          fallback()
        })
    }

    action()

    const timer = setInterval(action, 5 * 60 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [disable, fallbackUrl, navigate, notifyError, report, sendLock])

  const unlock = useCallback(async () => {
    if (disable || report == null) {
      return
    }

    try {
      await sendUnlock(report.id)
    } catch (e) {
      console.warn('unable to send unlock', e)
    }
  }, [disable, report, sendUnlock])

  useTeardownEffect({onUnload: unlock, onUnmount: unlock})
}
