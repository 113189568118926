import {Box, Button, Typography, baseTheme} from '@root/components/kit'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import StepTitle from '@root/components/StepTitle'
import useNotify from '@root/hooks/useNotify'
import {useFormState} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetTaskFormDataQuery} from '@root/redux/api/formsApi'
import {useDeleteTaskMutation, useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import useLazyModal from '../hooks/useLazyModal'
import useUpdateFormData from '../hooks/useUpdateFormData'
import CancelTaskModal from './CancelTaskModal'
import SaveTaskModal from './SaveTaskModal'
import {isEmpty} from 'lodash'
import {getUpdatedFieldIds} from '../utils/formUtils'
import {getTagOrName} from '@root/features/systems/utils'
import {useContext} from 'react'
import {createTaskContext} from '../context'
import {TaskStatuses} from '@root/constants'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

const {colors} = baseTheme

const StepsSection = ({draft, form, currentStepMeta, displayButtons}) => {
  const {notifyError, notifySuccess} = useNotify()
  const navigate = useNavigate()
  const {taskId, stepId} = useParams()

  const {data: userInfo} = useGetUserinfoQuery()

  const ctx = useContext(createTaskContext)
  if (!ctx) {
    throw new Error('Ctx was undefined')
  }

  const formValue = Object.values(form.getValues())
  const formState = useFormState({control: form.control})

  const {data: task} = useGetTaskByIdQuery(taskId)
  const {data: taskFormData = []} = useGetTaskFormDataQuery({taskId}, {skip: !task})

  const isFormDirty = () =>
    (Number(stepId) !== 3 && formState.isDirty) ||
    !isEmpty(formState.dirtyFields) ||
    !taskFormData.find((step) => step.number === Number(stepId))
  const {updateFormData, isUpdating} = useUpdateFormData()
  const handleSaveFormData = form.handleSubmit(
    (steps) => {
      const updatedFields = getUpdatedFieldIds(formState.dirtyFields[stepId - 1], currentStepMeta)
      updateFormData({
        steps,
        currentStepMeta,
        updatedFields,
        onSuccess: () => {
          ctx.setUpdated(true)
          notifySuccess(
            'Заявка сохранена',
            'Вы можете увидеть ее в разделе заявок, во вкладке “Черновики”.'
          )
        },
      })
    },
    (error) => {
      notifyError(JSON.stringify(error))
    }
  )
  const handleNavigateTo = (navigateTo) => {
    const to = `/tasks/${draft ? 'draft' : 'create'}/${taskId}/steps/${navigateTo}`
    // navigate(to);

    const isDirty = isFormDirty()
    console.log('form updated: ', isDirty)
    if (isDirty) {
      const updatedFields = getUpdatedFieldIds(formState.dirtyFields[stepId - 1], currentStepMeta)
      updateFormData({
        steps: formValue,
        currentStepMeta,
        updatedFields,
        shouldNotify: false,
        onSuccess: () => {
          ctx.setUpdated(true)
          navigate(to)
        },
      })
    } else navigate(to)
  }

  const [deleteTask] = useDeleteTaskMutation()
  const handleRemoveTask = async () => {
    await deleteTask(taskId)
      .unwrap()
      .then(() => {
        notifySuccess('Заявка удалена.')
        navigate('/tasks')
      })
      .catch((error) => notifyError(JSON.stringify(error)))
  }

  const handleChangeSystem = () => {
    if (isFormDirty()) {
      const updatedFields = getUpdatedFieldIds(formState.dirtyFields[stepId - 1], currentStepMeta)
      updateFormData({
        steps: formValue,
        currentStepMeta,
        updatedFields,
        shouldNotify: false,
      })
    }
    navigate(`/tasks/${draft ? 'draft' : 'create'}/${taskId}/system`)
  }
  const {
    modalShown: cancelModalShown,
    modalTouched: cancelModalTouched,
    setModalShown: setCancelModalShown,
  } = useLazyModal()
  const {
    modalShown: saveModalShown,
    modalTouched: saveModalTouched,
    setModalShown: setSaveModalShown,
  } = useLazyModal()

  const firstStep = formValue.find((step) => step.number === 1)
  const secondStep = formValue.find((step) => step.number === 2)

  return (
    <>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
        <FlexboxRow sx={{alignSelf: 'stretch', alignItems: 'center'}}>
          <Typography style={{flex: '1 0 0'}} variant="h3">
            Выбранная ИС: {getTagOrName(task?.system)}
          </Typography>
          <Button size="small" variant="text" onClick={handleChangeSystem}>
            Изменить ИС и продолжить заполнение
          </Button>
        </FlexboxRow>
        <FlexboxColumn sx={{gap: '8px'}}>
          <FlexboxRow sx={{gap: '8px', alignItems: 'center'}}>
            <StepTitle
              completed={firstStep?.completed}
              current={Number(stepId) === 1}
              handleClick={() => handleNavigateTo(1)}
              number={1}
              title="Общие настройки заявки"
            />
            <Box sx={{height: '1px', flex: '1 0 0', backgroundColor: 'grey.30'}} />
            <StepTitle
              completed={secondStep?.completed}
              current={Number(stepId) === 2}
              disabled={!secondStep}
              handleClick={() => handleNavigateTo(2)}
              number={2}
              title="Технические параметры"
            />
            <Box sx={{height: '1px', flex: '1 0 0', backgroundColor: 'grey.30'}} />
            <StepTitle
              current={Number(stepId) === 3}
              disabled={!formValue.find((step) => step.number === 3)}
              handleClick={() => handleNavigateTo(3)}
              number={3}
              title="Итоговая сверка"
            />
          </FlexboxRow>
          <Typography style={{color: colors.grey[50]}} variant="p3">
            Вы можете вернуться к любому пройденному шагу на протяжении всего процесса создания
            заявок, нажав на его название или по кнопке назад.
          </Typography>
        </FlexboxColumn>
        {displayButtons && (
          <FlexboxRow sx={{justifyContent: 'space-between'}}>
            <FlexboxRow sx={{gap: '16px'}}>
              {task?.status === TaskStatuses.WITHDRAWN && !userInfo?.isAdmin ? (
                <Button variant="outlined" onClick={() => navigate('/tasks')}>
                  Вернуться к заявкам
                </Button>
              ) : (
                <Button variant="outlined" onClick={() => setCancelModalShown(true)}>
                  Отменить создание
                </Button>
              )}
              <Button variant="secondary" onClick={() => setSaveModalShown(true)}>
                Сохранить заявку
              </Button>
            </FlexboxRow>
            <FlexboxRow sx={{gap: '16px'}}>
              <Button
                disabled={Number(stepId) === 1 || isUpdating}
                onClick={() => handleNavigateTo(Number(stepId) - 1)}
              >
                Предыдущий шаг
              </Button>
              <Button
                disabled={Number(stepId) === 3 || isUpdating}
                loading={isUpdating}
                onClick={() => handleNavigateTo(Number(stepId) + 1)}
              >
                Следующий шаг
              </Button>
            </FlexboxRow>
          </FlexboxRow>
        )}
      </Section>
      {cancelModalTouched && (
        <CancelTaskModal
          isOpen={cancelModalShown}
          onClose={() => setCancelModalShown(false)}
          onRemove={handleRemoveTask}
          onSave={handleSaveFormData}
        />
      )}
      {saveModalTouched && (
        <SaveTaskModal
          isOpen={saveModalShown}
          onClose={() => setSaveModalShown(false)}
          onSave={handleSaveFormData}
        />
      )}
    </>
  )
}

export default StepsSection
