import React, {FC, MouseEvent, useCallback, useContext, useMemo, useRef} from 'react'
import {tableContext} from './context'
import type {UnionReport} from '@root/features/reports/types'
import {ActionButton} from '@root/components/ActionButton'
import {MoreVertical} from '@x5-react-uikit/icons'
import Paper from '@root/components/Paper'
import {
  Dropdown,
  DropdownInnerRefMethods,
  ListItem,
  SelectList,
  SelectListProps,
  SelectOptionType,
} from '@root/components/kit'
import {userSelector} from '@root/redux/selectors'
import {useSelector} from 'react-redux'
import {isRoleAllowed, isSameEditorOrNull} from '@root/features/reports/canEditReport'
import {EditorTooltip} from '@root/features/reports/components/EditorTooltip'

type Props = {
  report: UnionReport
}

enum ActionsEnum {
  delete,
  update,
}

export const ReportTableActionsButton: FC<Props> = ({report}) => {
  const {editor} = report

  const dropdownRef = useRef<DropdownInnerRefMethods>()
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const currentUser = useSelector(userSelector)

  const reportLocked = !isSameEditorOrNull(currentUser, editor)

  const actions = useMemo(() => {
    const arr: SelectOptionType[] = [
      {name: 'Удалить', value: ActionsEnum.delete, disabled: reportLocked},
    ]

    if (isRoleAllowed(ctx.userInfo)) {
      arr.push({
        name: 'Редактировать',
        value: ActionsEnum.update,
        disabled: reportLocked,
      })
    }

    return arr
  }, [ctx.userInfo, reportLocked])

  const handleChange: SelectListProps['onChange'] = useCallback(
    (_, actionName) => {
      switch (actionName as ActionsEnum) {
        case ActionsEnum.delete:
          if (!reportLocked) {
            ctx.onDelete(report)
          }
          break
        case ActionsEnum.update:
          if (!reportLocked) {
            ctx.onUpdate(report)
          }
          break
      }

      dropdownRef?.current?.close()
    },
    [ctx, reportLocked, report]
  )

  const renderOption: SelectListProps['renderOption'] = useCallback(
    (option, isActive, onClick) => {
      const commonProps = {
        disabled: option.disabled,
        adornment: option.icon,
        selected: isActive,
        text: option.name,
        onClick: (event: MouseEvent<HTMLElement>) => !option.disabled && onClick(event, option),
      }

      if (reportLocked) {
        return (
          <EditorTooltip email={editor.email}>
            <ListItem {...commonProps} />
          </EditorTooltip>
        )
      }

      return <ListItem key={option.value} {...commonProps} />
    },
    [editor?.email, reportLocked]
  )

  if (!ctx.userInfo) {
    return <></>
  }

  return (
    <Dropdown
      disableContentVisual
      usePortal
      action={
        <ActionButton disabled={ctx.userInfo.isBusiness}>
          <MoreVertical size="small" />
        </ActionButton>
      }
      className="w-100"
      innerRef={dropdownRef}
      placement="bottom-end"
    >
      <Paper sx={{padding: '8px 0 0 0', backgroundColor: 'white'}}>
        <SelectList
          items={actions}
          renderOption={renderOption}
          selected={[]}
          onChange={handleChange}
        />
      </Paper>
    </Dropdown>
  )
}
