import {Box, Button, IconButton, Tooltip, Typography, baseTheme} from '@x5-react-uikit/core'
import {Add, Del, Info} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import DataGridTable from '@root/components/dataGridTable/table/DataGridTable'
import FormInputCheckbox from '@root/components/inputs/formInputs/FormInputCheckbox'
import FormInputSwitcher from '@root/components/inputs/formInputs/FormInputSwitcher'
import {memo, useState} from 'react'
import {Controller} from 'react-hook-form'
import {getTableColumnWidth} from '../constants'
import {InputTypes, createEmptyTableRow} from '../utils/formUtils'

const {colors} = baseTheme

const getCellCustomContent = ({tableName, cellMeta, rowIndex, cellIndex, form, readonly}) => {
  let inputName
  switch (cellMeta.type) {
    case InputTypes.switcher:
      inputName = `${tableName}.${rowIndex}.cells.${cellIndex}.values.switcher`
      return (
        <FormInputSwitcher
          control={form.control}
          disabled={readonly}
          name={inputName}
          style={{width: 'fit-content'}}
        />
      )
    case InputTypes.checkList: {
      const checkListName = `${tableName}.${rowIndex}.cells.${cellIndex}.values.checkList`
      return cellMeta.values.map((checkboxMeta, checkboxMetaIndex) => {
        inputName = `${checkListName}.${checkboxMetaIndex}.value`
        return (
          <Box key={inputName} sx={{p: 'x4'}}>
            <FormInputCheckbox
              control={form.control}
              disabled={readonly}
              label={checkboxMeta.label}
              name={inputName}
            />
          </Box>
        )
      })
    }
    default:
      return <p>FIELD NOT IMPLEMENTED</p>
  }
}

const EditableCell = memo(
  ({readonly, fieldId, label, tableName, rowIndex, cellIndex, setValue, children}) => {
    return (
      <DataGridTable.Cell
        disabled={readonly}
        mode="text"
        style={{
          width: getTableColumnWidth(fieldId, label),
          wordBreak: 'break-all',
        }}
        onCellChange={(event) => {
          const inputName = `${tableName}.${rowIndex}.cells.${cellIndex}.values.text.value`
          setValue(inputName, event.target.value, {shouldDirty: true})
        }}
      >
        {children}
      </DataGridTable.Cell>
    )
  }
)

const FormInputTable = ({fieldId, tableName, form, tableMeta, readonly}) => {
  // FIXME: костыльный способ вызвать ререндер.
  // Сеттер вызывается при изменении формы (добавление/удаление строки).
  // Альтернативный (и правильный) способ -- использовать form.watch,
  // но в этом случае происходит ререндер родительских компонент, это сказывается на UI.
  const [, setTableKey] = useState(Math.random())

  const handleAddRow = () => {
    form.setValue(tableName, [...form.getValues(tableName), createEmptyTableRow(tableMeta)], {
      shouldDirty: true,
    })
    setTableKey(Math.random())
  }
  const handleDeleteRow = (row) => {
    form.setValue(
      tableName,
      form.getValues(tableName).filter((r) => r !== row),
      {shouldDirty: true}
    )
    setTableKey(Math.random())
  }

  return (
    <Controller
      control={form.control}
      name={tableName}
      render={({fieldState: {error}}) => {
        return (
          <FlexboxColumn sx={{gap: '16px'}}>
            <DataGridTable
              styles={{
                display: 'block',
                width: 'fit-content',
                borderColor: error ? colors.error : colors.grey[10],
              }}
            >
              <DataGridTable.Head>
                {Object.values(tableMeta.columns).map((column) => {
                  return (
                    <DataGridTable.HeadCell
                      key={column.id}
                      style={{
                        width: getTableColumnWidth(fieldId, column.label),
                        textWrap: 'wrap',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <FlexboxRow
                        sx={{
                          alignItems: 'center',
                          gap: '4px',
                          width: 'fit-content',
                          padding: '4px',
                        }}
                      >
                        {column.description && (
                          <Tooltip content={column.description} contentWidth="pre">
                            <Info />
                          </Tooltip>
                        )}
                        {column.label}
                      </FlexboxRow>
                    </DataGridTable.HeadCell>
                  )
                })}
                {!readonly && (
                  <DataGridTable.HeadCell
                    key="remove"
                    style={{width: '33px'}}
                  ></DataGridTable.HeadCell>
                )}
              </DataGridTable.Head>
              <DataGridTable.Section style={{display: 'block'}}>
                {form.getValues(tableName).map((row, rowIndex) => (
                  <DataGridTable.Row key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => {
                      const cellMeta = tableMeta.columns[cell.columnId]
                      return cellMeta.type === InputTypes.text ? (
                        <EditableCell
                          key={cell.columnId}
                          cellIndex={cellIndex}
                          fieldId={fieldId}
                          label={cellMeta.label}
                          readonly={readonly}
                          rowIndex={rowIndex}
                          setValue={form.setValue}
                          tableName={tableName}
                        >
                          {cell.values.text.value}
                        </EditableCell>
                      ) : (
                        <DataGridTable.Cell
                          key={cell.columnId}
                          disabled={readonly}
                          mode="custom"
                          style={{
                            width: getTableColumnWidth(fieldId, cellMeta.label),
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {getCellCustomContent({
                            tableName,
                            cellMeta,
                            rowIndex,
                            cellIndex,
                            form,
                            readonly,
                          })}
                        </DataGridTable.Cell>
                      )
                    })}
                    {!readonly && (
                      <DataGridTable.Cell
                        key="remove"
                        mode="custom"
                        style={{width: '33px', padding: 0}}
                      >
                        <IconButton
                          disabled={form.getValues(tableName).length === 1}
                          IconComponent={<Del size="small" />}
                          size="small"
                          style={{padding: 0}}
                          variant="text"
                          onClick={() => handleDeleteRow(row)}
                        />
                      </DataGridTable.Cell>
                    )}
                  </DataGridTable.Row>
                ))}
                {!readonly && (
                  <Button
                    startIcon={<Add />}
                    style={{width: 'max-content', userSelect: 'none'}}
                    variant="text"
                    onClick={handleAddRow}
                  >
                    {tableMeta.addButtonLabel || 'Добавить'}
                  </Button>
                )}
              </DataGridTable.Section>
            </DataGridTable>
            {error && (
              <Typography style={{color: colors.error}} variant="p3">
                Имеются незаполненные ячейки
              </Typography>
            )}
          </FlexboxColumn>
        )
      }}
    />
  )
}

export default memo(FormInputTable)
