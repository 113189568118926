import {
  AppTable,
  AppTableColumn,
  AppTableTab,
  PatchFilterCollectorColumnsFn,
  SortTextKind,
  useAppTable,
} from '@root/components/appTable'
import {EmptyCell} from '@root/components/appTable/components/EmptyCell'
import CellTypography from '@root/components/dataGridTable/CellTypography'
import FlexboxRow from '@root/components/FlexboxRow'
import {Box, Button} from '@root/components/kit'
import RouterLink from '@root/components/RouterLink'
import EmptyIconMessage from '@root/components/stubs/EmptyIconMessage'
import {User} from '@root/core/types'
import {ReportConfiguration} from '@root/features/reportConfiguration/types'
import {FilterCollectorSystemsCombobox} from '@root/features/systems/components/FilterCollectorSystemsCombobox'
import {useGetConfigurationsQuery} from '@root/redux/api/reportConfigurationsApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {userSelector} from '@root/redux/selectors'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import {isEmpty} from 'lodash'
import {FC, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {ConfigurationsTableActionsButton} from './ConfigurationsTableActionsButton'
import {TableContextProvider} from './context'
import DeleteConfigurationModal from './DeleteConfigurationModal'
import {fieldMapper} from './fileldMapper'
import FilterCollectorCreatorCombobox from './FilterCollectorCreatorCombobox'
import {ReportConfigurationTabKind} from './types'

const columns: AppTableColumn<ReportConfiguration>[] = [
  {
    dataIndex: 'name',
    title: 'Название конфигурации',
    width: '61.666666%',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    filterableOptions: {
      type: 'text',
    },
  },
  {
    dataIndex: 'createdAt',
    title: 'Дата создания',
    valueRenderType: 'date',
    width: '15%',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
  },
  {
    key: 'creator',
    title: 'Редактор',
    width: '20%',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorCreatorCombobox,
    },
    renderCell: (_val, row) => {
      const value = row?.creator?.email

      if (isEmpty(value)) {
        return <EmptyCell />
      } else {
        return <CellTypography noPadding>{value}</CellTypography>
      }
    },
  },
  {
    key: 'actions',
    width: 40,
    title: '',
    bodyCellStyle: {
      padding: '8px 0 0 0',
      textAlign: 'center',
      verticalAlign: 'top',
    },
    preventRowClickHandler: true,
    renderCell: (_value, row) => <ConfigurationsTableActionsButton configurationId={row.id} />,
  },
]

const tabs: AppTableTab<ReportConfigurationTabKind>[] = [
  {
    label: 'Все',
    value: ReportConfigurationTabKind.all,
  },
  {
    label: 'Созданные мной',
    value: ReportConfigurationTabKind.my,
  },
  {
    label: 'Созданные другими',
    value: ReportConfigurationTabKind.others,
  },
]

function getId(configuration: ReportConfiguration) {
  if (configuration.id == null) {
    throw new Error('Id was null')
  }

  return configuration.id
}

const patchFilterCollectorColumns: PatchFilterCollectorColumnsFn = (columns) => {
  return [
    {
      name: 'Информационная система',
      value: 'system',
      type: 'custom',
      customInputComponent: FilterCollectorSystemsCombobox,
    },
    ...columns,
  ]
}

const ConfigurationsEmptyMessage = () => (
  <EmptyIconMessage
    message="Когда будет создана конфигурация, она появится в этой таблице
           и будет доступна для применения."
    sx={{width: '1200px', height: '600px', backgroundColor: 'white'}}
    title="Конфигурации отсутствуют"
  />
)

function configurationGroupBySystem(configuration: ReportConfiguration) {
  const tag = configuration?.system?.tag?.trim() ?? configuration?.system?.name?.trim()
  return isEmpty(tag) ? 'Без названия' : `ИС: ${tag}`
}

const ConfigurationsTable: FC = () => {
  const navigate = useNavigate()

  const {tableState, tableProps} = useAppTable<ReportConfiguration, ReportConfigurationTabKind>({
    pages: {
      currentPage: 1,
      pageSize: 20,
    },
    filterValues: [],
    currentTab: ReportConfigurationTabKind.all,
  })

  const user: User = useSelector(userSelector)

  const {
    data: cachedConfigurations,
    currentData: configurations,
    isFetching: isConfigurationsLoading,
    isError: isConfigurationsError,
  } = useGetConfigurationsQuery({filter: fieldMapper(tableState, user.email as string)})

  const {data: userInfo} = useGetUserinfoQuery()

  const [configurationToDelete, setConfigurationToDelete] = useState<string | null>(null)

  const handleRowClick = (configuration: ReportConfiguration) => {
    navigate(`/reports/configurations/${configuration.id}`)
  }

  return (
    <TableContextProvider
      value={{
        onUpdate: (id) => navigate(`/reports/configurations/${id}/update`),
        onDelete: (id) => setConfigurationToDelete(id),
        userInfo,
      }}
    >
      <Box sx={{backgroundColor: 'white', minWidth: 1200}}>
        <AppTable
          noSideBorders
          cachedData={cachedConfigurations?.items ?? []}
          columns={columns}
          components={{
            Row: {
              style: {cursor: 'pointer'},
            },
            FilterCollector: {
              size: 'large',
            },
            HeadFlexboxRow: {
              sx: {
                py: 'x8',
                height: 'auto',
              },
            },
            Cell: {
              style: {
                padding: '12px',
              },
            },
            Head: {
              style: {
                verticalAlign: 'top',
                whiteSpace: 'unset',
              },
            },
            HeadCell: {
              style: {
                padding: '12px',
              },
            },
          }}
          customEmpty={<ConfigurationsEmptyMessage />}
          data={configurations?.items ?? []}
          error={isConfigurationsError}
          getCheckboxRowId={getId}
          getId={getId}
          groupBy={{
            getIdentity: configurationGroupBySystem,
            getDefaultOpen: () => true,
          }}
          loading={isConfigurationsLoading}
          pagination={
            cachedConfigurations
              ? {
                  currentPage: tableState.pages?.currentPage ?? 1,
                  pageSize: tableState.pages?.pageSize ?? 20,
                  totalCount: cachedConfigurations.totalElements,
                  totalPages: cachedConfigurations.totalPages,
                }
              : undefined
          }
          patchFilterCollectorColumns={patchFilterCollectorColumns}
          renderHead={() => (
            <FlexboxRow sx={{justifyContent: 'end', gap: '16px'}}>
              <RouterLink noUnderline to="/reports/configurations/create">
                <Button startIcon={<AddIcon />}>Создать конфигурацию</Button>
              </RouterLink>
            </FlexboxRow>
          )}
          style={{
            width: '1200px',
            maxWidth: '1200px',
            minHeight: 400,
          }}
          tabs={{
            current: tableState.currentTab,
            list: tabs,
          }}
          onRowClick={handleRowClick}
          {...tableProps}
        />
      </Box>
      <DeleteConfigurationModal
        configurationId={configurationToDelete}
        onClose={() => setConfigurationToDelete(null)}
      />
    </TableContextProvider>
  )
}

export default ConfigurationsTable
