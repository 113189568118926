import {convertHexToRgba} from '@x5-react-uikit/core'
import {makeUiStyles} from '../theme'
import {getWidthBySize} from './helpers'

export const useSideModalStyles = makeUiStyles(
  (theme) => ({
    disablePointerEvents: null,
    root: {
      display: 'none',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 99,
      backgroundColor: convertHexToRgba(theme.colors.grey[80], 60),
    },
    open: {display: 'flex', justifyContent: 'end'},
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.colors.white,
      minWidth: (props) => props.minWidth || 440,
      maxWidth: (props) => props.maxWidth || 1200,
      width: '938px',
      height: '100%',
      borderRadius: 8,
      maxHeight: '100%',
      boxShadow: theme.shadows.large,
    },
    content: {
      padding: theme.spaces.join('x0', 'x12'),
      overflowY: 'auto',
      height: '100%',
    },
    contentBorder: {
      borderTop: `1px solid ${theme.colors.grey[20]}`,
      borderBottom: `1px solid ${theme.colors.grey[20]}`,
    },
  }),
  'modal'
)

// todo: sidepage
export const useStyles = makeUiStyles(
  (theme) => ({
    disablePointerEvents: null,
    root: {
      display: 'none',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 99,
      backgroundColor: convertHexToRgba(theme.colors.grey[80], 60),
      padding: theme.spaces.x10,
      '& $disablePointerEvents': {
        pointerEvents: 'none',
      },
    },
    open: {display: 'flex'},
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      background: theme.colors.white,
      minWidth: (props) => props.minWidth || 440,
      maxWidth: (props) => props.maxWidth || 1200,
      width: (props) => (props.size ? getWidthBySize(props.size) : 'fit-content'),
      borderRadius: 8,
      maxHeight: '100%',
      boxShadow: theme.shadows.large,
    },
    content: {
      padding: theme.spaces.join('x0', 'x12'),
      overflowY: 'auto',
    },
    contentBorder: {
      paddingTop: '24px',
      paddingBottom: '24px',
      borderTop: `1px solid ${theme.colors.grey[20]}`,
      borderBottom: `1px solid ${theme.colors.grey[20]}`,
    },
  }),
  'modal'
)

export const useHeaderStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spaces.join('x8', 'x6', 'x6', 'x12'),
    },
    content: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    text: {
      display: 'flex',
      wordBreak: 'break-word',
      '& svg': {
        margin: 'auto',
        marginRight: theme.spaces.x4,
        flexShrink: 0,
        color: theme.colors.grey[60],
      },
      '&.description svg': {
        visibility: 'hidden',
        opacity: 0,
      },
    },
  }),
  'modal-header'
)

export const useFooterStyles = makeUiStyles(
  (theme) => ({
    root: {
      padding: theme.spaces.join('x10', 'x12'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .right-side': {display: 'flex', justifyContent: 'flex-end'},
      '& .right-side button': {marginLeft: theme.spaces.x4, flexShrink: 0},

      '& .left-side button': {
        marginRight: theme.spaces.x4,
        '&:not(:hover)': {
          background: 'none',
        },
      },

      '& .left-side .x5-MuiButton-endIcon': {display: 'none'},
      '& .left-side .x5-MuiButton-startIcon': {marginRight: 0},
    },
  }),
  'modal-footer'
)
