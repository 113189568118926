import {IconButton, Typography, baseTheme} from '@root/components/kit'
import {Copy as CopyIcon} from '@x5-react-uikit/icons'
import useNotify from '@root/hooks/useNotify'
import {copyToClipboard} from 'utils'
import {Tooltip} from 'ui-kit'

import type {CSSProperties, FC} from 'react'
import styled from '@emotion/styled'

const {colors} = baseTheme

type Props = {
  shownText: string
  copiedText: string
  successMessage?: string
  style?: CSSProperties
}

const StyledWrapperButton = styled.button`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  border: 0;
`

const StyledText = styled(Typography)`
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
`

const ImprovedCellCopy: FC<Props> = ({
  shownText,
  copiedText,
  successMessage = 'Скопировано',
  style,
}) => {
  const {notifyError, notifyInfo} = useNotify()
  const handleCopy = () => {
    copyToClipboard(
      copiedText,
      () => notifyInfo(successMessage),
      () => notifyError()
    ).then()
  }

  return (
    <Tooltip doNotClone usePortal content={shownText} placement="top">
      <StyledWrapperButton style={style} onClick={handleCopy}>
        <IconButton
          component="div"
          IconComponent={<CopyIcon color={colors.grey[60]} size="small" />}
          size="small"
          style={{
            padding: 0,
            minWidth: 'unset',
            height: 'fit-content',
            width: 'fit-content',
            color: colors.grey[60],
          }}
          variant="text"
        />
        <StyledText variant="p2">{shownText}</StyledText>
      </StyledWrapperButton>
    </Tooltip>
  )
}

export default ImprovedCellCopy
