import {Button} from '@x5-react-uikit/core'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import EditCommentField from '@root/features/reports/components/EditCommentField'
import EditDynamicField from '@root/features/reports/components/EditDynamicField'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {CommonFieldsOrder} from '../constants'
import ReportAffilationSection from './ReportAffilationSection'
import ReportDataAndTasksSection from './ReportDataAndTasksSection'
import ReportDataSection from './ReportDataSection'

const DYNAMIC_FIELDS_REGISTER = 'reportData.dynamicFields'

const DataStep = ({form}) => {
  form.watch(DYNAMIC_FIELDS_REGISTER)

  const addDynamicField = () => {
    let number = 1
    const dynamicFields = form.getValues(DYNAMIC_FIELDS_REGISTER) || []
    if (dynamicFields?.length > 0) {
      number =
        dynamicFields.sort((fieldA, fieldB) => (fieldB.number > fieldA.number ? -1 : 1)).at(-1)
          .number + 1
    }
    dynamicFields.push({
      values: {comment: {value: ''}},
      label: '',
      number,
    })
    form.setValue(DYNAMIC_FIELDS_REGISTER, dynamicFields)
  }

  return (
    <FlexboxColumn sx={{gap: '24px'}}>
      <ReportDataSection form={form} />
      <FlexboxRow sx={{gap: '24px'}}>
        <ReportAffilationSection form={form} />
        <ReportDataAndTasksSection form={form} />
      </FlexboxRow>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px', my: 0}}>
        {CommonFieldsOrder.map((field) => {
          let fieldIndex = form
            .getValues('reportData.commonFields')
            .findIndex((f) => f.id === field.id)

          return (
            <FlexboxColumn key={field.id} sx={{gap: '24px'}}>
              <EditCommentField
                fieldIndex={fieldIndex}
                form={form}
                meta={ReportCommonFieldsMeta[field.id]}
              />
              <Divider />
            </FlexboxColumn>
          )
        })}
        {form.getValues(DYNAMIC_FIELDS_REGISTER) && (
          <FlexboxColumn sx={{gap: '24px', mb: 'x12'}}>
            {form.getValues(DYNAMIC_FIELDS_REGISTER).map((dynamicField) => (
              <EditDynamicField
                key={dynamicField.number}
                form={form}
                number={dynamicField.number}
              />
            ))}
          </FlexboxColumn>
        )}
        <Button
          startIcon={<AddIcon size="small" />}
          style={{width: 'fit-content'}}
          variant="text"
          onClick={addDynamicField}
        >
          Добавить новое текстовое поле
        </Button>
      </Section>
    </FlexboxColumn>
  )
}

export default DataStep
