import {FC, ReactNode, useState} from 'react'
import {Report} from '@root/features/reports/types'
import {useComparativeReportContext} from '../context'
import {Box, Chip} from 'ui-kit'
import FlexboxRow from '@root/components/FlexboxRow'
import FlexboxColumn from '@root/components/FlexboxColumn'

type ReportSwitchProps = {
  children: (report: Report) => ReactNode
}

export const ReportSwitch: FC<ReportSwitchProps> = ({children}) => {
  const {comparativeReport} = useComparativeReportContext()

  const [index, setIndex] = useState(0)

  const selectedReport = comparativeReport.reportsToCompare[index]

  return (
    <FlexboxColumn style={{gap: '24px'}}>
      <FlexboxRow style={{gap: '16px', flexWrap: 'wrap'}}>
        {comparativeReport.reportsToCompare.map((x, i) => (
          <Chip
            key={x.id}
            checked={index === i}
            label={`Отчет №${i + 1}`}
            variant="outlined"
            onChange={() => setIndex(i)}
          />
        ))}
      </FlexboxRow>
      <Box>{children(selectedReport)}</Box>
    </FlexboxColumn>
  )
}
