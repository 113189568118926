import {StyleSheet, Text} from '@react-pdf/renderer'
import {Column, fontX5Ui, Link, withLineHeight} from '@root/pdf-components'
import {baseTheme} from 'ui-kit'
import {ReactNode} from 'react'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import type {Report} from '@root/features/reports/types'
import {sortBy} from 'lodash'

const smallTextStyle = withLineHeight(14, 16, {
  fontWeight: 400,
  fontFamily: fontX5Ui,
  letterSpacing: '0.08px',
})

const greyStyle = {
  color: baseTheme.colors.grey[100],
}

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#EFF1F5',
    padding: '16px',
    gap: '24px',
  },
  smallText: smallTextStyle,
  grey: greyStyle,
  nonEditableText: {
    ...smallTextStyle,
    ...greyStyle,
  },
  text: withLineHeight(16, 20, {
    fontWeight: 400,
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
  }),
  listItem: {
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'baseline',
  },
  testVariables: {
    gap: '16px',
  },
  testVariableItem: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '24px',
    padding: '14px 16px',
    backgroundColor: '#F5F7F7',
  },
  testVariableItemTextIndex: withLineHeight(16, 20, {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: '#222626',
  }),
  testVariableItemTextKey: {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: baseTheme.colors.grey[100],
    fontWeight: 500,
  },
  testVariableItemTextValue: {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: '#222626',
  },
})

export type Anchor = {
  text: string
  href: string
}

export const noDataStub = (
  <Text style={{fontFamily: fontX5Ui, color: baseTheme.colors.grey[60]}}>Не заполнен</Text>
)

export const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

export function enhanceText(input: string): ReactNode | null {
  if (!input) {
    return null
  }

  const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/\S*)?/gi
  const result = []
  let lastIndex = 0

  let match: RegExpExecArray
  while ((match = urlRegex.exec(input)) !== null) {
    if (match.index > lastIndex) {
      result.push(<Text key={lastIndex}>{input.substring(lastIndex, match.index)}</Text>)
    }

    result.push(
      <Link key={match.index} href={withHttp(match[0])}>
        {match[0]}
      </Link>
    )

    lastIndex = match.index + match[0].length
  }

  if (lastIndex < input.length) {
    result.push(<Text key={lastIndex}>{input.substring(lastIndex)}</Text>)
  }

  return <Text style={styles.text}>{result}</Text>
}

export const tableMeta = ReportCommonFieldsMeta.configuration.values[0]

if (tableMeta == null || tableMeta.type !== 'table') {
  throw new Error('Unexpected error. Unable to find default table declaration for report')
}

export const configurationTableColumns = Object.values(tableMeta.columns).map(
  (x) =>
    ({
      dataIndex: x.id as string,
      title: x.label,
    }) as Column
)

export function getCommonFieldsMap(report: Report) {
  return (report.reportData?.commonFields ?? []).reduce(
    (acc, next) => {
      acc[next.id] = next
      return acc
    },
    {} as Record<string, Report['reportData']['commonFields'][0]>
  )
}

export function getDynamicFieldsMap(report: Report) {
  return (report.reportData?.dynamicFields ?? []).reduce(
    (acc, next) => {
      acc[next.id] = next
      return acc
    },
    {} as Record<string, Report['reportData']['dynamicFields'][0]>
  )
}

export const getAnchorsWithDynamicFields = (
  dynamicFieldsMap: DynamicFieldsMap,
  getAnchors: (x: Anchor[], showStats: boolean) => Anchor[],
  showStats: boolean
): Anchor[] =>
  getAnchors(
    sortBy(Object.values(dynamicFieldsMap), 'number').map((x, i) => ({
      text: x.label!,
      href: x.id ? x.id.toString() : i.toString(),
    })),
    showStats
  )

export type CommonFieldsMap = ReturnType<typeof getCommonFieldsMap>
export type DynamicFieldsMap = ReturnType<typeof getDynamicFieldsMap>
