import {Fragment, ReactNode} from 'react'
import {Divider} from '@mui/material'
type Props<T> = {
  arr: T[]
  children: (x: T, index: number) => ReactNode
  getKey: (x: T, index: number) => string | number
}

export function RenderDivider<T>({children, arr, getKey}: Props<T>) {
  return (
    <Fragment>
      {arr.map((x, index) => (
        <Fragment key={getKey(x, index)}>
          {children(x, index)}
          {index !== arr.length - 1 && <Divider sx={{my: 'x8'}} />}
        </Fragment>
      ))}
    </Fragment>
  )
}
