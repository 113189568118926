import {result, Result, Error} from './result'
import {isNotFoundError} from '@root/utils'
import {match} from 'ts-pattern'
import {isArray} from 'lodash'
import {AxiosError} from 'axios'

export enum FetchError {
  NOT_FOUND,
  UNKNOWN,
}

export type ServerError = {type: FetchError.NOT_FOUND; message: string} | {type: FetchError.UNKNOWN}

export function getErrorFromAxios(error: AxiosError): Error<ServerError> {
  if (error.status === 404) {
    return result.error({type: FetchError.NOT_FOUND, message: ''})
  }

  return result.error({type: FetchError.UNKNOWN})
}

export function getErrorFromQuery(error: unknown): Error<ServerError> {
  if (isNotFoundError(error)) {
    return result.error({
      type: FetchError.NOT_FOUND,
      message: match<boolean>(true)
        .with(isArray(error.data?.messages), () => error.data?.messages[0])
        .with(error.data.error != null, () => error.data.error)
        .with(error.data.message != null, () => error.data.message)
        .otherwise(() => 'No message'),
    })
  }

  return result.error({type: FetchError.UNKNOWN})
}

export function isServerError(isError: boolean, error: unknown): Result<null, ServerError> {
  if (!isError) {
    return result.ok(null)
  }

  return getErrorFromQuery(error)
}

export function matchServerErrorToString(error: ServerError): string {
  return match(error)
    .with({type: FetchError.NOT_FOUND}, ({message}) => message)
    .otherwise(() => 'Unknown server error')
}
