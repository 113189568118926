import {fontX5Ui} from './constants'
import {Text, View} from '@react-pdf/renderer'
import {FC, PropsWithChildren} from 'react'
import {baseTheme} from 'ui-kit'
import {ArrowUp} from './icons'
import {Style} from '@react-pdf/types'

type Props = PropsWithChildren<{
  text: string
}>

const headerText: Style = {
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: fontX5Ui,
  letterSpacing: '0.12px',
}

export const Accordion: FC<Props> = ({text, children}) => {
  return (
    <View style={{gap: '24px'}}>
      <View
        style={{
          height: '40px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: '16px',
          backgroundColor: baseTheme.colors.accent[10],
          borderBottom: `1px solid ${baseTheme.colors.accent[20]}`,
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '4px',
          }}
        >
          <View
            style={{
              height: '24px',
              width: '24px',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <ArrowUp height={4.39} width={7.45} />
          </View>

          <Text style={headerText}>{text}</Text>
        </View>
      </View>
      <View>{children}</View>
    </View>
  )
}
