import {useCallback, useRef, useState} from 'react'
import {pdf} from '@react-pdf/renderer'
import {PdfComparativeReport} from '../pdf/PdfComparativeReport'
import {ComparativeReport} from '../types'
import {saveFile} from '@root/utils'
import {
  enhanceComparativeReportWithImages,
  getComparativeFilesErrorMessage,
} from '@root/features/reports/reportStatisticsImages'
import {match} from 'ts-pattern'
import useNotify from '@root/hooks/useNotify'
import comparativeReportApi from '@root/redux/api/report/comparativeReportApi'

export const useDownloadComparativeReportPdf = (comparativeReport?: ComparativeReport | null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const promise = useRef<Promise<Blob>>()
  const [getStatisticsImageLinks] =
    comparativeReportApi.useLazyGetComparativeReportStatisticsImageLinksQuery()
  const {notifyError} = useNotify()

  const download = useCallback(() => {
    if (comparativeReport === null || promise.current != null) {
      return
    }

    setLoading(true)

    promise.current = enhanceComparativeReportWithImages(comparativeReport, (id) =>
      getStatisticsImageLinks(id).unwrap()
    ).then((x) =>
      match(x)
        .returnType<Promise<Blob>>()
        .with({isOk: true}, ({value}) =>
          pdf(<PdfComparativeReport comparativeReport={value} />).toBlob()
        )
        .with({isError: true}, ({value: {type}}) => {
          notifyError(getComparativeFilesErrorMessage(type))
          throw new Error('Images failed to fetch')
        })
        .exhaustive()
    )

    promise.current
      .then((blob) => {
        saveFile(blob, `Сравнительный отчет: ${comparativeReport.name}`)
      })
      .catch((err) => {
        console.error(`Unexpected error while forming report pdf:`)
        console.error(err)
        notifyError('Не удалось сформировать pdf')
        setError(true)
      })
      .finally(() => {
        setLoading(false)
        promise.current = null
      })
  }, [comparativeReport, getStatisticsImageLinks, notifyError])

  return {
    download,
    disabled: comparativeReport === null,
    loading,
    error,
  }
}
