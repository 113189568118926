import {SystemListFilterPayload} from '@root/features/systems/types'
import {FilterItemType} from '@root/openapi'
import LoaderFrame from '@root/components/Loader'
import {debounce} from 'lodash'
import React, {useMemo, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {Combobox} from 'ui-kit'
import {RequiredRule} from '@root/constants'

const fieldRegister = 'systemId'
const debouncedChange = debounce((event, cb) => cb(event), 1000)
const defaultFilter: SystemListFilterPayload = {
  pageNumber: 1,
  pageSize: 99,
  filters: {},
}

const SystemsCombobox = ({form}) => {
  const [filter, setFilter] = useState(defaultFilter)

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedChange(event, (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setValue(fieldRegister, undefined)
      const systemName = event.target.value
      setFilter((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          'name|cod': {value: systemName, type: FilterItemType.LIKE} as any,
        },
      }))
    })
  }

  const {data: rawSystems, isLoading: isSystemsLoading} = useGetSystemsQuery({filter})

  const systems = useMemo(
    () =>
      rawSystems?.items?.map((system) => ({
        name: system.name,
        value: system.id,
        caption: system.cod,
      })) ?? [],
    [rawSystems]
  )

  if (isSystemsLoading) return <LoaderFrame height="88px" />

  return (
    <Controller
      control={form.control}
      name={fieldRegister}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        return (
          <Combobox
            required
            caption="Введите КЕ или наименование из MFSM, либо выберите из списка"
            error={!!error}
            label="Информационная система"
            options={systems}
            textError={
              filter.filters?.name
                ? 'Такой ИС нет в списке. Проверьте формат данных.'
                : error?.message
            }
            value={value}
            width={320}
            onChange={(e, system) => {
              onChange(system)
            }}
            onClearClick={() => {
              form.resetField(fieldRegister)
              setFilter(defaultFilter)
            }}
            onFilterChange={onChangeInput}
          />
        )
      }}
      rules={RequiredRule}
    />
  )
}

export default SystemsCombobox
