import {useCallback, useRef, useState} from 'react'
import {pdf} from '@react-pdf/renderer'
import {PdfReport} from '../pdf/PdfReport'
import {ComparativeReport, UnionReport, Report, Graph} from '../types'
import {id, memoize, notEmpty, saveFile} from '@root/utils'
import {isEmpty} from 'lodash'
import {
  isCommonReport,
  isComparativeReport,
  isReportComparative,
} from '@root/features/reports/reportComparison'
import reportsApi from '@root/redux/api/report/reportsApi'
import comparativeReportApi from '@root/redux/api/report/comparativeReportApi'
import {match} from 'ts-pattern'
import {PdfComparativeReport} from '@root/features/reports/pdf/PdfComparativeReport'
import {aggregateResults, result, Result, Error} from '@root/core/result'
import {
  getErrorFromQuery,
  matchServerErrorToString,
  ServerError,
} from '@root/core/serverResultParser'
import useNotify from '@root/hooks/useNotify'
import JSZip from 'jszip'
import {useIsMounted} from '@root/hooks/useIsMounted'
import dayjs from 'dayjs'
import {
  enhanceComparativeReportWithImages,
  enhanceReportWithImages,
  grafanaDisplayErrorMessage,
  image404DisplayErrorMessage,
} from '@root/features/reports/reportStatisticsImages'

enum ErrorType {
  SERVER_ERROR,
  BLOB_ERROR,
  ZIP_ERROR,
  GRAFANA_ERROR,
  IMAGES_ERROR,
}

type PdfErrorType =
  | {type: ErrorType.SERVER_ERROR; body: ServerError}
  | {type: ErrorType.GRAFANA_ERROR; body: ServerError}
  | {type: ErrorType.IMAGES_ERROR; body: ServerError}
  | {type: ErrorType.BLOB_ERROR; report: Report | ComparativeReport; error: unknown}
  | {type: ErrorType.ZIP_ERROR; error: unknown}

const errors = {
  createServer: (body: ServerError): PdfErrorType => ({type: ErrorType.SERVER_ERROR, body}),
  createGrafana: (body: ServerError): PdfErrorType => ({type: ErrorType.GRAFANA_ERROR, body}),
  createImages: (body: ServerError): PdfErrorType => ({type: ErrorType.IMAGES_ERROR, body}),
  createBlob: (report: Report | ComparativeReport, error: unknown): PdfErrorType => ({
    type: ErrorType.BLOB_ERROR,
    report,
    error,
  }),
  createZip: (error: unknown): PdfErrorType => ({type: ErrorType.ZIP_ERROR, error}),
}

type GetImagesFn = (isComparative: boolean) => (reportId: string) => Promise<Graph[]>

async function getBlob(
  report: Report | ComparativeReport,
  getImages: GetImagesFn
): Promise<Result<Blob, PdfErrorType[]>> {
  const componentResult = await match(isComparativeReport(report))
    .with(true, () =>
      enhanceComparativeReportWithImages(report, getImages(true)).then((res) =>
        result.mapError(
          result.map(res, (x) => <PdfComparativeReport comparativeReport={x} />),
          ({data, type}) =>
            match(type)
              .with('files', () => data.map((x) => errors.createImages(x)))
              .with('grafana', () => data.map((x) => errors.createGrafana(x)))
              .exhaustive()
        )
      )
    )
    .with(false, () =>
      enhanceReportWithImages(report, getImages(false)).then((res) =>
        result.mapError(
          result.map(res, (x) => <PdfReport report={x} />),
          (err) => [errors.createGrafana(err)]
        )
      )
    )
    .exhaustive()

  if (result.isError(componentResult)) {
    return componentResult
  }

  try {
    const blob = await pdf(componentResult.value).toBlob()

    return result.ok(blob)
  } catch (error) {
    return result.error([errors.createBlob(report, error)])
  }
}

function getBlobForEachReport<T extends Report | ComparativeReport>(
  reports: T[],
  getImages: GetImagesFn
): Promise<Result<[T, Blob], PdfErrorType[]>[]> {
  return Promise.all(
    reports.map(async (x) =>
      result.mapError(
        result.map(await getBlob(x, getImages), (blob) => [x, blob]),
        (errList) => errList.flatMap(id)
      )
    )
  )
}

function mapServerError(error: unknown): Error<PdfErrorType> {
  return result.error(errors.createServer(getErrorFromQuery(error).value))
}

async function fetchAndGetBlob<T extends Report | ComparativeReport>(
  unionReports: UnionReport[],
  defineSubtype: (x: UnionReport) => boolean,
  fetch: (id: string[]) => Promise<T[]>,
  getImages: GetImagesFn
): Promise<Result<[T, Blob], PdfErrorType[]>[] | null> {
  const subtypeArr = unionReports.filter(defineSubtype)

  if (isEmpty(subtypeArr)) {
    return null
  }

  let reports: T[]

  try {
    reports = await fetch(subtypeArr.map((x) => x.id))
  } catch (err) {
    return [result.mapError(mapServerError(err), (x) => [x])]
  }

  return getBlobForEachReport(reports, getImages)
}

function getFileName(report: Report | ComparativeReport, i: number) {
  return (
    match(isComparativeReport(report))
      .with(false, () => `${i} Отчет: ${report.name}`)
      .with(true, () => `${i} Сравнительный отчет: ${report.name}`)
      .exhaustive() + '.pdf'
  )
}

export const useDownloadManyReportsPdf = (reports?: UnionReport[]) => {
  const [getReportsByIds] = reportsApi.useLazyGetReportsByIdQuery()
  const [getComparativeReportsByIds] = comparativeReportApi.useLazyGetComparativeReportsByIdQuery()
  const [getReportStatisticsImageLinks] = reportsApi.useLazyGetReportStatisticsImageLinksQuery()
  const [getComparativeReportStatisticsImageLinks] =
    comparativeReportApi.useLazyGetComparativeReportStatisticsImageLinksQuery()

  const isMountedRef = useIsMounted()

  const {notifyError} = useNotify()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const mainPromise =
    useRef<Promise<Result<[Report | ComparativeReport, Blob], PdfErrorType[]>[]>>()

  const isEmptyReports = isEmpty(reports)

  const download = useCallback(async () => {
    if (isEmptyReports || mainPromise.current != null) {
      return
    }

    setLoading(true)

    const getImages = memoize((isComparativeReport: boolean) => (id: string) => {
      const req = isComparativeReport
        ? getComparativeReportStatisticsImageLinks
        : getReportStatisticsImageLinks

      return req(id).unwrap()
    })

    const resultsPromiseArray = [
      fetchAndGetBlob(reports, isCommonReport, (x) => getReportsByIds(x).unwrap(), getImages),
      fetchAndGetBlob(
        reports,
        isReportComparative,
        (x) => getComparativeReportsByIds(x).unwrap(),
        getImages
      ),
    ]

    mainPromise.current = Promise.all(resultsPromiseArray).then((res) => res.flatMap(id))

    try {
      const exported = (await mainPromise.current).filter(notEmpty)

      const aggregatedResult = result.mapError(aggregateResults(exported), (errList) =>
        errList.flatMap(id)
      )

      const zipResult = await new Promise<Result<Blob, PdfErrorType[]>>((resolve) => {
        match(aggregatedResult)
          .with({isError: true}, (error) => resolve(error))
          .otherwise((results) => {
            const zip = new JSZip()
            let i = 0
            for (const [report, blob] of results.value) {
              i++
              const fileName = getFileName(report, i)
              zip.file(fileName, blob)
            }

            zip
              .generateAsync({type: 'blob'})
              .then((content) => resolve(result.ok(content)))
              .catch((error) => resolve(result.error([errors.createZip(error)])))
          })
      })

      match(zipResult)
        .with({isOk: true}, (zipFile) => {
          saveFile(
            zipFile.value,
            `экспорт отчетов ${dayjs(Date.now()).format('DD.MM.YYYY hh_mm')}.zip`
          )
        })
        .otherwise((errors) => {
          for (const error of errors.value) {
            console.error('export error', error)

            const errMessage = match(error)
              .with({type: ErrorType.GRAFANA_ERROR}, () => grafanaDisplayErrorMessage)
              .with({type: ErrorType.IMAGES_ERROR}, () => image404DisplayErrorMessage)
              .with({type: ErrorType.SERVER_ERROR}, ({body}) => matchServerErrorToString(body))
              .with(
                {type: ErrorType.BLOB_ERROR},
                ({report}) => `Unable to create pdf blob for report: ${report.id}`
              )
              .otherwise(() => 'Unknown error')

            notifyError(errMessage)
          }

          if (isMountedRef.current) {
            setError(true)
          }
        })
    } catch (err) {
      console.error(`Unexpected error`)
      console.error(err)

      if (isMountedRef.current) {
        setError(true)
      }
    } finally {
      if (isMountedRef.current) {
        setLoading(false)
        mainPromise.current = null
      }
    }
  }, [
    isEmptyReports,
    reports,
    getComparativeReportStatisticsImageLinks,
    getReportStatisticsImageLinks,
    getReportsByIds,
    getComparativeReportsByIds,
    isMountedRef,
    notifyError,
  ])

  return {
    download,
    disabled: isEmptyReports,
    loading,
    error,
  }
}
