import {MetaRendererProps, MetaValueType, TableMeta} from './types'
import {FC} from 'react'
import {match} from 'ts-pattern'
import Table from './table'

const MetaRenderer: FC<MetaRendererProps<TableMeta>> = ({meta, isEdit, value, fallback}) => {
  return match(meta.type)
    .with(MetaValueType.table, () => (
      <Table fallback={fallback} isEdit={isEdit} meta={meta} value={value} />
    ))
    .exhaustive()
}

export default MetaRenderer
