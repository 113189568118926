import {Table} from '@root/pdf-components'
import {type FC, useMemo} from 'react'
import {type CommonFieldsMap, configurationTableColumns} from './common'

type Props = {
  commonFieldsMap: CommonFieldsMap
}

export const ConfigurationTable: FC<Props> = ({commonFieldsMap}) => {
  const tableData = useMemo(() => {
    return commonFieldsMap?.configuration.values.table[0].rows.map(({cells}) => {
      return cells.reduce(
        (acc, next) => {
          acc[next.columnId] = next.values.text.value
          return acc
        },
        {} as Record<string, string>
      )
    })
  }, [commonFieldsMap.configuration.values.table])

  return <Table columns={configurationTableColumns} data={tableData} />
}
