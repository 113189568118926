import {ComponentProps, type FC, useMemo} from 'react'
import type {Report} from '../types'
import {Table, Column} from '@root/pdf-components/Table'
import {formatDateTimeRuLocale} from '@root/utils'
import {
  ReportToCompareRow,
  reportToCompareRow,
} from '@root/features/reports/comparisonTableConstants'
import {Link, Section, Title} from '@root/pdf-components'
import {View, Text} from '@react-pdf/renderer'
import {styles} from './common'

const columns: Column<ReportToCompareRow>[] = [
  {
    dataIndex: 'id',
    title: 'Номер',
    width: 150,
    renderCell: (_, {number}) => <Text style={styles.nonEditableText}>Отчет №{number}</Text>,
  },
  {
    dataIndex: 'name',
    title: 'Отчет',
  },
  {
    dataIndex: 'task',
    title: 'Заявка',
    renderCell: (_, {task: {id, number}}) => (
      <Link
        href={new URL(`${window.location.origin}/tasks/${id}`).toString()}
        style={styles.smallText}
      >
        #{number}
      </Link>
    ),
  },
  {
    dataIndex: 'test',
    title: 'Тест',
    // TODO: THERE IS NO ROUTE TO A TEST LAUNCH
    renderCell: (_, {test: {number}}) => <Text style={styles.nonEditableText}>ID {number}</Text>,
  },
  {
    dataIndex: 'testFinishedAt',
    title: 'Дата и время завершения теста',
    renderCell: (_, {testFinishedAt}) => (
      <Text style={styles.nonEditableText}>{formatDateTimeRuLocale(testFinishedAt)}</Text>
    ),
  },
  {
    dataIndex: 'template',
    title: 'Шаблон переменных',
    renderCell: (_, {template: {name}}) => <Text style={styles.nonEditableText}>{name}</Text>,
  },
]

type Props = {
  reports: Report[]
} & Partial<ComponentProps<typeof Section>>

export const ComparisonTable: FC<Props> = ({reports, ...other}) => {
  const data = useMemo(() => reports.map((x, i) => reportToCompareRow(x, i + 1)), [reports])

  return (
    <Section style={{paddingHorizontal: 0, paddingBottom: '40px'}} {...other}>
      <View style={{marginBottom: '24px', marginHorizontal: '39px'}}>
        <Title level="h2">Сравниваемые отчеты</Title>
      </View>
      <Table noSideBorders columns={columns} data={data} />
    </Section>
  )
}
