import {FC, useEffect, useState} from 'react'
import {PDFViewer} from '@react-pdf/renderer'
import {PdfComparativeReport} from '@root/features/reports/pdf/PdfComparativeReport'
import {useParams} from 'react-router-dom'

import reportsApi, {
  useGetByIdComparativeReportQuery,
} from '@root/redux/api/report/comparativeReportApi'
import {ComparativeReportWithImages} from '@root/features/reports/types'
import {enhanceComparativeReportWithImages} from '@root/features/reports/reportStatisticsImages'
import {match} from 'ts-pattern'

export const ComparativeReportPdfPreviewPage: FC = () => {
  const {reportId} = useParams()
  const [reportWithImages, setReportWithImages] = useState<ComparativeReportWithImages>()

  const {data: comparativeReport} = useGetByIdComparativeReportQuery(reportId)
  const [getStatisticsImageLinks] =
    reportsApi.useLazyGetComparativeReportStatisticsImageLinksQuery()

  useEffect(() => {
    if (!comparativeReport) {
      return
    }
    enhanceComparativeReportWithImages(comparativeReport, (id) =>
      getStatisticsImageLinks(id).unwrap()
    ).then((x) =>
      match(x)
        .with({isOk: true}, ({value}) => setReportWithImages(value))
        .with({isError: true}, () => {
          throw new Error('Images failed to fetch')
        })
        .exhaustive()
    )
  }, [getStatisticsImageLinks, comparativeReport])

  if (!reportWithImages) {
    return <>"Loading"</>
  }

  return (
    <PDFViewer width="100%">
      <PdfComparativeReport comparativeReport={reportWithImages} />
    </PDFViewer>
  )
}
