import {Button} from '@x5-react-uikit/core'
import {Copy as CopyIcon} from '@x5-react-uikit/icons'
import {TaskStatuses} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {useNavigate, useParams} from 'react-router-dom'
import {
  useCopyTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskStatusMutation,
} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {match} from 'ts-pattern'

const TaskActionButtons = ({setModalChangeStatus}) => {
  const {notifySuccess, notifyError} = useNotify()
  const navigate = useNavigate()
  const {taskId} = useParams()

  const {data: task} = useGetTaskByIdQuery(taskId)
  const {data: userInfo} = useGetUserinfoQuery()

  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const updateData = {nextTaskStatus: task.previousStatus}
  const handleResumeTask = () => {
    updateTaskStatus({taskId, updateData})
      .unwrap()
      .then(() =>
        notifySuccess(
          'Заявка успешно возобновлена',
          'Участники заявки получат уведомление об этом в outlook.'
        )
      )
      .catch((error) => {
        match(error)
          .with({status: 409, data: {messages: ['You need to add an performers']}}, () => {
            notifyError('Необходимо указать хотя бы одного специалиста')
          })
          .with(
            {status: 409, data: {messages: ['The system already has an active request']}},
            () => {
              notifyError('Текущая ИС уже имеет активную заявку.')
            }
          )
          .otherwise(() => {
            console.log(error)
            notifyError()
          })
      })
  }

  const [copyTask, {isLoading: isCopying}] = useCopyTaskMutation()
  const handleCopyTask = () => {
    copyTask(taskId)
      .unwrap()
      .then((createdTask) => {
        navigate(`/tasks/create/${createdTask.id}/steps/1`)
        notifySuccess('Заявка скопирована')
      })
      .catch((error) => {
        const msg =
          error.status === 409 ? 'Для выбранной системы существует активная заявка' : undefined
        notifyError(msg, 'Не удалось скопировать заявку')
        console.log(error)
      })
  }

  if (userInfo.isManager || userInfo.isAdmin) {
    switch (task?.status) {
      case TaskStatuses.IN_REVIEW:
        return (
          <>
            <Button variant="outlined" onClick={() => setModalChangeStatus(TaskStatuses.SUSPENDED)}>
              Приостановить
            </Button>
            <Button
              variant="outlined"
              onClick={() => setModalChangeStatus(TaskStatuses.NOT_REQUIRED)}
            >
              НТ не требуется
            </Button>
            <Button onClick={() => setModalChangeStatus(TaskStatuses.SEARCHING_PERFORMER)}>
              Начать поиск исполнителя
            </Button>
          </>
        )
      case TaskStatuses.SEARCHING_PERFORMER:
        return (
          <>
            <Button variant="outlined" onClick={() => setModalChangeStatus(TaskStatuses.SUSPENDED)}>
              Приостановить
            </Button>
            <Button onClick={() => setModalChangeStatus(TaskStatuses.IN_PROGRESS)}>
              Отправить в работу
            </Button>
          </>
        )
      case TaskStatuses.SUSPENDED:
        return (
          <>
            <Button onClick={handleResumeTask}>Возобновить</Button>
          </>
        )
      case TaskStatuses.IN_PROGRESS:
        return (
          <>
            <Button variant="outlined" onClick={() => setModalChangeStatus(TaskStatuses.SUSPENDED)}>
              Приостановить
            </Button>
            <Button onClick={() => setModalChangeStatus(TaskStatuses.COMPLETED_SUCCESSFULLY)}>
              Завершить испытания
            </Button>
          </>
        )
      default:
        return <></>
    }
  } else if (userInfo.isBusiness) {
    switch (task?.status) {
      case TaskStatuses.IN_REVIEW:
        return (
          <Button variant="outlined" onClick={() => setModalChangeStatus(TaskStatuses.WITHDRAWN)}>
            Отозвать заявку
          </Button>
        )
      case TaskStatuses.COMPLETED_SUCCESSFULLY:
      case TaskStatuses.NOT_REQUIRED:
      case TaskStatuses.SYSTEM_SENT_FOR_REVISION:
        return (
          <Button
            disabled={isCopying}
            loading={isCopying}
            startIcon={<CopyIcon size="small" />}
            variant="outlined"
            onClick={handleCopyTask}
          >
            Копировать заявку
          </Button>
        )
      case TaskStatuses.SEARCHING_PERFORMER:
      case TaskStatuses.IN_PROGRESS:
      case TaskStatuses.SUSPENDED:
      default:
        return <></>
    }
  }
  return <></>
}

export default TaskActionButtons
