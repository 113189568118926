import {Buffer} from 'buffer'
import {aggregateResults, Result, result} from '@root/core/result'
import {getErrorFromQuery, ServerError} from '@root/core/serverResultParser'
import {
  ComparativeReport,
  ComparativeReportWithStatisticsImages,
  Graph,
  GraphWithImageData,
  Report,
  ReportWithStatisticsImages,
} from './types'

import {axiosClient} from '@root/axiosClient'

async function downloadRequest(fileName: string) {
  const response = await axiosClient.get(`/reports/statistics-images/download/${fileName}`, {
    responseType: 'arraybuffer',
  })

  return Buffer.from(response.data, 'binary')
}

export async function downloadImages(
  reportId: string,
  requestFn: (reportId: string) => Promise<Graph[]>
): Promise<Result<GraphWithImageData[], ServerError>> {
  try {
    const graphs = await requestFn(reportId)

    const promises = graphs.map((x) =>
      downloadRequest(x.graphUrl).then<GraphWithImageData>((buffer) => ({
        comment: x.comment,
        imageBuffer: buffer,
        graphName: x.graphName,
      }))
    )

    return result.ok(await Promise.all(promises))
  } catch (err) {
    console.log(err)
    return getErrorFromQuery(err)
  }
}

export async function enhanceReportWithImages(
  report: Report,
  requestFn: (reportId: string) => Promise<Graph[]>
): Promise<Result<ReportWithStatisticsImages, ServerError>> {
  return result.map(await downloadImages(report.id, requestFn), (images) => {
    const reportWithImages: ReportWithStatisticsImages = {
      ...report,
      statistics: {
        graphs: images,
      },
    }

    return reportWithImages
  })
}

export async function enhanceComparativeReportWithImages(
  comparativeReport: ComparativeReport,
  requestFn: (reportId: string) => Promise<Graph[]>
): Promise<Result<ComparativeReportWithStatisticsImages, ServerError[]>> {
  const aggregated = aggregateResults(
    await Promise.all(
      comparativeReport.reportsToCompare.map((x) => enhanceReportWithImages(x, requestFn))
    )
  )

  return result.map(aggregated, (reportWithImages) => {
    const comparativeWithImages: ComparativeReportWithStatisticsImages = {
      ...comparativeReport,
      reportsToCompare: reportWithImages,
    }

    return comparativeWithImages
  })
}
