import {placements} from '@x5-react-uikit/tokens'
import {PropsWithChildren, ReactNode} from 'react'
import {QA} from '../types'

export const tooltipPlacements = placements
export type TooltipPlacement = (typeof placements)[keyof typeof placements]

export type TooltipContentWidth = 'auto' | 'pre' | number

export type TooltipProps = QA &
  PropsWithChildren<{
    zIndex?: number
    placement?: TooltipPlacement
    content?: ReactNode
    className?: string
    contentWidth?: TooltipContentWidth
    interactive?: boolean
    usePortal?: boolean
    doNotClone?: boolean
  }>
