import {FC} from 'react'
import {Modal, Typography} from 'ui-kit'

type Props = {
  open: boolean
  onClose: () => void
  onCancel: () => void
  handleChangeStatus: () => void
  message: string
  isSubmiting?: boolean
}

const SubmitChangeStatus: FC<Props> = ({
  open,
  isSubmiting,
  onClose,
  onCancel,
  message,
  handleChangeStatus,
}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onCancel,
          side: 'right',
          text: 'Назад',
        },
        {
          onClick: handleChangeStatus,
          side: 'right',
          text: 'Изменить статус',
          props: {
            loading: isSubmiting,
            disabled: isSubmiting,
          },
        },
      ]}
      isOpen={open}
      size="medium"
      title="Изменение статуса заявки"
      onClose={onClose}
    >
      <Typography>{message}</Typography>
    </Modal>
  )
}

export default SubmitChangeStatus
