import {
  ArrayPath,
  ControllerProps,
  FieldArray,
  FieldPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'
import {IconButton} from '@x5-react-uikit/core'
import {Add as AddIcon, Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {useCallback} from 'react'
import FormTmpFileUpload from '@root/components/inputs/formInputs/FormTmpFileUpload'
import {FileType, FileWithComment, FormFileWithComment} from '@root/features/fileUpload'
import {genericMemo} from '@root/utils'
import {Button} from 'ui-kit'
import {mapFileBackToFormUri, mapUrlToFileUploaderItem} from '@root/components/inputs/TmpFileUpload'
import {isEmpty} from 'lodash'
import {RenderDivider} from 'ui-kit/src/RenderDivider'

type Props<TFieldValues extends FieldValues> = {
  name: ArrayPath<TFieldValues>
  fileType: FileType
  limit?: number
} & Pick<ControllerProps<TFieldValues>, 'control'>

const emptyFormFile: FormFileWithComment = {
  comment: '',
  name: '',
  file: [],
}

export function mapFileWithCommentsToForm(
  fileWithComments: FileWithComment[] | null
): FormFileWithComment[] {
  if (isEmpty(fileWithComments)) {
    return []
  }

  return fileWithComments.map((x) => ({
    ...x,
    file: x.url ? [mapUrlToFileUploaderItem(x.url)] : [],
  }))
}

export function mapFileWithCommentsToDto(arr: FormFileWithComment[]): FileWithComment[] {
  if (isEmpty(arr)) {
    return []
  }

  return arr.map((x) => ({
    url: mapFileBackToFormUri(x.file[0]),
    name: x.name,
    comment: x.comment,
  }))
}

function FileWithCommentArray<TFieldValues extends FieldValues>({
  name,
  control,
  fileType,
  limit,
}: Props<TFieldValues>) {
  const {fields, remove, append} = useFieldArray({
    name,
    control,
  })

  const isLimited = limit > 1 ? fields.length === limit : false

  const appendEmpty = useCallback(() => {
    append(emptyFormFile as FieldArray<TFieldValues, ArrayPath<TFieldValues>>)
  }, [append])

  return (
    <FlexboxColumn sx={{gap: '24px'}}>
      <RenderDivider arr={fields} getKey={(x) => x.id}>
        {(_field, index) => (
          <FlexboxRow sx={{gap: '24px', alignItems: 'top', justifyContent: 'space-between'}}>
            <FlexboxColumn sx={{gap: '16px'}}>
              <FormInputText
                required
                control={control}
                label="Название изображения"
                name={`${name}.${index}.name` as FieldPath<TFieldValues>}
                rules={RequiredRule}
                width="520px"
              />
              <FormTmpFileUpload
                control={control}
                name={`${name}.${index}.file` as FieldPath<TFieldValues>}
                type={fileType}
              />
              <FormInputText
                textarea
                control={control}
                label="Комментарий"
                name={`${name}.${index}.comment` as FieldPath<TFieldValues>}
                width="520px"
              />
            </FlexboxColumn>
            <IconButton IconComponent={<DelIcon />} variant="text" onClick={() => remove(index)} />
          </FlexboxRow>
        )}
      </RenderDivider>

      <Button
        disabled={isLimited}
        startIcon={<AddIcon size="small" />}
        style={{width: 'fit-content'}}
        variant="text"
        onClick={appendEmpty}
      >
        Добавить изображение
      </Button>
    </FlexboxColumn>
  )
}

export default genericMemo(FileWithCommentArray)
