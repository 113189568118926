import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

// TODO: STATE AND USER TYPES ARE NOT DEFINED
const getDecodedAuth = (token: string) => {
  try {
    const user = jwtDecode(token)

    return {
      logining: false,
      user: user,
      jwtToken: token,
      // @ts-ignore
      error: null,
      success: false,
    }
  } catch (err) {
    console.error('Ошибка при попытке декодировать jwt')
    console.error(err)
  }
}

const createInitialState = () => {
  const token = localStorage.getItem('token')
  if (!token)
    return {
      logining: false,
      user: null,
      jwtToken: null,
      error: null,
      success: false,
    }
  return getDecodedAuth(token)
}

const authSlice = createSlice({
  name: 'auth',
  initialState: createInitialState(),
  reducers: {
    updateJwtToken: (state, action: PayloadAction<string>) => {
      if (action.payload == null) {
        throw new Error('Payload was null')
      }

      const decoded = getDecodedAuth(action.payload)

      Object.entries(decoded).forEach(([key, value]) => {
        // @ts-ignore TODO: HAD NO TIME FOR THIS
        state[key] = value
      })
    },
  },
  extraReducers: {},
})

export const {updateJwtToken} = authSlice.actions
export default authSlice.reducer
