import {Typography, baseTheme} from 'ui-kit'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {FC} from 'react'

const {colors} = baseTheme

type VariableElementProps = {
  varNumber: number
  varKey: string
  varValue: string
}
const VariableElement: FC<VariableElementProps> = ({varNumber, varKey, varValue}) => (
  <FlexboxRow
    sx={{
      pt: 'x7',
      pr: 'x8',
      pb: 'x7',
      pl: 'x8',
      borderRadius: 'x2',
      gap: '24px',
      height: '48px',
      backgroundColor: 'grey.5',
      alignItems: 'center',
    }}
  >
    <Typography style={{width: '22px'}}>{varNumber}.</Typography>
    <Typography style={{color: colors.grey[100]}} variant="h4">
      {varKey}:
    </Typography>
    <Typography>{varValue}</Typography>
  </FlexboxRow>
)

type VariablesListProps = {
  variables: {key?: string; value?: string}[]
}

const VariablesList: FC<VariablesListProps> = ({variables}) => (
  <FlexboxColumn sx={{gap: '16px'}}>
    {variables.map((variable, variableIndex) => (
      <VariableElement
        key={variableIndex}
        varKey={variable.key}
        varNumber={variableIndex + 1}
        varValue={variable.value}
      />
    ))}
  </FlexboxColumn>
)

export default VariablesList
