function writeSerialized<T>(key: string, value: T) {
  if (value == null) {
    console.warn(`Value was undefined for key: ${key}. Skipped.`)
    return
  }

  return localStorage.setItem(key, JSON.stringify(value))
}

function getDeserialized<T>(key: string): T | undefined | null {
  const value = localStorage.getItem(key)

  if (value === 'undefined') {
    return undefined
  } else if (value === 'null') {
    return null
  }

  try {
    return JSON.parse(value) as T
  } catch (e) {
    console.error('Unable to parse JSON value', e)

    localStorage.removeItem(key)

    return undefined
  }
}

const PREFIX = 'token-store'
const ID_TOKEN_KEY = `${PREFIX}:id-token`
const ACCESS_TOKEN_KEY = `${PREFIX}:access-token`
const REFRESH_TOKEN_KEY = `${PREFIX}:refresh-token`
const REFRESH_LOCKED_KEY = `${PREFIX}:refresh-locked`
const LOCK_TIMESTAMP = `${PREFIX}:lock-timestamp`

export type BaseStoreEvent = {
  custom: true
  publisherId: string
}
export type TokenPairRefreshedEvent = BaseStoreEvent & {
  type: 'token-pair-refreshed'
  payload: {
    idToken: string
    accessToken: string
    refreshToken: string
  }
}

export type SyncEvents = TokenPairRefreshedEvent

export function getLocked() {
  return getDeserialized<boolean>(REFRESH_LOCKED_KEY)
}

export function setLock(locked: boolean) {
  writeSerialized(REFRESH_LOCKED_KEY, locked)
  if (locked) {
    writeSerialized(LOCK_TIMESTAMP, Date.now().valueOf())
  } else {
    localStorage.removeItem(LOCK_TIMESTAMP)
  }
}

export function getLockTimestamp() {
  return getDeserialized<number>(LOCK_TIMESTAMP)
}

export function getRefreshToken() {
  return getDeserialized<string>(REFRESH_TOKEN_KEY)
}

export function getIdToken() {
  return getDeserialized<string>(ID_TOKEN_KEY)
}

export function setIdToken(idToken: string) {
  return writeSerialized(ID_TOKEN_KEY, idToken)
}

export function setRefreshToken(token: string) {
  writeSerialized(REFRESH_TOKEN_KEY, token)
}

export function getAccessToken() {
  return getDeserialized<string>(ACCESS_TOKEN_KEY)
}

export function setAccessToken(token: string) {
  writeSerialized(ACCESS_TOKEN_KEY, token)
}

export function clearKeycloakStorage() {
  localStorage.removeItem(ID_TOKEN_KEY)
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)

  localStorage.removeItem(REFRESH_LOCKED_KEY)
  localStorage.removeItem(LOCK_TIMESTAMP)
}

const LOCK_MAX_LIFETIME_MS = 2000

export function isLockExpired() {
  const timestamp = getLockTimestamp()

  if (timestamp == null) {
    return true
  }

  console.log(timestamp)

  return Date.now().valueOf() - timestamp > LOCK_MAX_LIFETIME_MS
}

export function tokenPairRefreshedAction(
  publisherId: string,
  idToken: string,
  accessToken: string,
  refreshToken: string
): TokenPairRefreshedEvent {
  return {
    custom: true,
    type: 'token-pair-refreshed',
    publisherId,
    payload: {
      idToken,
      accessToken,
      refreshToken,
    },
  }
}

export function isTokenStoreEvent(event: unknown): event is SyncEvents {
  return (event as SyncEvents).custom === true
}
