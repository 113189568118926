import {AppTableState} from '@root/components/appTable'
import {LaunchInfo, LaunchTabKind} from './types'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {components, FilterItemType, FilterWithSortPagedRequestSort} from '@root/openapi'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'

type FilterItem = components['schemas']['FilterItem']

function getExpressionForTab(currentTab: LaunchTabKind): GetLaunchesPayload['filter']['filters'] {
  switch (currentTab) {
    case LaunchTabKind.all:
      return {}
    case LaunchTabKind.withReport:
      return {
        report: {
          value: null as any,
          type: FilterItemType.IS_NOT_NULL,
        },
      }
    case LaunchTabKind.withoutReport:
      return {
        report: {
          value: null as any,
          type: FilterItemType.IS_NULL,
        },
      }
  }
}

function sortValueMapper(
  tableFormat: ExtractConstValues<typeof sortDirections>
): FilterWithSortPagedRequestSort {
  switch (tableFormat) {
    case 'asc':
      return FilterWithSortPagedRequestSort.ASC
    case 'desc':
      return FilterWithSortPagedRequestSort.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof LaunchInfo): string {
  switch (field) {
    case 'variablesTemplateName':
      return 'template.name'
    case 'id':
      return 'pipelineId'
  }

  return field
}

// why not sortNameMapper? because we sort by different entity properties
function getExpressionForField(field: FilterCollectorFilter): [string, FilterItem] {
  const fieldName = field.name as keyof LaunchInfo
  const value = field.value as any

  switch (fieldName) {
    case 'id':
      return ['pipelineId', {value, type: FilterItemType.LIKE}]
    case 'creator':
      return ['creator.id', {value, type: FilterItemType.EQUAL}]
    case 'taskNumber':
      return ['task.number', {value, type: FilterItemType.LIKE}]
    case 'variablesTemplateName':
      return ['template.id', {value, type: FilterItemType.EQUAL}]
    case 'status':
      return ['status', {value, type: FilterItemType.EQUAL}]
    case 'createdAt':
      return ['createdAt', {value, type: FilterItemType.EQUAL}]
    case 'finishedAt':
      return ['finishedAt', {value, type: FilterItemType.EQUAL}]
    default:
      throw new Error('Out of range')
  }
}

export function fieldMapper(
  tableState: AppTableState<LaunchInfo, LaunchTabKind>
): GetLaunchesPayload['filter'] {
  const tabExpression = getExpressionForTab(tableState.currentTab)

  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, expression]: [string, FilterItem]) => !isEmpty(expression.value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  const sortExpressions: GetLaunchesPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  return {
    filters: {
      ...filterExpressions,
      ...tabExpression,
    },
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }
}
