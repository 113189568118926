import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

type Params = {
  redirectUri: string
  hasAccess: boolean
}

export const useAuthGuard = ({hasAccess, redirectUri}: Params) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirectUri)
      console.warn('had no access, redirected')
    }
  }, [navigate, hasAccess, redirectUri])
}
