import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import {BACKEND_BASE_URL} from '@root/constants'
import {keycloakClient} from '@root/hooks/useKeycloak'
import {setOnAuthRedirect} from 'routes/utils'
import type {BaseQueryFn} from '@reduxjs/toolkit/src/query/baseQueryTypes'

const redirectLoginOn401Query: (prefix?: string) => BaseQueryFn =
  (apiPrefix = '/v1') =>
  async (args, api, extraOptions) => {
    let baseUrl = BACKEND_BASE_URL
    if (apiPrefix) baseUrl += apiPrefix
    const baseQuery = fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers) => {
        const jwtToken = keycloakClient.token
        if (jwtToken) {
          headers.set('Authorization', `Bearer ${jwtToken}`)
        }
        return headers
      },
    })

    const result = await baseQuery(args, api, extraOptions)

    if (result.error?.status === 401) {
      setOnAuthRedirect()
      keycloakClient.login()
      return result
    }

    return result
  }

export default redirectLoginOn401Query
