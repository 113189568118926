import axios from 'axios'
import {BACKEND_BASE_URL} from '@root/constants'
import {store} from '@root/redux/store'
import {tokenSelector} from '@root/redux/selectors'

export const axiosClient = axios.create({
  baseURL: `${BACKEND_BASE_URL}/v1`,
})

axiosClient.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${tokenSelector(store.getState())}`
  return config
})
