import {FC} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'
import {TaskStatusSelect} from '@root/features/tasks/components/TaskStatusSelect'

const FilterCollectorStatusCombobox: FC<CustomFilterInputProps> = ({onChange, value, size}) => {
  return (
    <TaskStatusSelect
      multiple
      stretch
      usePortal
      size={size}
      value={value}
      width="100%"
      onChange={onChange}
    />
  )
}

export default FilterCollectorStatusCombobox
