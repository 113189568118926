import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'

import {TaskFormData} from '@root/features/tasks/types'

type GetTaskFormDataPayload = {taskId: string}

const tags = ['TaskFormData']

export const formsApi = createApi({
  reducerPath: 'formsApi',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getTaskFormMeta: build.query({
      query: ({taskId}) => `/tasks/${taskId}/form/meta`,
    }),
    getTaskFormData: build.query<TaskFormData, GetTaskFormDataPayload>({
      query: ({taskId}) => `/tasks/${taskId}/form`,
      transformResponse: (response: any) => {
        return response?.data ? response.data : []
      },
      providesTags: tags,
    }),
    getTaskFormFile: build.query({
      query: ({taskId, type, nameInStore}) => {
        return {
          url: `/tasks/${taskId}/form/files/${type}/${nameInStore}`,
          responseHandler: async (response) => await response.blob(),
        }
      },
      // transformResponse: (response, meta, arg) => {
      //   return response?.data ? response.data : []
      // },

      providesTags: tags,
    }),
    updateTaskFormData: build.mutation({
      query: ({taskId, updateData}) => ({
        url: `/tasks/${taskId}/form`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),
    updateTaskFormDataWithFiles: build.mutation({
      query: ({taskId, formData}) => ({
        url: `/tasks/${taskId}/form`,
        method: 'POST',
        body: formData,
      }),
      // invalidatesTags: tags
    }),
    pushTaskFormDataFiles: build.mutation({
      query: ({taskId, formData}) => ({
        url: `/tasks/${taskId}/form/files`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: tags,
    }),
    deleteTaskFormDataFiles: build.mutation({
      query: ({taskId, files}) => ({
        url: `/tasks/${taskId}/form/files?fileIds=${files.join(',')}`,
        method: 'DELETE',
      }),
      invalidatesTags: tags,
    }),
  }),
})

export const invalidateFormDataAction = formsApi.util.invalidateTags(tags)

export const {
  useGetTaskFormMetaQuery,
  useGetTaskFormDataQuery,
  useGetTaskFormFileQuery,
  useUpdateTaskFormDataMutation,
  useUpdateTaskFormDataWithFilesMutation,
  usePushTaskFormDataFilesMutation,
  useDeleteTaskFormDataFilesMutation,
} = formsApi
