import {useComparativeReportContext} from '../context'

import {ComponentProps, FC, PropsWithChildren, ReactNode} from 'react'
import {Typography} from 'ui-kit'

import {isEmpty, isString} from 'lodash'
import TextUrlEnhancer from '@root/components/TextUrlEnhancer'

type Props = PropsWithChildren<{
  label?: string
  value: ReactNode | string
  typography?: ComponentProps<typeof Typography>
}>

export const EditableField: FC<Props> = ({label, value, children, typography}) => {
  const {isEdit} = useComparativeReportContext()

  if (isEdit) {
    return <>{children}</>
  }

  const content = isString(value) ? (
    <TextUrlEnhancer text={`${label ? label + ' ' : ''} ${isEmpty(value) ? '-' : value}`} />
  ) : (
    value
  )

  return (
    <Typography style={{whiteSpace: 'pre-wrap'}}>
      <Typography {...typography}>{content}</Typography>
    </Typography>
  )
}
