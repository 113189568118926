import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'
import {initStepFormData} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {useLockReportMutation, useUnlockReportMutation} from '@root/redux/api/report/reportsApi'
import {Typography} from '@x5-react-uikit/core'
import {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import DataStep from './components/DataStep'
import ReportUpdateConf from './components/ReportUpdateConf'
import ReviewStep from './components/ReviewStep'
import StatisticsStep from './components/StatisticsStep'
import StepsSection from './components/StepsSection'
import {CommonFieldsOrder} from './constants'
import {useSaveReportBeforeTeardown} from './hooks/useSaveReportBeforeTeardown'
import {useEditReportGuard} from '@root/features/reports/hooks/useEditReportGuard'
import useGetReportGuard from '@root/features/reports/hooks/useGetReportGuard'
import {components} from '@root/openapi'
import {UpdateReportContextProvider} from './context'
import {useLockReport} from '@root/features/reports/hooks/useLockReport'

type FormValues = components['schemas']['ReportResponse'] | {loading: boolean}

const ReportUpdatePage = () => {
  const {reportId} = useParams()

  const {report, isChecking: isReportLoading} = useGetReportGuard()
  useEditReportGuard(report)

  const [step, setStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([1])
  const [touchedSteps, setTouchedSteps] = useState([1])
  const [discardChanges, setDiscardChanges] = useState(false)
  const [sendLockMut] = useLockReportMutation()
  const [sendUnlockMut] = useUnlockReportMutation()
  const navigate = useNavigate()
  const sendLock = useCallback((id: string) => sendLockMut(id).unwrap(), [sendLockMut])
  const sendUnlock = useCallback((id: string) => sendUnlockMut(id).unwrap(), [sendUnlockMut])

  useLockReport({
    fallbackUrl: '/reports',
    sendLock,
    sendUnlock,
    report,
  })

  const [changeConfShown, setChangeConfShown] = useState(false)
  const [updated, setUpdated] = useState(false)

  const {
    reset: formReset,
    trigger: formTrigger,
    ...form
  } = useForm<FormValues>({defaultValues: {loading: true}})

  useEffect(() => {
    if (report) {
      const sortedReport = structuredClone(report)
      sortedReport.reportData.commonFields = CommonFieldsOrder.reduce((sorted, fieldMeta) => {
        let field = sortedReport.reportData.commonFields.find((f) => f.id === fieldMeta.id)
        if (!field) field = initStepFormData({[fieldMeta.id]: fieldMeta}).fields[0]
        sorted.push(field)
        return sorted
      }, [])
      // formReset({...sortedReport, systemId: report.system.systemId})
      formReset({...sortedReport})
    }
  }, [report, formReset])

  useSaveReportBeforeTeardown({
    updated,
    discardChanges,
    reportId,
    getValues: form.getValues,
    isDirty: form.formState.isDirty,
  })

  useEffect(() => {
    if (!discardChanges) {
      return
    }

    navigate('/reports')
  }, [discardChanges, navigate])

  useEffect(() => {
    if (form.formState.isDirty) {
      setUpdated(false)
    }
  }, [form.formState.isDirty])

  if (changeConfShown)
    return <ReportUpdateConf form={form} onCancel={() => setChangeConfShown(false)} />

  if (isReportLoading || form.getValues('loading')) return <LoaderFrame />

  let currentStepComponent
  switch (Number(step)) {
    case 1:
      currentStepComponent = <DataStep form={form} />
      break
    case 2:
      currentStepComponent = <StatisticsStep form={form} />
      break
    case 3:
      currentStepComponent = <ReviewStep form={form} />
      break
    default:
      currentStepComponent = <DataStep form={form} />
  }

  return (
    <UpdateReportContextProvider value={{setUpdated}}>
      <div style={{margin: '0 16px 0 16px', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports', label: 'Отчеты портала'},
            {to: null, label: 'Формирование отчета'},
          ]}
        />
        <Typography variant="h2">Редактирование отчета нагрузочного тестирования</Typography>
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setChangeConfShown={setChangeConfShown}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          setDiscardChanges={setDiscardChanges}
          touchedSteps={touchedSteps}
        />
        {currentStepComponent}
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          position="bottom"
          setChangeConfShown={setChangeConfShown}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          setDiscardChanges={setDiscardChanges}
          setTouchedSteps={setTouchedSteps as any}
          touchedSteps={touchedSteps}
        />
      </div>
    </UpdateReportContextProvider>
  )
}

export default ReportUpdatePage
