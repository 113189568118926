import Divider from '@root/components/Divider'
import {Button} from '@root/components/kit'
import {FILES_REGISTER, GRAPHS_REGISTER} from '@root/features/reports/constants'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import Section from '@root/components/Section'
import GraphDataShowField from './GraphDataShowField'
import StatisticsFileField from './StatisticsFileField'
import {Fragment} from 'react'

const StatisticsStep = ({form}) => {
  form.watch('statistics')

  const addImageFile = () => {
    const files = form.getValues(FILES_REGISTER) || []
    const newFile = {
      name: '',
      comment: '',
    }
    form.setValue(FILES_REGISTER, [...files, newFile])
  }

  const removeStatisticGraph = (graphIndex) => {
    const graphs = form.getValues(GRAPHS_REGISTER)
    // WARNING: первый вызов setValue здесь для добавления statistics.graphs в dirtyFields
    form.setValue(`${GRAPHS_REGISTER}.${graphIndex}.name`, '', {shouldDirty: true})
    graphs.splice(graphIndex, 1)
    form.setValue(GRAPHS_REGISTER, graphs, {shouldDirty: true})
  }

  const removeStatisticFile = (fileFieldIndex) => {
    const fileFields = form.getValues(FILES_REGISTER)
    // WARNING: первый вызов setValue здесь для добавления statistics.files в dirtyFields
    form.setValue(`${FILES_REGISTER}.${fileFieldIndex}.name`, '', {shouldDirty: true})
    fileFields.splice(fileFieldIndex, 1)
    form.setValue(FILES_REGISTER, fileFields, {shouldDirty: true})
  }

  return (
    <Section sx={{display: 'flex', flexDirection: 'column', minHeight: '100px'}}>
      {form.getValues(GRAPHS_REGISTER)?.length > 0 && (
        <FlexboxColumn sx={{gap: '24px', mb: 'x12'}}>
          {form.getValues(GRAPHS_REGISTER).map((_graph, index) => {
            const last = index === form.getValues(GRAPHS_REGISTER).length - 1
            return (
              <Fragment key={index}>
                <GraphDataShowField
                  form={form}
                  index={index}
                  onRemove={() => removeStatisticGraph(index)}
                />
                {!last && <Divider sx={{my: 'x8'}} />}
              </Fragment>
            )
          })}
          {form.getValues(FILES_REGISTER)?.length > 0 && <Divider sx={{my: 'x8'}} />}
          {form.getValues(FILES_REGISTER)?.map((_fileItem, index) => {
            const last = index === form.getValues(FILES_REGISTER).length - 1
            return (
              <Fragment key={index}>
                <StatisticsFileField
                  form={form}
                  index={index}
                  onRemove={() => removeStatisticFile(index)}
                />
                {!last && <Divider sx={{my: 'x8'}} />}
              </Fragment>
            )
          })}
        </FlexboxColumn>
      )}
      <Button
        startIcon={<AddIcon size="small" />}
        style={{width: 'fit-content'}}
        variant="text"
        onClick={addImageFile}
      >
        Добавить изображение
      </Button>
    </Section>
  )
}

export default StatisticsStep
