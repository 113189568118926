import {CONTENT_TYPE_JSON} from '@root/constants'
import {UpdateReportRequest} from '@root/features/reports/types'
import {useUpdateReportMutation} from '@root/redux/api/report/reportsApi'
import {useCallback} from 'react'
import {DeepPartial} from 'react-hook-form'
import {pick} from 'lodash'

type FormFileField = {
  name?: string
  comment?: string
  files?: {name: string; file?: File; shouldRemoved?: boolean; nameInStore?: string}[]
}

type AllowedRequestFields = keyof UpdateReportRequest

const allowedRequestFields: AllowedRequestFields[] = [
  'name',
  'statistics',
  'deletedFiles',
  'reportData',
]

const getRemovedFilesMeta = (fileFields: FormFileField[]) => {
  const toDelete = []

  fileFields?.forEach((fileField) => {
    const deleted = fileField.files?.filter((fileItem) => fileItem.shouldRemoved)
    toDelete.push(...deleted.map((fileItem) => fileItem.nameInStore))
  })
  return toDelete
}

const useUpdateReport = () => {
  const [updateReport, {isLoading: isUpdating}] = useUpdateReportMutation()

  const update = useCallback(
    (updateData: DeepPartial<UpdateReportRequest>, reportId: string) => {
      const formData = new FormData()

      if (updateData.statistics) {
        // Добавляем в FormData.files новые файл-объекты
        updateData.statistics.fileFields?.forEach((fileField: FormFileField) => {
          fileField.files
            ?.filter((fileItem) => fileItem.file)
            .forEach((fileItem) => {
              formData.append('files', fileItem.file, fileItem.name)
              delete fileItem.file
            })
        })

        // Добавляем в FormData.deletedFiles список названий удалённых файлов
        const filesNamesToRemove = getRemovedFilesMeta(
          updateData.statistics.fileFields as FormFileField[]
        )
        if (filesNamesToRemove.length > 0) {
          updateData.deletedFiles = filesNamesToRemove
        }
      }

      const requestData = pick(updateData, allowedRequestFields)

      const updateDataBlob = new Blob([JSON.stringify(requestData)], CONTENT_TYPE_JSON)
      formData.append('request', updateDataBlob)

      return updateReport({reportId, formData}).unwrap()
    },
    [updateReport]
  )
  return {update, isUpdating}
}

export default useUpdateReport
