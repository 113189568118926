import React, {
  FC,
  forwardRef,
  cloneElement,
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
  MouseEvent as ReactMouseEvent,
} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import * as Icons from '@x5-react-uikit/icons'
import {StyledTab, StyledTabs, StyledTabList, StyledTabPanel, tabsClasses} from './styles'
import {TabsContext} from './context'
import {TabsProps, TabProps, TabListProps, TabPanelProps, TabValue} from './types'
import {getPanelId, getTabId} from './helpers'
import {getQaAttribute} from '../utils'

export const Tab: FC<TabProps> = ({
  qa = 'tab',
  label,
  value,
  disabled,
  icon,
  IconComponent,
  counter,
  tabRef,
  onChange,
  _order,
  _onIndicatorChange,
}) => {
  const context = useContext(TabsContext)
  const buttonRef = useRef()
  const getQA = getQaAttribute(qa)
  const selected = context ? context.value === value : _order === 0
  const IconRender = useMemo(() => {
    const Icon = Icons[icon]
    return IconComponent ? IconComponent : icon ? <Icon size={sizeTokenValues.small} /> : null
  }, [IconComponent, icon])

  useEffect(() => {
    if (selected) {
      _onIndicatorChange(buttonRef.current)
    }
  }, [])

  const handleClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) event.preventDefault()
    const {
      currentTarget,
      currentTarget: {
        dataset: {id},
      },
    } = event
    context?.onChange?.(event, Number.isInteger(value) ? (Number(id) as number) : (id as string))
    _onIndicatorChange(currentTarget)
    onChange?.(selected)
  }

  return (
    <StyledTab
      ref={tabRef || buttonRef}
      aria-controls={context ? getPanelId(value) : null}
      aria-selected={selected}
      data-id={String(value)}
      data-index={_order}
      data-qa={getQA('', [selected && 'selected', disabled && 'disabled'])}
      disabled={disabled}
      id={getTabId(value)}
      role="tab"
      selected={selected}
      tabIndex={0}
      onClick={handleClick}
    >
      {IconRender}
      {label}
      {Number(counter) >= 0 && (
        <span className={tabsClasses.counter} data-qa={getQA('counter')}>
          {counter}
        </span>
      )}
    </StyledTab>
  )
}

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
  ({qa = 'tab-panel', value, index, className, children}, ref) => {
    const context = useContext(TabsContext)
    const getQA = getQaAttribute(qa)
    const selected = value ? context.value === value : Number(context.value) === index

    return (
      <StyledTabPanel
        ref={ref}
        aria-labelledby={getTabId(value ?? index)}
        className={className}
        data-qa={getQA('')}
        id={getPanelId(value ?? index)}
        role="tabpanel"
        selected={selected}
      >
        {children}
      </StyledTabPanel>
    )
  }
)

export const TabList = forwardRef<HTMLDivElement, TabListProps>(
  ({qa = 'tab-list', style, holderStyles, children}, ref) => {
    const [indicator, setIndicator] = useState({stripeWidth: 0, left: 0, disabled: false})
    const handleIndicatorChange = ({
      offsetWidth: stripeWidth,
      offsetLeft: left,
      disabled,
    }: Partial<HTMLButtonElement>) => setIndicator({stripeWidth, left, disabled})

    return (
      <StyledTabList ref={ref} {...indicator} style={style}>
        <div className={tabsClasses.holder} data-qa={qa} role="tablist" style={holderStyles}>
          {React.Children.toArray(children)
            .filter(Boolean)
            .map((child: JSX.Element, index) => {
              const childProps = {
                value: index,
                _onIndicatorChange: handleIndicatorChange,
                ...child.props,
              }
              if (child.props.value === undefined) {
                childProps._order = index
              }
              return cloneElement(child, childProps)
            })}
        </div>
      </StyledTabList>
    )
  }
)

export const Tabs: FC<TabsProps> = ({qa = 'tabs', children, onChange, style, ...props}) => {
  const [value, setValue] = useState(props.value ?? 0)

  const handleChange = (event: ReactMouseEvent, newValue: TabValue) => {
    onChange?.(event, newValue)
    if ([null, undefined].includes(props.value) && onChange) {
      setValue(props.value)
    } else {
      setValue(newValue)
    }
  }

  return (
    <TabsContext.Provider value={{onChange: handleChange, ...props, value}}>
      <StyledTabs data-qa={qa} style={style}>
        {children}
      </StyledTabs>
    </TabsContext.Provider>
  )
}

export default Tabs
