import {AppTableState} from '@root/components/appTable'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {components, FilterItemType, FilterWithSortPagedRequestSort} from '@root/openapi'
import {GetTemplatesListPayload} from '@root/redux/api/templatesApi'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {Template, TemplatesTabKind} from '../../types'

type FilterItem = components['schemas']['FilterItem']

function getExpressionForTab(
  currentTab: TemplatesTabKind,
  userEmail: string
): GetTemplatesListPayload['filter']['filters'] {
  switch (currentTab) {
    case TemplatesTabKind.all:
      return {}
    case TemplatesTabKind.mine:
      return {
        'creator.email': {
          value: userEmail as any,
          type: FilterItemType.LIKE,
        },
      }
    default:
      throw new Error('Out of range')
  }
}

function sortValueMapper(
  tableFormat: ExtractConstValues<typeof sortDirections>
): FilterWithSortPagedRequestSort {
  switch (tableFormat) {
    case 'asc':
      return FilterWithSortPagedRequestSort.ASC
    case 'desc':
      return FilterWithSortPagedRequestSort.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof Template): string {
  // switch(field) {
  //   case "variablesTemplateName":
  //     return "template.name"
  // }

  return field
}

// why not sortNameMapper? because we sort by different entity properties
function getExpressionForField(field: FilterCollectorFilter): [string, FilterItem] {
  const fieldName = field.name as keyof Template
  const value = field.value as any

  switch (fieldName) {
    case 'name':
      return ['name', {value, type: FilterItemType.LIKE}]
    case 'creator':
      return ['creator.id', {value, type: FilterItemType.EQUAL}]
    case 'createdDate':
      return ['createdDate', {value, type: FilterItemType.EQUAL}]
    default:
      throw new Error('Out of range')
  }
}

export function fieldMapper(
  tableState: AppTableState<Template, TemplatesTabKind>,
  userEmail: string
): GetTemplatesListPayload['filter'] {
  const tabExpression = getExpressionForTab(tableState.currentTab, userEmail)

  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, expression]: [string, FilterItem]) => !isEmpty(expression.value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  const sortExpressions: GetTemplatesListPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  return {
    filters: {
      ...filterExpressions,
      ...tabExpression,
    },
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }
}
