import {FC} from 'react'
import {Modal, Typography} from 'ui-kit'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onCancel: () => void
  isSubmitting?: boolean
}

const SubmitTaskModal: FC<Props> = ({isOpen, onSubmit, isSubmitting, onCancel}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onCancel,
          side: 'right',
          text: 'Отмена',
        },
        {
          onClick: onSubmit,
          side: 'right',
          text: 'Отправить',
          props: {
            loading: isSubmitting,
            disabled: isSubmitting,
          },
        },
      ]}
      isOpen={isOpen}
      size="medium"
      title="Отправить заявку на рассмотрение?"
      onClose={onCancel}
    >
      <Typography variant="p1">
        Заявка отправится к менеджеру нагрузочного тестирования на рассмотрение. Вы сможете отозвать
        заявку, чтобы внести изменения, до изменения статуса заявки на “В работе”.
      </Typography>
    </Modal>
  )
}

export default SubmitTaskModal
