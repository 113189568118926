import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {tagsArray} from './tags'

export const emptyApi = createApi({
  reducerPath: 'api',
  baseQuery: redirectLoginOn401Query(),
  tagTypes: tagsArray,
  endpoints: () => ({}),
})
