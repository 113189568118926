import {Button} from '@x5-react-uikit/core'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import Section from '@root/components/Section'
import GraphDataField, {GRAPHS_REGISTER} from './EditGraphDataField'
import {useEffect} from 'react'

const StatisticsStep = ({form}) => {
  useEffect(() => {
    if (!form.getValues(GRAPHS_REGISTER)?.length) {
      form.setValue(GRAPHS_REGISTER, [
        {
          graphName: undefined,
          graphUrl: undefined,
          comment: undefined,
        },
      ])
    }
  }, [form])

  const addStatistic = () => {
    const graphs = form.getValues(GRAPHS_REGISTER) || []
    const newGraph = {
      graphName: undefined,
      graphUrl: undefined,
      comment: undefined,
    }
    form.setValue(GRAPHS_REGISTER, [...graphs, newGraph])
  }

  const removeStatistic = (graphIndex) => {
    const graphs = form.getValues(GRAPHS_REGISTER)
    graphs.splice(graphIndex, 1)
    form.setValue(GRAPHS_REGISTER, graphs)
  }

  return (
    <Section sx={{display: 'flex', flexDirection: 'column', minHeight: '100px'}}>
      {form.getValues(GRAPHS_REGISTER)?.length > 0 && (
        <FlexboxColumn sx={{gap: '24px', mb: 'x12'}}>
          {form.getValues(GRAPHS_REGISTER).map((graph, index) => (
            <GraphDataField
              key={index}
              form={form}
              index={index}
              onRemove={
                form.getValues(GRAPHS_REGISTER).length > 1 && (() => removeStatistic(index))
              }
            />
          ))}
        </FlexboxColumn>
      )}
      <Button
        startIcon={<AddIcon size="small" />}
        style={{width: 'fit-content'}}
        variant="text"
        onClick={addStatistic}
      >
        Добавить график
      </Button>
    </Section>
  )
}

export default StatisticsStep
