import {useSelector} from 'react-redux'
import {userSelector} from '@root/redux/selectors'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {useMemo} from 'react'
import {canEditReport} from '../canEditReport'
import type {Report, ComparativeReport} from '../types'

export function useCanEditReport(
  report?: Report | ComparativeReport,
  canEditWhenNotInitialized: boolean = true
) {
  const currentUser = useSelector(userSelector)
  const {data: userInfo} = useGetUserinfoQuery()

  return useMemo(() => {
    if (currentUser == null) {
      console.error('Current user was null')
      return false
    }
    if (report == null || userInfo == null) {
      return canEditWhenNotInitialized
    }

    return canEditReport(userInfo, currentUser, report)
  }, [report, userInfo, currentUser, canEditWhenNotInitialized])
}
