import Divider from '@root/components/Divider'
import SectionInitiator from '@root/pages/tasks/CreateTaskPage/components/SectionInitiator'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {Loader} from '@x5-react-uikit/core'
import {useParams} from 'react-router-dom'
import {hasWritePerm} from '../fieldPermMapper'
import useTaskPageForm, {getStepMeta} from '../hooks/useTaskPageForm'
import BudgetFieldAccordeon from './BudgetFieldAccordeon'
import FieldAccordeon from './FieldAccordeon'
import SystemField from './SystemsField'
import {Fragment, useMemo} from 'react'
import {useSaveFormBeforeTeardown} from '../hooks/useSaveFormBeforeTeardown'
import {isEmpty} from 'lodash'

const STEP_NUMBER = 1

const CommonParamsSection = () => {
  const {taskId} = useParams()
  const {data: task} = useGetTaskByIdQuery(taskId)

  const {data: userInfo} = useGetUserinfoQuery()

  const {form, formMeta, isFormDataLoading, stepIndices} = useTaskPageForm()

  const stepMeta = useMemo(() => getStepMeta(formMeta, STEP_NUMBER), [formMeta])
  useSaveFormBeforeTeardown({
    stepId: STEP_NUMBER,
    systemId: task?.system.id,
    taskId,
    updated: !form.formState.dirtyFields || isEmpty(form.formState.dirtyFields),
    currentStepMeta: stepMeta,
    form,
    shouldCheckCompleted: false,
  })

  if (isFormDataLoading) return <Loader />

  const formValue = form.getValues('formData')
  const stepIndex = stepIndices[STEP_NUMBER]

  return (
    <>
      <FieldAccordeon readonly title="Заказчик">
        <SectionInitiator />
      </FieldAccordeon>
      <Divider />
      <SystemField form={form} />
      <Divider />
      <BudgetFieldAccordeon
        form={form}
        readonly={!hasWritePerm(userInfo.role, 'budget_source', task)}
      />
      <Divider />
      {['aims', 'deadlines', 'stakeholders'].map((fieldId, index) => {
        const last = index === 3
        const fieldIdIndex = formValue[stepIndex].fields.findIndex((field) => field.id === fieldId)
        const field = stepMeta[fieldId]
        return (
          <Fragment key={index}>
            <FieldAccordeon
              fieldIndex={fieldIdIndex}
              fieldMeta={field}
              form={form}
              readonly={!hasWritePerm(userInfo.role, field, task)}
              stepIndex={stepIndex}
              title={field.label}
            />
            {!last && <Divider />}
          </Fragment>
        )
      })}
    </>
  )
}

export default CommonParamsSection
