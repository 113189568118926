import {Link, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/report/reportsApi'
import {formattedDate} from 'utils'

const ReportDateAndTasks = () => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  const handleOpenKaiten = () => {
    window.open(report.kaitenCardLink, '_blank')
  }

  return (
    <Section sx={{my: 0, width: '588px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Даты и задачи</Typography>
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxRow sx={{gap: '64px'}}>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography>Завершение тестирования</Typography>
              <Typography>
                {report?.testFinishedAt ? formattedDate(report.testFinishedAt) : '-'}
              </Typography>
            </FlexboxColumn>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography>Дата формирования отчета</Typography>
              <Typography>{report?.createdAt ? formattedDate(report.createdAt) : '-'}</Typography>
            </FlexboxColumn>
          </FlexboxRow>
          <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
            <Typography>Задачи в Kaiten</Typography>
            {report?.kaitenCardLink ? (
              <Link style={{width: 'fit-content'}} onClick={handleOpenKaiten}>
                Перейти к связанным задачам
              </Link>
            ) : (
              '-'
            )}
          </FlexboxColumn>
        </FlexboxColumn>
      </FlexboxColumn>
    </Section>
  )
}

export default ReportDateAndTasks
