import {useInView} from '@react-spring/web'
import FlexboxColumn from '@root/components/FlexboxColumn'
import LoaderFrame from '@root/components/Loader'
import {FileField} from '@root/features/reports/types'
import {useGetReportFileByNameQuery} from '@root/redux/api/report/reportsApi'
import {Typography} from '@x5-react-uikit/core'
import {useEffect, useState} from 'react'

const ReportImage = ({fileField}: {fileField: FileField}) => {
  const [imageRef, isImageInView] = useInView()
  const [blobUrl, setBlobUrl] = useState<string | null>(null)

  const {isFetching: isImageFetching, data: fetchedImageBlob} = useGetReportFileByNameQuery(
    fileField.files[0].nameInStore,
    {
      skip: !isImageInView || !fileField.files[0].nameInStore || (fileField.files[0] as any).file,
    }
  )

  useEffect(() => {
    const blob = (fetchedImageBlob || (fileField.files[0] as any).file) as Blob

    if (blob == null) {
      return
    }

    setBlobUrl(URL.createObjectURL(blob))
  }, [fetchedImageBlob, fileField.files])

  useEffect(() => {
    return () => {
      if (!blobUrl) {
        return
      }

      URL.revokeObjectURL(blobUrl)
    }
  }, [blobUrl])

  return (
    <div ref={imageRef}>
      <FlexboxColumn sx={{gap: '16px'}}>
        {!isImageInView || isImageFetching ? (
          <LoaderFrame backgroundColor="grey" height="485px" />
        ) : (
          <>
            <Typography variant="h3">{fileField.name}</Typography>
            {blobUrl && <img alt={fileField.name} src={blobUrl} />}
            <FlexboxColumn sx={{gap: '24px'}}>
              <Typography variant="p1">Комментарий:</Typography>
              <Typography variant="p1">{fileField.comment}</Typography>
            </FlexboxColumn>
          </>
        )}
      </FlexboxColumn>
    </div>
  )
}

export default ReportImage
