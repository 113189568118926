import {CONTENT_TYPE_JSON} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {TaskStatusTabKind} from '@root/pages/tasks/TaskListPage/types'
import {
  invalidateFormDataAction,
  useUpdateTaskFormDataWithFilesMutation,
} from '@root/redux/api/formsApi'
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getAddedFiles,
  getRemovedFilesMeta,
  isStepCompleted,
  prepareFilesMetaToPush,
} from '../utils/formUtils'

const useUpdateFormData = () => {
  const navigate = useNavigate()
  const {taskId, stepId} = useParams()
  const {notifySuccess, notifyError, closeSnackbar} = useNotify()
  const dispatch = useDispatch()

  const [update, {isLoading: isUpdating}] = useUpdateTaskFormDataWithFilesMutation()

  const updateFormData = useCallback(
    async ({
      steps,
      updatedFields,
      currentStepMeta,
      onSuccess,
      shouldNotify = true,
      currentStepId = stepId,
    }) => {
      let formData = new FormData()

      const currentStep = steps.find((step) => step.number === Number(currentStepId))
      if (currentStepMeta) currentStep.completed = isStepCompleted(currentStep, currentStepMeta)

      const filesToAdd = getAddedFiles(currentStep)
      filesToAdd.forEach((file) => {
        formData.append('files', file, file.name)
      })
      const filesMetaToRemove = getRemovedFilesMeta(currentStep)
      if (filesMetaToRemove.length > 0) {
        const deleteDataBlob = new Blob([JSON.stringify(filesMetaToRemove)], CONTENT_TYPE_JSON)
        formData.append('deletedFiles', deleteDataBlob)
      }
      prepareFilesMetaToPush(currentStep)

      const updateDataBlob = new Blob([JSON.stringify(Object.values(steps))], CONTENT_TYPE_JSON)
      formData.append('data', updateDataBlob)

      if (updatedFields?.length > 0) {
        const updatedFieldIds = new Blob([JSON.stringify(updatedFields)], CONTENT_TYPE_JSON)
        formData.append('updatedFieldsIds', updatedFieldIds)
      }

      console.log('Pushing formData: ', Object.values(steps))

      return update({taskId, formData})
        .unwrap()
        .then((result) => {
          dispatch(invalidateFormDataAction)
          // dispatch(invalidateTasksAction)
          if (onSuccess) {
            onSuccess()
          } else if (shouldNotify) {
            notifySuccess(
              'Заявка сохранена',
              'Вы можете увидеть ее в разделе заявок, во вкладке “Черновики”.',
              {
                text: 'Перейти в "Черновики"',
                onClick: () => {
                  navigate(`/tasks?topTab=${TaskStatusTabKind.draft}`)
                  closeSnackbar()
                },
              }
            )
          }
          return result
        })
        .catch((error) => notifyError(JSON.stringify(error)))
    },
    [taskId, stepId, update, notifyError, notifySuccess, navigate, closeSnackbar, dispatch]
  )

  return {updateFormData, isUpdating}
}

export default useUpdateFormData
