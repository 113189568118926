import {
  AppTable,
  AppTableTab,
  SortTextKind,
  useAppTable,
  type AppTableColumn,
} from '@root/components/appTable'
import {FC, useCallback, useMemo, useState} from 'react'
import ErrorMessage from '../stubs/ErrorMessage'

import Section from '@root/components/Section'
import {Button} from '@root/components/kit'
import {GetTemplatesListPayload, useGetTemplatesQuery} from '@root/redux/api/templatesApi'
import {formattedDate} from '@root/utils'
import {useSelector} from 'react-redux'
import {Template, TemplatesTabKind} from '../../types'
import FilterCollectorTemplateCreatorCombobox from './FilterCollectorTemplateCreatorCombobox'
import TemplateActionsButton from './TemplateActionsButton'
import TemplateCreateModal from './TemplateCreateModal'
import TemplateDeleteModal from './TemplateDeleteModal'
import TemplateUpdateModal from './TemplateUpdateModal'
import {fieldMapper} from './fieldMapper'
import {TableContextProvider} from './tableContext'
import TemplateViewModal from './TemplateViewModal'
import {useGetSystemByIdQuery} from '@root/redux/api/systemsApi'
import {EmptyTableStub} from '@root/components/stubs/EmptyTableStub'
import {isEmpty} from 'lodash'
import ErrorTableStub from '@root/components/stubs/ErrorTableStub'

const tabs: AppTableTab<TemplatesTabKind>[] = [
  {
    label: 'Все',
    value: TemplatesTabKind.all,
  },
  {
    label: 'Мои шаблоны',
    value: TemplatesTabKind.mine,
  },
]

const columns: AppTableColumn<Template>[] = [
  {
    dataIndex: 'name',
    title: 'Название шаблона',
    filterableOptions: {
      type: 'text',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabeticalCyrillic,
    },
    width: '41.25%', // (495 / 1200)
    bodyCellStyle: {
      paddingLeft: 8,
    },
    headCellStyle: {
      paddingLeft: 8,
    },
  },
  {
    key: 'varCount',
    title: 'Количество переменных',
    width: '15.83%', // (190 / 1200)
    renderCell: (_val, row: Template) => row.variables?.length,
    bodyCellStyle: {
      paddingLeft: 8,
    },
    headCellStyle: {
      paddingLeft: 8,
    },
  },
  {
    dataIndex: 'createdDate',
    title: 'Дата создания',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
    filterableOptions: {
      type: 'date',
    },
    width: '14.16%', // (170 / 1200)
    valueRenderType: 'dateTime',
    renderCell: (_val, row: Template) => (row.createdDate ? formattedDate(row.createdDate) : ''),
  },
  {
    dataIndex: 'creator',
    title: 'Автор',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorTemplateCreatorCombobox,
    },
    width: '25.8%', // (310 / 1200),
    renderCell: (_val, row: Template) => row.creator.email,
  },

  {
    key: 'actions',
    width: 40,
    bodyCellStyle: {
      padding: '8px',
      textAlign: 'center',
      verticalAlign: 'top',
    },
    preventRowClickHandler: true,
    renderCell: (_val, row) => <TemplateActionsButton template={row} />,
  },
]

function getId(row: Template) {
  if (!row.id) {
    throw new Error('Id was null!')
  }
  return row.id
}

const TemplatesTable: FC<{systemId: string}> = ({systemId}) => {
  const {tableState, tableProps} = useAppTable<Template, TemplatesTabKind>({
    pages: {
      currentPage: 1,
      pageSize: 20,
    },
    filterValues: [],
    sort: [
      {
        name: 'createdDate',
        value: 'desc',
      },
    ],
    currentTab: TemplatesTabKind.all,
  })

  const {email: userEmail} = useSelector((state: any) => state.auth.user)

  const {
    data: cachedTemplates,
    currentData: templates,
    isFetching: isTemplatesFetching,
    isError: isTemplatesError,
  } = useGetTemplatesQuery(
    {
      systemId,
      filter: fieldMapper(tableState, userEmail),
    } as GetTemplatesListPayload,
    {skip: !systemId}
  )

  const {data: system} = useGetSystemByIdQuery(systemId)

  const [createModalShown, setCreateModalShown] = useState(false)
  const [templateToView, setTemplateToView] = useState<Template | null>(null)
  const [templateToDelete, setTemplateToDelete] = useState<Template | null>()
  // const handleDeleteTemplate = (template) => setTemplateToDelete(template)
  const [templateToUpdate, setTemplateToUpdate] = useState<Template | null>()
  // const handleUpdateTemplate = (template) => setTemplateToUpdate(template);

  const onRowClick = useCallback((template: Template) => {
    setTemplateToView(template)
  }, [])

  const emptyStub = useMemo(() => {
    if (isEmpty(tableState.filterValues))
      return (
        <EmptyTableStub
          description="Когда будет создан шаблон, он появится в этой таблице и 
                     будет доступен для применения в настройках по ИС."
          message="Шаблоны по выбранной ИС пока отсутствуют."
        />
      )
    return (
      <ErrorTableStub
        description="Проверьте корректность введённых фильтров или задайте другие параметры для поиска."
        message="Ничего не найдено."
        sx={{height: '462px'}}
      />
    )
  }, [tableState])

  return (
    <TableContextProvider
      value={{
        setTemplateToDelete: setTemplateToDelete,
        setTemplateToEdit: setTemplateToUpdate,
      }}
    >
      <Section sx={{p: undefined}}>
        <AppTable<Template>
          noSideBorders
          cachedData={cachedTemplates?.items ?? []}
          columns={columns}
          components={{
            Row: {
              style: {cursor: 'pointer'},
            },
            FilterCollector: {
              size: 'large',
            },
            Cell: {
              style: {
                padding: '16px 12px',
              },
            },
            HeadCell: {
              style: {
                padding: '12px',
              },
            },
          }}
          customEmpty={emptyStub}
          customErrorStub={<ErrorMessage />}
          data={templates?.items ?? []}
          error={isTemplatesError}
          getId={getId}
          loading={isTemplatesFetching}
          pagination={
            cachedTemplates
              ? {
                  currentPage: tableState.pages?.currentPage ?? 1,
                  pageSize: tableState.pages?.pageSize ?? 20,
                  totalCount: cachedTemplates.totalElements,
                  totalPages: cachedTemplates.totalPages,
                }
              : undefined
          }
          renderHead={
            <Button variant="outlined" onClick={() => setCreateModalShown(true)}>
              Создать шаблон настроек
            </Button>
          }
          style={{
            width: '1200px',
            maxWidth: '1200px',
            minHeight: 400,
          }}
          tabs={{
            current: tableState.currentTab,
            list: tabs,
          }}
          onRowClick={onRowClick}
          {...tableProps}
        />

        <TemplateCreateModal
          open={createModalShown}
          systemId={systemId}
          onClose={() => setCreateModalShown(false)}
        />
        <TemplateDeleteModal
          systemId={systemId}
          template={templateToDelete}
          onClose={() => setTemplateToDelete(null)}
        />
        <TemplateViewModal
          system={system}
          template={templateToView}
          onClose={() => setTemplateToView(null)}
          onDelete={() => {
            setTemplateToDelete(templateToView)
            setTemplateToView(null)
          }}
          onEdit={() => {
            setTemplateToUpdate(templateToView)
            setTemplateToView(null)
          }}
        />
        <TemplateUpdateModal
          open={!!templateToUpdate}
          systemId={systemId}
          template={templateToUpdate}
          onClose={() => setTemplateToUpdate(null)}
        />
      </Section>
    </TableContextProvider>
  )
}

export default TemplatesTable
