import {Typography} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/report/reportsApi'
import ReportImage from './ReportImage'
import {Report} from '@root/features/reports/types'
import {FC, Fragment} from 'react'

type Props = {
  value?: Report['statistics']
}

const ReportStatisticsTab: FC<Props> = ({value}) => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  const statistics = value || report?.statistics

  return (
    <FlexboxColumn sx={{mt: 'x12', gap: '40px'}}>
      {statistics?.graphs?.map((stat, statIndex) => {
        const last = statIndex === statistics.graphs.length - 1
        return (
          <Fragment key={statIndex}>
            <FlexboxColumn sx={{gap: '16px'}}>
              <Typography variant="h3">{stat.graphName}</Typography>
              <iframe src={stat.graphUrl} style={{height: '400px'}} title={stat.graphName}></iframe>
              <FlexboxColumn sx={{gap: '24px'}}>
                <Typography variant="p1">Комментарий:</Typography>
                <Typography variant="p1">{stat.comment}</Typography>
              </FlexboxColumn>
            </FlexboxColumn>
            {!last && <Divider />}
          </Fragment>
        )
      })}
      {statistics?.fileFields?.map((fileField, fieldIndex) => {
        const last = fieldIndex === statistics.fileFields.length - 1
        return (
          <Fragment key={fieldIndex}>
            <ReportImage fileField={fileField} />
            {!last && <Divider />}
          </Fragment>
        )
      })}
    </FlexboxColumn>
  )
}

export default ReportStatisticsTab
