import {Box, Button, Typography} from '@root/components/kit'
import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import useNotify from '@root/hooks/useNotify'
import {useTeardownEffect} from '@root/hooks/useTeardownEffect'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetSystemFinishedTaskQuery, useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {
  CreateTaskResponse,
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
} from '@root/redux/api/tasksApi'
import CopyTaskModal from './components/CopyFinishedTaskModal'
import SystemsCombobox from './components/SystemsCombobox'
import {System} from '@root/features/systems/types'
import {axiosClient} from '@root/axiosClient'
import {Result, result} from '@root/core/result'
import {type TaskCreatorDetails, TaskErrorReason} from '@root/features/tasks/types'
import {isEmpty} from 'lodash'
import {isAxiosError} from 'axios'
import * as yup from 'yup'
import {match} from 'ts-pattern'
import {yupResolver} from '@hookform/resolvers/yup'
import {memoize} from '@root/utils'
import {isTaskError} from '@root/features/tasks/taskResult'

const SystemsFilter = {pageNumber: 1, pageSize: 9999999, filters: {}}
const hideDuration = 999999

type ValidationError =
  | {type: TaskErrorReason.SYSTEM_ALREADY_HAS_ACTIVE_TASK; activeTaskCreator: TaskCreatorDetails}
  | {type: 'unknown'; error: any}
type ValidationResult = Result<null, ValidationError>
type CreateTaskResult = Result<CreateTaskResponse, ValidationError>

const errors = {
  systemAlreadyHasActiveTask: (activeTaskCreator: TaskCreatorDetails): ValidationError => ({
    type: TaskErrorReason.SYSTEM_ALREADY_HAS_ACTIVE_TASK,
    activeTaskCreator,
  }),
  createUnknown: (error: any): ValidationError => ({type: 'unknown', error}),
}

async function validateTaskAsyncResult(systemId: string): Promise<ValidationResult> {
  try {
    await axiosClient.get('/tasks/validate', {params: {systemId}})

    return result.ok(null)
  } catch (err) {
    if (!isAxiosError(err)) {
      throw err
    }

    const {data} = err.response
    if (isTaskError(data)) {
      return result.error(errors.systemAlreadyHasActiveTask(data.activeTaskCreator))
    }

    return result.error(errors.createUnknown(err))
  }
}

const memoValidateTaskAsyncResult = memoize(validateTaskAsyncResult)

async function createTaskResult(
  system: System,
  createFn: (system: System) => Promise<CreateTaskResponse>
): Promise<CreateTaskResult> {
  try {
    const res = await createFn(system)

    return result.ok(res)
  } catch (err) {
    const {data, status} = err
    if (status === 400) {
      if (isTaskError(data)) {
        return result.error(errors.systemAlreadyHasActiveTask(data.activeTaskCreator))
      }
    }

    return result.error(errors.createUnknown(err))
  }
}

const unknownErrorMessage = 'Неизвестная ошибка'

function mapValidationErrorToStringTitle(validationError: ValidationError): string {
  return match(validationError)
    .with(
      {type: TaskErrorReason.SYSTEM_ALREADY_HAS_ACTIVE_TASK},
      () => 'По выбранной ИС уже есть активная заявка НТ'
    )
    .otherwise(() => unknownErrorMessage)
}

function mapValidationErrorToStringMessage(validationError: ValidationError): string {
  return match(validationError)
    .with(
      {type: TaskErrorReason.SYSTEM_ALREADY_HAS_ACTIVE_TASK},
      ({activeTaskCreator}) =>
        `Выберите другую ИС. При возникновении вопросов необходимо обратиться к Менеджеру НТ(${activeTaskCreator.email})`
    )
    .otherwise(() => unknownErrorMessage)
}

const schema = yup.object({
  systemId: yup
    .string()
    .required('Выберите ИС из списка')
    .test('server', 'Server error', async function (systemId) {
      return match(await memoValidateTaskAsyncResult(systemId))
        .with({isOk: true}, () => true)
        .with({isError: true}, ({value}) =>
          this.createError({message: mapValidationErrorToStringTitle(value)})
        )
        .exhaustive()
    }),
})

type FormValues = yup.InferType<typeof schema>

const SelectSystemPage = () => {
  const {taskId} = useParams()
  const navigate = useNavigate()

  const {notifyError, closeSnackbar} = useNotify()
  useTeardownEffect({onUnmount: closeSnackbar})

  const {data: systemsData, isLoading: isSystemsLoading} = useGetSystemsQuery({
    filter: SystemsFilter,
  })

  const systemsById = useMemo(
    () =>
      (systemsData?.items ?? []).reduce(
        (acc, next) => {
          acc[next.id] = next
          return acc
        },
        {} as Record<string, System>
      ),
    [systemsData]
  )

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const selectedSystemId = form.watch('systemId')
  const selectedSystem = useMemo(
    () => selectedSystemId && systemsById[selectedSystemId],
    [selectedSystemId, systemsById]
  )
  const {currentData: draftTask} = useGetTaskByIdQuery(taskId, {skip: !taskId})
  const {currentData: finishedTask} = useGetSystemFinishedTaskQuery(selectedSystemId, {
    skip: !selectedSystemId,
  })
  const [copyModalOpen, setCopyModalOpen] = useState(false)

  const [createTask, {isLoading: isCreatingTask}] = useCreateTaskMutation()
  const [updateTask, {isLoading: isUpdatingTask}] = useUpdateTaskMutation()

  const createTaskMapped = useCallback(
    (system: System) => createTaskResult(system, (x) => createTask({system: x}).unwrap()),
    [createTask]
  )

  const notifyErrorByTaskError = useCallback(
    (error: ValidationError) => {
      notifyError(mapValidationErrorToStringMessage(error), mapValidationErrorToStringTitle(error))
    },
    [notifyError]
  )

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit(
        async (_values) => {
          const unknownErrorHandler = (error: any) => {
            if (error.status === 409 && error.data?.messages?.[0])
              notifyError(error.data.messages[0], 'Ошибка', hideDuration)
            else notifyError()
          }

          if (draftTask) {
            await updateTask({taskId, update: {system: selectedSystem}})
              .unwrap()
              .then((_updated) => navigate(`/tasks/draft/${taskId}/steps/1`))
              .catch(unknownErrorHandler)
          } else if (finishedTask) {
            setCopyModalOpen(true)
          } else {
            await createTaskMapped(selectedSystem)
              .then((res) => {
                match(res)
                  .with({isOk: true}, ({value}) => {
                    navigate(`/tasks/create/${value.id}/steps/1`)
                  })
                  .with({isError: true}, ({value}) => {
                    notifyErrorByTaskError(value)
                  })
              })
              .catch(unknownErrorHandler)
          }
        },
        (_err) => {
          notifyError('Не удалось выбрать ИС.')
        }
      ),
    [
      createTaskMapped,
      draftTask,
      finishedTask,
      form,
      navigate,
      notifyError,
      notifyErrorByTaskError,
      selectedSystem,
      taskId,
      updateTask,
    ]
  )

  const systemIdError = form.formState.errors.systemId

  useEffect(() => {
    if (isEmpty(systemIdError)) {
      return
    }

    memoValidateTaskAsyncResult(selectedSystemId).then((res) => {
      if (result.isOk(res)) {
        return
      }

      notifyErrorByTaskError(res.value)
    })
  }, [notifyErrorByTaskError, selectedSystemId, systemIdError])

  if (isSystemsLoading) return <LoaderFrame />

  const loading = isCreatingTask || isUpdatingTask || form.formState.isValidating

  return (
    <form onSubmit={handleSubmit}>
      <div style={{margin: '0 16px 0 16px'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: null, label: 'Создание заявки: выбор ИС'},
          ]}
        />
        <Typography variant="h2">Создание заявки</Typography>
        <Section>
          <Typography style={{marginBottom: '8px'}} variant="h3">
            Добро пожаловать в создание заявок.
          </Typography>
          <Typography variant="p3">
            Необходимо выбрать Информационную систему, для которой создается заявка на нагрузочное
            тестирование.
          </Typography>
          <div style={{marginBottom: '24px', marginTop: '24px'}}>
            <SystemsCombobox form={form} />
          </div>
          <Box sx={{display: 'flex', alignItems: 'center', gap: '24px', mb: 'x4'}}>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault()
                navigate('/tasks')
              }}
            >
              На главную
            </Button>
            <Button disabled={loading} loading={loading} type="submit" variant="primary">
              Продолжить
            </Button>
          </Box>
        </Section>
      </div>
      <CopyTaskModal
        open={copyModalOpen}
        system={selectedSystem}
        onClose={() => setCopyModalOpen(false)}
      />
    </form>
  )
}

export default SelectSystemPage
