import {useState} from 'react'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {RoleTabs} from '../constants'
import {TaskStatusTabKind} from '../types'

const useContentTabs = () => {
  const {data: userInfo} = useGetUserinfoQuery()

  let roleTabs = []
  let topTabs = []
  if (userInfo) {
    if (userInfo.isAdmin) {
      topTabs = [TaskStatusTabKind.active, TaskStatusTabKind.draft]
    } else if (userInfo.isBusiness) {
      roleTabs = [RoleTabs.all, RoleTabs.creator, RoleTabs.stakeholders]
      topTabs = [TaskStatusTabKind.active, TaskStatusTabKind.draft]
    } else if (userInfo.isManager) {
      roleTabs = [RoleTabs.all, RoleTabs.mine, RoleTabs.notAssigned]
      topTabs = [TaskStatusTabKind.active]
    }
  }
  const [selectedRoleTab, setSelectedRoleTab] = useState(1)

  return {
    roleTabs,
    topTabs,
    selectedRoleTab,
    setSelectedRoleTab,
  }
}

export default useContentTabs
