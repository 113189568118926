import {Modal, Typography} from 'ui-kit'
import useNotify from '@root/hooks/useNotify'

import {FC, useCallback} from 'react'
import {useIsMounted} from '@root/hooks/useIsMounted'

type Props = {
  open: boolean
  deleteAction: (reportId: string) => Promise<void>
  reportId: string
  onClose: () => void
}
const ConfirmReportDeleteModal: FC<Props> = ({open, reportId, deleteAction, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()
  const isMountedRef = useIsMounted()

  const handleDeleteReport = useCallback(() => {
    deleteAction(reportId)
      .then(() => {
        notifySuccess('Отчёт удалён')

        if (isMountedRef.current) {
          onClose()
        }
      })
      .catch(() => {
        notifyError()
        if (isMountedRef.current) {
          onClose()
        }
      })
  }, [deleteAction, isMountedRef, notifyError, notifySuccess, onClose, reportId])

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDeleteReport,
            side: 'right',
            text: 'Удалить',
          },
        ]}
        isOpen={open}
        size="medium"
        title="Удаление шаблона"
        onClose={onClose}
      >
        <Typography variant="p1compact">
          Вы действительно хотите удалить отчет? Отчет не подлежит восстановлению. Можно будет
          создать отчет повторно.
        </Typography>
      </Modal>
    </>
  )
}

export default ConfirmReportDeleteModal
