import {FC, memo, PropsWithChildren, ReactElement, ReactNode} from 'react'
import {Text, TextProps, LinkProps} from '@react-pdf/renderer'
import {Link} from './Link'
import {AbstractTextUrlEnhancer, ImplProps} from '@root/components/AbstractTextUrlEnhancer'
import {isEmpty} from 'lodash'
import {noDataStub as noDataStubDefault} from './shared'

type Props = ImplProps<LinkProps, PropsWithChildren<TextProps>, undefined> & {
  noDataStub?: ReactNode
}

const PdfTextUrlEnhancer: FC<Props> = ({text, noDataStub = noDataStubDefault, ...other}: Props) => {
  if (isEmpty(text)) {
    return noDataStub as ReactElement
  }

  return (
    <AbstractTextUrlEnhancer
      LinkComponent={({children, href, ...other}) => (
        <Link href={href} {...other}>
          {children}
        </Link>
      )}
      text={text}
      TextComponent={Text}
      {...other}
    />
  )
}

export default memo(PdfTextUrlEnhancer)
