import {Modal, Typography} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useNavigate, useParams} from 'react-router-dom'
import {useDeleteReportMutation} from '@root/redux/api/report/reportsApi'

const ReportDeleteModal = ({open, onClose}) => {
  const navigate = useNavigate()
  const {notifySuccess, notifyError} = useNotify()
  const {reportId} = useParams()

  const [deleteReport] = useDeleteReportMutation()
  const handleDelete = () => {
    deleteReport(reportId)
      .unwrap()
      .then(() => {
        navigate('/reports')
        notifySuccess('Отчёт удалён')
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDelete,
            side: 'right',
            text: 'Удалить',
          },
        ]}
        isOpen={open}
        size="medium"
        title="Удаление отчёта"
        onClose={onClose}
      >
        <Typography>
          Вы действительно хотите удалить отчет? Отчет не подлежит восстановлению. Можно будет
          создать отчет повторно.
        </Typography>
      </Modal>
    </>
  )
}

export default ReportDeleteModal
