import {createApi} from '@reduxjs/toolkit/query/react'
import {OpenApiResponseType} from '@root/core/helperTypes'
import {components} from '@root/openapi'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {withQueryParams} from './utils'

type FinportalUrl = `/v1/finportal`
type CppPayload = {cpp: string}
export type VerifyBudgetResponse = components['schemas']['CheckBudgetResponse']
export type VerifyBudgetPayload = CppPayload & {workCost?: number | string}
export type VerifyCppResponse = OpenApiResponseType<`${FinportalUrl}/verify/{sppCode}`, 'get'>
export type GetReceiveOwnersResponse = OpenApiResponseType<
  `${FinportalUrl}/receive-owners/{sppCode}`,
  'get'
>

export const budgetApi = createApi({
  reducerPath: 'budgetApi',
  tagTypes: ['Budget'],
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    verifyCpp: build.query<VerifyBudgetResponse, CppPayload>({
      query: ({cpp}) => `/finportal/verify/${cpp}`,
    }),
    verifyBudget: build.mutation<VerifyBudgetResponse, VerifyBudgetPayload>({
      query: ({cpp, workCost}) => withQueryParams(`/finportal/verify/${cpp}`, {workCost}),
    }),
    getBudgetOwners: build.query<GetReceiveOwnersResponse, CppPayload>({
      query: ({cpp}) => `/finportal/receive-owners/${cpp}`,
    }),
  }),
})

export const {
  useVerifyBudgetMutation,
  useVerifyCppQuery,
  useLazyVerifyCppQuery,
  useGetBudgetOwnersQuery,
} = budgetApi
