import {Box, Tooltip, Typography, baseTheme} from '@x5-react-uikit/core'
import {Help} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {getFieldComponent} from '../utils/componentUtils'
import QuestionRow from './QuestionRow'
import TypographyGrey from '@root/components/typography/TypographyGrey'

const {colors} = baseTheme

const ParamField = ({fieldMeta, fieldValue, form, fieldIndex, stepIndex, sx}) => {
  const inputsMeta = fieldMeta.question
    ? fieldMeta.question[fieldValue.question].values
    : fieldMeta.values

  form.watch(`${stepIndex}.fields.${fieldIndex}.question`)

  return (
    <FlexboxColumn sx={{my: 'x16', ...sx}}>
      <FlexboxColumn sx={{mb: 'x12'}}>
        <FlexboxRow sx={{alignItems: 'center', gap: '8px'}}>
          <Typography variant="h3">{fieldMeta.label}</Typography>
          {fieldMeta.required && <TypographyGrey variant="h3">*</TypographyGrey>}
          {fieldMeta.description && (
            <Tooltip content={fieldMeta.description}>
              <Help size="large" style={{color: colors.grey[60]}} />
            </Tooltip>
          )}
        </FlexboxRow>
        {!fieldMeta.required && <Typography variant="p3">Необязательный пункт</Typography>}
      </FlexboxColumn>
      {fieldMeta.question && (
        <QuestionRow
          fieldIndex={fieldIndex}
          fieldMeta={fieldMeta}
          fieldValue={fieldValue}
          form={form}
          stepIndex={stepIndex}
        />
      )}
      {fieldMeta.id === 'deadlines' ? (
        <FlexboxRow sx={{flexWrap: 'wrap', boxSizing: 'border-box', mb: 'x12'} as any}>
          {inputsMeta.map((inputMeta, i) => {
            const indexes = {field: fieldIndex, step: stepIndex}
            return (
              <Box key={i} sx={{flex: '0 0 50%', boxSizing: 'border-box', pt: 'x12'} as any}>
                {getFieldComponent({inputMeta, form, indexes, fieldValue})}
              </Box>
            )
          })}
        </FlexboxRow>
      ) : (
        <FlexboxColumn sx={{gap: '24px'}}>
          {inputsMeta.map((inputMeta) => {
            const indexes = {field: fieldIndex, step: stepIndex}
            return getFieldComponent({inputMeta, form, indexes, fieldValue})
          })}
        </FlexboxColumn>
      )}
    </FlexboxColumn>
  )
}

export default ParamField
