import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import useNotify from '@root/hooks/useNotify'
import useUpdateFormData from '@root/pages/tasks/CreateTaskPage/hooks/useUpdateFormData'
import {getFormIndiciesById} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {useLazyVerifyCppQuery} from '@root/redux/api/budgetApi'
import {useGetTaskByIdQuery, useUpdateTaskMutation} from '@root/redux/api/tasksApi'
import {ChevronDown, ChevronUp, Done} from '@x5-react-uikit/icons'
import {FC, Fragment, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Box, Button, Typography} from 'ui-kit'
import useFinportalData from '../hooks/useFinportalData'
import {UseTaskPageForm} from '../hooks/useTaskPageForm'
import BudgetFieldEdit from './BudgetFieldEdit'
import BudgetFieldView from './BudgetFieldVlew'
import {TaskStatuses} from '@root/constants'

type Props = {
  form: UseTaskPageForm['form']
  readonly?: boolean
}

const CppNotFoundError = {type: 'custom', message: 'Статьи нет в базе. Проверьте и измените номер'}

const BudgetFieldAccordeon: FC<Props> = ({form, readonly}) => {
  const {taskId} = useParams()
  const {data: task} = useGetTaskByIdQuery(taskId)

  const {notifyError, notifySuccess, notifyWarning} = useNotify()
  const [editing, setEditing] = useState(false)
  const handleSetEditing = () => {
    if (!expanded) setExpanded(true)
    setEditing(true)
  }
  const [expanded, setExpanded] = useState(false)

  const finportalData = useFinportalData()
  useEffect(() => {
    form.setValue('finportal', {cpp: finportalData.cpp, workCost: finportalData.workCost})
  }, [finportalData.cpp, finportalData.workCost, form])

  const {stepIndex, fieldIndex} = useMemo(
    () => getFormIndiciesById(Object.values(form.getValues('formData')), 'budget_source'),
    // eslint-disable-next-line
    []
  )
  const handleCancelEditing = () => {
    setEditing(false)

    form.setValue('finportal', {cpp: finportalData.cpp, workCost: finportalData.workCost})
  }

  const {updateFormData, isUpdating: isFormDataUpdating} = useUpdateFormData()
  const [updateTask, {isLoading: isTaskUpdating}] = useUpdateTaskMutation()
  const [verifyCpp, {isFetching: isCppVerifying}] = useLazyVerifyCppQuery()
  const handleSave = async () => {
    const dirtyFields = form.formState.dirtyFields as any

    await form.trigger('finportal.cpp')

    const state = form.getFieldState('finportal.cpp')

    if (state.invalid) {
      notifyError('Поле СПП содержит ошибки')
      return
    }

    if (!dirtyFields.finportal) {
      setEditing(false)

      return
    }

    if (dirtyFields.finportal.cpp) {
      const cpp = form.getValues('finportal.cpp')
      try {
        await verifyCpp({cpp}).unwrap()
      } catch (error) {
        if (error.status === 404) {
          form.setError('finportal.cpp', CppNotFoundError)
          return
        } else {
          notifyWarning('Не удалось проверить номер СПП.')
        }
      }

      const steps = Object.values(form.getValues('formData'))
      steps[stepIndex].fields[fieldIndex].values.text[0].value = cpp
      await updateFormData({
        steps: steps,
        currentStepId: steps[stepIndex].number,
        updatedFields: ['budget_source'],
        shouldNotify: false,
      })
    }

    if (dirtyFields.finportal.workCost) {
      try {
        await updateTask({
          taskId,
          update: {workCost: form.getValues('finportal.workCost')},
        }).unwrap()
        form.resetField('finportal.workCost', {defaultValue: form.getValues('finportal.workCost')})
      } catch (error) {
        notifyError()
        console.error(error)
        return
      }
    }
    notifySuccess(
      'Изменения сохранены',
      'Пункт сохранен. Участники заявки получат уведомление об этом.'
    )
    setEditing(false)
    finportalData.budgetResetVerify()
  }

  const content = editing ? (
    <BudgetFieldEdit cppEditable={task.status === TaskStatuses.IN_REVIEW} form={form} />
  ) : (
    <BudgetFieldView finportalData={finportalData} />
  )

  const submiting = isFormDataUpdating || isCppVerifying || isTaskUpdating
  return (
    <>
      <FlexboxRow
        sx={{
          py: 'x16',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <FlexboxColumn>
            <FlexboxRow
              sx={{cursor: 'pointer', userSelect: 'none'} as any}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? <ChevronUp /> : <ChevronDown />}
              <Typography variant="h3">Статья для списания бюджета (СПП)</Typography>
            </FlexboxRow>
          </FlexboxColumn>
          {!readonly &&
            (!editing ? (
              <Button variant="text" onClick={handleSetEditing}>
                Редактировать пункт
              </Button>
            ) : (
              <FlexboxRow sx={{gap: '16px'}}>
                <Button size="medium" variant="outlined" onClick={handleCancelEditing}>
                  Отмена
                </Button>
                <Button
                  disabled={submiting}
                  loading={submiting}
                  size="medium"
                  startIcon={<Done size="small" />}
                  style={{textWrap: 'nowrap'}}
                  onClick={handleSave}
                >
                  Сохранить изменения
                </Button>
              </FlexboxRow>
            ))}
        </FlexboxRow>
      </FlexboxRow>
      {expanded && (
        <Box sx={{mb: 'x16'}}>
          <FlexboxRow sx={{gap: '80px'}}>
            {content}
            {finportalData.cppVerified === true && (
              <FlexboxColumn sx={{gap: '4px'}}>
                <Typography>Согласование расходов</Typography>
                {!finportalData.budgetOwners ? (
                  <LoaderFrame height="241px" />
                ) : (
                  finportalData.budgetOwners.map((owner, responsibleIndex) => {
                    const last = responsibleIndex === finportalData.budgetOwners.length - 1
                    return (
                      <Fragment key={responsibleIndex}>
                        <Typography variant="p1">
                          {responsibleIndex + 1}. {owner.userLogin}
                        </Typography>
                        <Typography variant="p1">{owner.positionUser}</Typography>
                        {!last && <Divider />}
                      </Fragment>
                    )
                  })
                )}
              </FlexboxColumn>
            )}
          </FlexboxRow>
        </Box>
      )}
    </>
  )
}

export default BudgetFieldAccordeon
