import {Link, Tab, TabList, Tabs, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useContext, useState} from 'react'
import {useGetSystemByIdQuery} from '@root/redux/api/systemsApi'
import ReviewReportDataTab from './ReviewReportDataTab'
import TypographyGrey from '@root/components/typography/TypographyGrey'
import TypographyError from '@root/components/typography/TypographyError'
import ReviewReportStatisticsTab from './ReviewReportStatisticsTab'
import {getTagOrName} from '@root/features/systems/utils'
import {Button, Typography} from 'ui-kit'
import {confUpdateContext} from '../../context'

const {colors} = baseTheme

const DataTypeTabs = {
  data: 'Данные',
  statistics: 'Статистика',
}

const ReviewStep = ({form}) => {
  const formValue = form.getValues()

  const {currentData: system} = useGetSystemByIdQuery(formValue?.systemId, {
    skip: !formValue?.systemId,
  })

  const ctx = useContext(confUpdateContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const [dataTab, setDataTab] = useState(DataTypeTabs.data)
  const tabContent =
    dataTab === DataTypeTabs.data ? (
      <ReviewReportDataTab form={form} />
    ) : (
      <ReviewReportStatisticsTab form={form} />
    )

  return (
    <>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
        <FlexboxRow sx={{justifyContent: 'space-between'}}>
          <Typography variant="h3">Название конфигурации</Typography>
          {!formValue.name && (
            <Button variant="text" onClick={() => ctx.setStep(1)}>
              Вернуться к пункту
            </Button>
          )}
        </FlexboxRow>
        {formValue.name ? (
          <Typography variant="p1">{formValue.name}</Typography>
        ) : (
          <TypographyError>
            Пункт обязательный. Без его заполнения отправка невозможна
          </TypographyError>
        )}
      </Section>
      <FlexboxRow sx={{gap: '24px'}}>
        <Section
          sx={{display: 'flex', flexDirection: 'column', width: '588px', gap: '24px', my: 'x0'}}
        >
          <Typography variant="h3">Принадлежность конфигурации</Typography>
          <FlexboxColumn sx={{gap: '4px'}}>
            <Typography variant="p1">Информационная система</Typography>
            {system ? (
              <Typography>{getTagOrName(system)}</Typography>
            ) : (
              <TypographyError>
                Пункт обязательный. Без его заполнения отправка невозможна
              </TypographyError>
            )}
          </FlexboxColumn>
          <FlexboxColumn sx={{gap: '4px'}}>
            <Typography>Заявка</Typography>
            <Typography style={{color: colors.grey[60]}}>
              Автоматически при формировании отчета
            </Typography>
          </FlexboxColumn>
        </Section>
        <Section
          sx={{display: 'flex', flexDirection: 'column', width: '588px', gap: '24px', my: 'x0'}}
        >
          <Typography variant="h3">Дата и задачи</Typography>
          <FlexboxColumn sx={{gap: '4px'}}>
            <Typography>Дата</Typography>
            <Typography style={{color: colors.grey[60]}}>
              Автоматически при формировании отчета
            </Typography>
          </FlexboxColumn>
          <FlexboxColumn sx={{gap: '4px'}}>
            <Typography>Задачи в Kaiten</Typography>
            {formValue.kaitenCardLink ? (
              <Link
                style={{width: 'fit-content'}}
                onClick={() => window.open(formValue.kaitenCardLink, '_blank')}
              >
                Перейти к связанным задачам
              </Link>
            ) : (
              <TypographyGrey>Не указаны</TypographyGrey>
            )}
          </FlexboxColumn>
        </Section>
      </FlexboxRow>
      <Section sx={{display: 'flex', flexDirection: 'column'}}>
        <Tabs value={dataTab} onChange={(e, newTab: string) => setDataTab(newTab)}>
          <TabList>
            <Tab label={DataTypeTabs.data} value={DataTypeTabs.data} />
            <Tab label={DataTypeTabs.statistics} value={DataTypeTabs.statistics} />
          </TabList>
        </Tabs>
        {tabContent}
      </Section>
    </>
  )
}

export default ReviewStep
