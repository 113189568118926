import {ArrayPath, FieldPath, FieldValues, useWatch} from 'react-hook-form'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {baseTheme, IconButton} from 'ui-kit'
import {Del as DelIcon, Refresh as RefreshIcon} from '@x5-react-uikit/icons'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'
import {Control} from 'react-hook-form/dist/types/form'
import {isEmpty} from 'lodash'
import {useCallback, useRef} from 'react'
import {genericMemo} from '@root/utils'
import {urlValidator} from '@root/validators'

const {colors} = baseTheme

type Props<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>
  name: ArrayPath<TFieldValues>

  remove: () => void
  removeDisabled?: boolean
}

function FormGrafanaUrl<TFieldValues extends FieldValues>({
  control,
  name,
  remove,
  removeDisabled,
}: Props<TFieldValues>) {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const graphUrlPath = `${name}.graphUrl` as FieldPath<TFieldValues>

  const graphNamePath = `${name}.graphName` as FieldPath<TFieldValues>

  const graphUrl = useWatch({name: graphUrlPath, control})

  const graphNameValue = useWatch({name: graphNamePath, control})

  const refresh = useCallback(() => {
    if (iframeRef.current == null) {
      return
    }

    // Causes iframe reload without rerender
    iframeRef.current.src += ''
  }, [])

  const showUrl = urlValidator(true).isValidSync(graphUrl)

  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      <FlexboxRow sx={{gap: '24px', alignItems: 'top', justifyContent: 'space-between'}}>
        <FlexboxRow sx={{gap: '24px', alignItems: 'top'}}>
          <IconButton
            disabled={isEmpty(graphUrl)}
            IconComponent={<RefreshIcon />}
            style={{color: colors.grey[60]}}
            variant="text"
            onClick={refresh}
          />
          <FormInputText
            required
            control={control}
            label="Название графика"
            name={graphNamePath}
            rules={RequiredRule}
            width="540px"
          />
          <FormInputText
            required
            control={control}
            label="Ссылка на график в Grafana "
            name={graphUrlPath}
            rules={RequiredRule}
            width="540px"
          />
        </FlexboxRow>
        <IconButton
          disabled={removeDisabled}
          IconComponent={<DelIcon />}
          variant="text"
          onClick={remove}
        />
      </FlexboxRow>
      {showUrl && (
        <iframe
          ref={iframeRef}
          src={graphUrl}
          style={{height: '400px'}}
          title={graphNameValue}
        ></iframe>
      )}
      <FormInputText
        textarea
        control={control}
        label="Комментарий"
        name={`${name}.comment` as FieldPath<TFieldValues>}
        width="520px"
      />
    </FlexboxColumn>
  )
}

export default genericMemo(FormGrafanaUrl)
