import FlexboxColumn from '@root/components/FlexboxColumn'
import {baseTheme, Modal, Typography} from 'ui-kit'
import {AssigneeFormValue} from '../../types'
import {FC} from 'react'
import {displayUser} from '@root/features/users/displayUser'

const {typography, colors} = baseTheme

type Props = {
  open: boolean
  onClose: () => void
  onCancel: () => void
  manager: AssigneeFormValue['manager']
  usersWithAccess: AssigneeFormValue['usersWithAccess']
  handleSubmit: () => void
  isSubmiting?: boolean
}

const SubmitChangeAssignee: FC<Props> = ({
  open,
  onClose,
  onCancel,
  manager,
  usersWithAccess,
  handleSubmit,
  isSubmiting,
}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onCancel,
          side: 'right',
          text: 'Вернуться к изменению',
        },
        {
          onClick: handleSubmit,
          side: 'right',
          text: 'Подтвердить',
          props: {
            loading: isSubmiting,
            disabled: isSubmiting,
          },
        },
      ]}
      isOpen={open}
      size="medium"
      title='Изменение данных "Работа с заявкой"'
      onClose={onClose}
    >
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography>
          <span>Менеджер:&nbsp;</span>
          <span style={manager ? typography.h4 : {color: colors.grey[60]}}>
            {manager ? displayUser(manager) : 'Не выбран'}
          </span>
        </Typography>
        <Typography>
          <span>Доступ к заявке:&nbsp;</span>
          <span style={usersWithAccess?.length ? typography.h4 : {color: colors.grey[60]}}>
            {usersWithAccess?.filter(Boolean).length
              ? usersWithAccess.filter(Boolean).map(displayUser).join(', ')
              : 'Не выбран'}
          </span>
        </Typography>
        <Typography>
          Выбранные пользователи будут закреплены за данной заявкой. У них будут полномочия
          взаимодействовать с ней. Она появится в списке принадлежащих им заявок.
        </Typography>
      </FlexboxColumn>
    </Modal>
  )
}

export default SubmitChangeAssignee
