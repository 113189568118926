import {Add as AddIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ArrayPath, ControllerProps, FieldArray, FieldValues, useFieldArray} from 'react-hook-form'
import {Fragment, useCallback} from 'react'
import {FormFileWithComment} from '@root/features/fileUpload'
import Divider from '@root/components/Divider'
import {Button} from 'ui-kit'
import {genericMemo} from '@root/utils'
import FormGrafanaUrl from './FormGrafanaUrl'

type Props<TFieldValues extends FieldValues> = {
  name: ArrayPath<TFieldValues>
  limit?: number
} & Pick<ControllerProps<TFieldValues>, 'control'>

function GrafanaUrlArray<TFieldValues extends FieldValues>({
  name,
  control,
  limit,
}: Props<TFieldValues>) {
  const {fields, remove, append} = useFieldArray({
    name,
    control,
  })

  const isLimited = limit > 1 ? fields.length === limit : false

  const appendEmpty = useCallback(() => {
    const val: FormFileWithComment = {
      comment: '',
      name: '',
      file: [],
    }

    append(val as FieldArray<TFieldValues, ArrayPath<TFieldValues>>)
  }, [append])

  return (
    <FlexboxColumn sx={{gap: '24px'}}>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <FormGrafanaUrl
            control={control}
            name={`${name}.${index}` as ArrayPath<TFieldValues>}
            remove={() => remove(index)}
          />

          {index !== fields.length - 1 && <Divider sx={{my: 'x8'}} />}
        </Fragment>
      ))}
      <Button
        disabled={isLimited}
        startIcon={<AddIcon size="small" />}
        style={{width: 'fit-content'}}
        variant="text"
        onClick={appendEmpty}
      >
        Добавить график
      </Button>
    </FlexboxColumn>
  )
}

export default genericMemo(GrafanaUrlArray)
