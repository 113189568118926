import {FilterItemType} from '@root/openapi'
import {useGetUsersByFilterQuery} from '@root/redux/api/userApi'
import {useState} from 'react'
import {Combobox} from 'ui-kit'

const usersEmpty = {items: []}

const getDefaultFilter = (roles) => ({
  pageNumber: 1,
  pageSize: 9999999,
  filters: {role: {value: roles, type: FilterItemType.EQUAL}},
})

const UsersCombobox = ({
  required,
  onChange,
  onClear,
  label,
  selectedUser,
  widthPx,
  roles,
  exludedUsers = [],
}) => {
  const [usersFilter, setUsersFilter] = useState(getDefaultFilter(roles))
  const updateEmail = (email) => {
    setUsersFilter((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        email: {value: email, type: FilterItemType.LIKE},
      },
    }))
  }
  const emailFilterValue = usersFilter?.filters?.email?.value
  const {data: users = usersEmpty} = useGetUsersByFilterQuery({filter: usersFilter})

  const onClearClick = () => {
    if (onClear) {
      onClear()
    } else {
      setUsersFilter(getDefaultFilter(roles))
    }
  }

  let options =
    selectedUser && !emailFilterValue
      ? [{name: selectedUser.email, value: selectedUser}]
      : users.items.map((u) => ({name: u.email, value: u}))
  if (exludedUsers.length > 0)
    options = options.filter(
      (option) => !exludedUsers.find((excluded) => excluded.email === option.value.email)
    )
  // WARNING: value должен быть строго равен одному из option.value
  // FIXME: Возможно, будет правильнее ассайнить value строку (например user.id)
  const value = options?.map((o) => o.value).find((o) => o.id === selectedUser?.id)

  return (
    <Combobox
      usePortal
      label={label}
      options={options}
      required={required}
      value={value}
      width={widthPx}
      onChange={onChange}
      onClearClick={onClearClick}
      onFilterChange={(event) => updateEmail(event.target.value)}
    />
  )
}

export default UsersCombobox
