import {components} from '@root/openapi'
import {useGetUsersByFilterQuery, UserListResponse} from '@root/redux/api/userApi'
import {Combobox, CustomFilterInputProps, SelectOptionType, SelectProps} from '@root/components/kit'
import {FC, useCallback, useMemo} from 'react'

type UserItems = UserListResponse['items']

interface Props extends CustomFilterInputProps {
  filter: components['schemas']['FilterWithSortPagedRequest']
  optionsMap?: (items: UserItems) => SelectOptionType[]
  comboboxProps?: Partial<
    Omit<
      SelectProps,
      | 'chipText'
      | 'label'
      | 'options'
      | 'size'
      | 'stretch'
      | 'value'
      | 'width'
      | 'onChange'
      | 'onClearClick'
    >
  >
}

const defaultOptionsMap = (items: UserItems): SelectOptionType[] =>
  items?.map((x) => ({
    name: x.email,
    value: x.id,
  })) ?? []

export const FilterCollectorUserCombobox: FC<Props> = ({
  onChange,
  value,
  size,
  filter,
  optionsMap = defaultOptionsMap,
  comboboxProps,
}) => {
  const {currentData} = useGetUsersByFilterQuery({filter})

  const castResponse = currentData as UserListResponse

  const options: SelectOptionType[] = useMemo(
    () => optionsMap(castResponse?.items),
    [castResponse?.items, optionsMap]
  )

  const handleChange: SelectProps['onChange'] = useCallback(
    (_e, val) => {
      onChange(val as string)
    },
    [onChange]
  )

  const onClearClick: SelectProps['onClearClick'] = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Combobox
      chipText="Выбрано: "
      label="Значение"
      options={options}
      size={size}
      stretch={true}
      value={value}
      width="100%"
      onChange={handleChange}
      onClearClick={onClearClick}
      {...comboboxProps}
    />
  )
}
