import {TaskStatuses} from '@root/constants'
import {Task} from '@root/features/tasks/types'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {isClientErrorCode} from '@root/utils'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const getRedirectByStatus = (task: Task) => {
  switch (task?.status) {
    case TaskStatuses.DRAFT:
      return `/tasks/draft/${task.id}/steps/1`
    case TaskStatuses.WITHDRAWN:
      return `/tasks/withdrawn/${task.id}/steps/1`
    default:
      return null
  }
}

export const useGetTaskGuard = (taskId: string) => {
  const navigate = useNavigate()
  const [initialized, setInitialized] = useState(false)

  const taskQueryResult = useGetTaskByIdQuery(taskId)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (taskQueryResult.isLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }

    if (
      taskQueryResult.error != null &&
      isClientErrorCode((taskQueryResult.error as any)?.status)
    ) {
      setLoading(true)
      navigate('/tasks')
      return
    }

    if (initialized) {
      return
    }

    const redirectUri = getRedirectByStatus(taskQueryResult.data)
    if (redirectUri == null) {
      return
    }

    setInitialized(true)
    setLoading(true)
    navigate(redirectUri)
  }, [
    initialized,
    navigate,
    taskQueryResult.data,
    taskQueryResult.error,
    taskQueryResult.isLoading,
  ])

  return {task: taskQueryResult.data, isTaskLoading: loading}
}
