import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import {
  Done as DoneIcon,
  Info as InfoIcon,
  WarningOutline as WarningIcon,
} from '@x5-react-uikit/icons'
import React, {FC} from 'react'
import {Banner, baseTheme, Button, Typography} from 'ui-kit'
import {FinportalData} from '../hooks/useFinportalData'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {match} from 'ts-pattern'

const {colors} = baseTheme

const BudgetEnough = () => (
  <FlexboxRow sx={{gap: '4px', alignItems: 'center'}}>
    <DoneIcon color={colors.green[80]} />
    <Typography style={{color: colors.grey[60]}} variant="p2">
      В бюджете достаточно средств
    </Typography>
  </FlexboxRow>
)
const BudgetNotEnough = () => (
  <FlexboxRow sx={{gap: '4px', alignItems: 'center'}}>
    <WarningIcon color={colors.red[80]} />
    <Typography style={{color: colors.grey[60]}} variant="p2">
      В бюджете недостаточно средств
    </Typography>
  </FlexboxRow>
)

const CppNotVerified = () => (
  <FlexboxRow sx={{gap: '4px', alignItems: 'center'}}>
    <InfoIcon color={colors.grey[60]} />
    <Typography style={{color: colors.grey[60]}} variant="p2">
      Данные по СПП не получены
    </Typography>
  </FlexboxRow>
)
const CppValid = () => (
  <FlexboxRow sx={{gap: '4px', alignItems: 'center'}}>
    <DoneIcon color={colors.green[80]} />
    <Typography style={{color: colors.grey[60]}} variant="p2">
      СПП проверен
    </Typography>
  </FlexboxRow>
)
const CppInvalid = () => (
  <FlexboxRow sx={{gap: '4px', alignItems: 'center'}}>
    <InfoIcon color={colors.red[60]} />
    <Typography style={{color: colors.red[60]}} variant="p2">
      Некорректный номер СПП
    </Typography>
  </FlexboxRow>
)

type Props = {
  finportalData: FinportalData
}

const BudgetFieldView: FC<Props> = ({finportalData}) => {
  const {data: userInfo} = useGetUserinfoQuery()

  const verifyStatusContent = match(finportalData.cppVerified)
    .with(true, () => <CppValid />)
    .with(false, () => <CppInvalid />)
    .otherwise(() => <CppNotVerified />)
  if (!finportalData.cpp || finportalData.isCppVerifying) return <LoaderFrame />

  return (
    <FlexboxColumn sx={{gap: '24px', width: '536px'}}>
      <FlexboxColumn sx={{gap: '8px'}}>
        <FlexboxColumn sx={{gap: '4px'}}>
          <Typography>СПП</Typography>
          <FlexboxRow sx={{gap: '16px', alignItems: 'center'}}>
            <Typography>{finportalData.cpp}</Typography>
            {verifyStatusContent}
          </FlexboxRow>
        </FlexboxColumn>
        {finportalData.cppVerifyData?.sppTypeName && (
          <Banner icon message={finportalData.cppVerifyData?.sppTypeName} type="grey" />
        )}
      </FlexboxColumn>
      {(userInfo.isAdmin || userInfo.isManager) && (
        <FlexboxColumn sx={{gap: '16px'}}>
          <FlexboxColumn sx={{gap: '4px'}}>
            <Typography>Стоимость работ</Typography>
            <FlexboxRow sx={{gap: '16px', alignItems: 'center'}}>
              <Typography>
                {finportalData.workCost ? `${finportalData.workCost} рублей` : '-'}
              </Typography>
              {finportalData.workCost &&
                finportalData.budgetVerifyResult &&
                (finportalData.budgetVerifyResult.checkBudget ? (
                  <BudgetEnough />
                ) : (
                  <BudgetNotEnough />
                ))}
            </FlexboxRow>
          </FlexboxColumn>
          <Button
            disabled={
              !finportalData.cppVerified ||
              !finportalData.workCost ||
              finportalData.isBudgetVerifying
            }
            loading={finportalData.isBudgetVerifying}
            style={{width: 'fit-content'}}
            variant="outlined"
            onClick={() => finportalData.budgetVerify()}
          >
            Проверить
          </Button>
        </FlexboxColumn>
      )}
    </FlexboxColumn>
  )
}

export default BudgetFieldView
