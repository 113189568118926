import React, {FC, CSSProperties, cloneElement, useState, useMemo, Fragment, useRef} from 'react'
import {
  autoUpdate,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
  arrow,
} from '@floating-ui/react'
import clsx from 'clsx'
import {TooltipProps, tooltipPlacements} from './types'
import {StyledContent, StyledFloatingArrow} from './styles'

import {getQaAttribute} from '../utils'

export const defaultTooltipPlacement = tooltipPlacements.bottom

export const Tooltip: FC<TooltipProps> = ({
  children,
  zIndex,
  className,
  content,
  placement = defaultTooltipPlacement,
  contentWidth = 'auto',
  qa = 'tooltip',
  interactive,
  usePortal = false,
  doNotClone,
}) => {
  const getQA = getQaAttribute(qa)
  const [open, setOpen] = useState<boolean>(false)
  const arrowRef = useRef()

  const {floatingStyles, refs, context} = useFloating({
    open,
    onOpenChange: setOpen,
    strategy: 'absolute',
    placement,
    middleware: [shift(), arrow({element: arrowRef})],
    whileElementsMounted: autoUpdate,
  })

  const {styles: transitionStyles, isMounted} = useTransitionStyles(context, {
    duration: {
      open: 330,
      close: 100,
    },
  })

  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([dismiss])

  const contentStyle: CSSProperties = useMemo(() => {
    if (contentWidth === 'pre') {
      return {
        whiteSpace: 'pre',
        maxWidth: 'none',
      }
    }

    if (typeof contentWidth === 'number') {
      return {
        maxWidth: contentWidth,
        wordBreak: 'break-word',
      }
    }

    return {}
  }, [contentWidth])

  const zStyle: CSSProperties = useMemo(() => {
    if (!open) {
      return undefined
    }

    const o: CSSProperties = {}
    if (zIndex) {
      o.zIndex = zIndex
    }
    return o
  }, [open, zIndex])

  const childrenProps = {
    'data-qa': getQA('target'),
    className: clsx(className, children.props.className),
    onMouseOver: () => setOpen(true),
    onMouseLeave: () => setOpen(false),
  }

  if (!content) {
    return children
  }

  const renderReference = doNotClone ? (
    <div {...childrenProps}>{children}</div>
  ) : (
    cloneElement(children, {...childrenProps, ...children.props})
  )

  const ContentWrapper = usePortal ? FloatingPortal : Fragment

  return (
    <>
      {
        <div
          ref={refs.setReference}
          data-qa={getQA()}
          onMouseLeave={interactive ? () => setOpen(false) : null}
          onMouseOver={interactive ? () => setOpen(true) : null}
          {...getReferenceProps()}
        >
          {renderReference}
        </div>
      }
      {isMounted && (
        <ContentWrapper>
          <StyledContent
            ref={refs.setFloating}
            style={{...floatingStyles, ...transitionStyles, ...contentStyle, ...zStyle}}
            {...getFloatingProps()}
          >
            <div data-qa={getQA('content')}>{content}</div>
            <StyledFloatingArrow ref={arrowRef} context={context} data-qa={getQA('arrow')} />
          </StyledContent>
        </ContentWrapper>
      )}
    </>
  )
}

export default Tooltip
