import {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  FC,
  ReactNode,
  useState,
  ChangeEventHandler,
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
} from 'react'

import CellTypography from '@root/components/dataGridTable/CellTypography'
import TablePagination from '@root/components/dataGridTable/TablePagination'
import DataGridTable from '@root/components/dataGridTable/table/DataGridTable'
import {
  extractValueFromAction,
  formatDateRuLocale,
  formatDateTimeRuLocale,
  genericMemo,
  id,
  notEmpty,
} from 'utils'
import {
  AppTableChangeEventType,
  AppTableColumn,
  AppTableProps,
  AppTableState,
  GetIdFn,
  PatchFilterCollectorColumnsFn,
  RowItemBase,
  SortItem,
  SortTextKind,
  ValueRenderType,
} from './types'

import {
  FilterCollectorColumn,
  FilterCollectorProps,
  FilterCollector,
  Loader,
  Typography,
  IconButton,
} from 'ui-kit'

import FlexboxRow from '@root/components/FlexboxRow'

import {
  SegmentedControlSizes,
  UncontrolledSegmentedControls,
  UncontrolledSegmentedControlsProps,
} from '@root/components/UncontrolledSegmentedControl'

import styled from '@emotion/styled'
import {groupBy, isEmpty, isFunction, isString, omit} from 'lodash'
import {SetAction} from '@root/core/helperTypes'
import {convertHexToRgba} from '@x5-react-uikit/core'
import {useDimensions} from '@root/hooks/useDimensions'
import {animated, useTransition} from '@react-spring/web'
import {AppTableHeadCell, AppTableHeadCellProps} from './components/headCell/AppTableHeadCell'
import clsx from 'clsx'
import CheckboxCellContent from '@root/components/dataGridTable/CellCheckbox'
import DataGridRow from './components/Row/Row'
import {EmptyTableStub} from '@root/components/stubs/EmptyTableStub'
import ErrorTableStub from '@root/components/stubs/ErrorTableStub'
import {RowAccordion} from './components/RowAccordion'
import {Close as CloseIcon} from '@x5-react-uikit/icons'

function toCollectorColumns<T extends RowItemBase>(
  columns: AppTableColumn<T>[]
): FilterCollectorColumn[] {
  return columns
    .filter((x) => notEmpty(x.filterableOptions))
    .map((x) => {
      const name = x.filterableOptions?.name ?? x.title

      if (name == null) {
        console.error('Column err', x)
        throw new Error(
          'You have to define either name or title for the column in order to use filters'
        )
      }

      return {
        ...x.filterableOptions,
        value: x.filterableOptions?.value ?? x.key ?? x.dataIndex,
        name,
      }
    })
}

type MemoFilterCollectorProps<T extends RowItemBase> = Omit<FilterCollectorProps, 'columns'> & {
  columns: AppTableColumn<T>[]
  patchFilterCollectorColumns?: PatchFilterCollectorColumnsFn
}

function InternalFilterCollector<T extends RowItemBase>({
  columns,
  patchFilterCollectorColumns = id,
  ...rest
}: MemoFilterCollectorProps<T>) {
  const filterColumns = useMemo(
    () => patchFilterCollectorColumns(toCollectorColumns(columns)),
    [columns, patchFilterCollectorColumns]
  )

  return <FilterCollector columns={filterColumns} {...rest} />
}

const MemoFilterCollector = genericMemo(InternalFilterCollector)

function validateColumns<T extends RowItemBase>(columns: AppTableColumn<T>[]) {
  let checkboxColumnCount = 0

  for (const {key, dataIndex, isCheckbox} of columns) {
    if (isCheckbox) {
      checkboxColumnCount++

      if (checkboxColumnCount > 1) {
        throw new Error("Table can't have more than one checkbox column")
      }

      continue
    }

    if (key == null && dataIndex == null) {
      throw new Error(`Column must have either key or dataIndex`)
    }
  }
}

function omitWhenNotNull<T extends object>(data: T | undefined | null, ...fields: string[]) {
  if (data == null) return {}

  return omit(data, fields)
}

function getColumnKey<T extends RowItemBase>(col: AppTableColumn<T>) {
  return col.key ?? col.dataIndex
}

function matchRenderType<T extends RowItemBase>(
  renderType: ValueRenderType,
  value: T[keyof T & string]
) {
  if (isString(value)) {
    if (renderType === 'dateTime') {
      return <CellTypography noPadding>{formatDateTimeRuLocale(value)}</CellTypography>
    }

    if (renderType === 'date') {
      return <CellTypography noPadding>{formatDateRuLocale(value)}</CellTypography>
    }

    if (renderType === 'string') {
      return (
        <CellTypography noPadding title={value}>
          {value}
        </CellTypography>
      )
    }
  }

  return <CellTypography noPadding>{value}</CellTypography>
}

function renderCell<T extends RowItemBase>(row: T, column: AppTableColumn<T>) {
  if (column.renderCell) {
    return column.renderCell(column.dataIndex ? row[column.dataIndex] : undefined, row, column)
  }

  const renderType = column.valueRenderType

  if (renderType && row[column.dataIndex] != null) {
    const value = row[column.dataIndex]
    return matchRenderType(renderType, value)
  } else {
    return <CellTypography noPadding>{row[column.dataIndex]}</CellTypography>
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const dividerClass = 'divider'
const TabsWrap = styled.div`
  position: relative;
  &.${dividerClass} {
    padding-right: 9px;
  }
`

const StyledDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${({theme}) => theme.colors.grey[20]};
`

const TabsDivider = styled(StyledDivider)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

const TableWrapper = styled.div`
  position: relative;
  flex: 1 0;

  & > table {
    width: 100%;
    z-index: 1;

    & > tbody {
      position: relative;
    }
  }
`

const StyledLoader = styled(animated.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${({theme}) => convertHexToRgba(theme.colors.grey[80], 60)};
  z-index: 2;
  left: 0;
  bottom: 0;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const UncheckAllButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

type LoaderOverlayProps = {
  heightOffset: number
  active: boolean
  customLoader?: ReactNode
}

const LoaderOverlay: FC<LoaderOverlayProps> = ({heightOffset, active, customLoader}) => {
  const transition = useTransition(active, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  return transition((style, isOpen) => {
    if (isOpen) {
      return (
        <StyledLoader style={{height: `calc(100% - ${heightOffset}px)`, ...style}}>
          {customLoader ? customLoader : <Loader />}
        </StyledLoader>
      )
    }

    return null
  })
}

const Header = styled.div``

function mutateTableState<Row extends RowItemBase, Tab extends number>(
  obj: Partial<AppTableState<Row, Tab>>,
  pagination: AppTableProps<Row, Tab>['pagination'],
  formState: AppTableProps<Row, Tab>['formState'],
  tabs: AppTableProps<Row, Tab>['tabs'],
  sort: SortItem<Row>[],
  checkedRows?: AppTableProps<Row, Tab>['checkedRows']
) {
  obj.pages = pagination
    ? {
        currentPage: pagination.currentPage,
        pageSize: pagination.pageSize,
      }
    : undefined

  obj.filterValues = formState
  obj.currentTab = tabs?.current
  obj.sort = sort
  obj.checkedRows = checkedRows

  return obj
}

const eventTypes: Record<AppTableChangeEventType, AppTableChangeEventType> = {
  pages: 'pages',
  currentTab: 'currentTab',
  sort: 'sort',
  filterApply: 'filterApply',
  filterChanged: 'filterChanged',
  filterReset: 'filterReset',
  checkboxSelectAll: 'checkboxSelectAll',
  checkboxSelect: 'checkboxSelect',
  checkboxUnselectAll: 'checkboxUnselectAll',
} as const

type TdCheckboxProps<Row extends RowItemBase> = {
  checkboxId: string
  checkedMap: Record<string, true>
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void
  column: AppTableColumn<Row>
}
function TdCheckbox<Row extends RowItemBase>({
  checkboxId,
  checkedMap,
  onCheckboxChange,
  column,
}: TdCheckboxProps<Row>) {
  const handleTdClick: MouseEventHandler<HTMLTableCellElement> = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  return (
    <td
      data-row-id={checkboxId}
      style={{
        ...(column.width ? {width: column.width, maxWidth: column.maxWidth} : {}),
      }}
      title="Checkbox"
      onClick={handleTdClick}
    >
      <CheckboxCellContent checked={checkedMap[checkboxId] != null} onChange={onCheckboxChange} />
    </td>
  )
}

function getTableRowContent<Row extends RowItemBase, Tab extends number = number>(
  columns: AppTableColumn<Row>[],
  row: Row,
  getCheckboxRowId: AppTableProps<Row, Tab>['getCheckboxRowId'],
  rowId: ReturnType<GetIdFn<Row>>,
  checkedMap: Record<string, true>,
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void,
  components: AppTableProps<Row, Tab>['components'],
  noSideBorders: boolean
) {
  return columns.map((column) => {
    const columnKey = getColumnKey(column)
    const cellValue = renderCell(row, column)

    if (column.isCheckbox) {
      return (
        <TdCheckbox
          key={`td-checkbox-${rowId}`}
          checkboxId={getCheckboxRowId(row)}
          checkedMap={checkedMap}
          column={column}
          onCheckboxChange={handleCheckboxChange}
        />
      )
    }

    return (
      <DataGridTable.Cell
        {...omitWhenNotNull(components?.Cell, 'style')}
        key={`cell-${rowId}-${columnKey}`}
        noSideBorders={noSideBorders}
        preventRowClickHandler={column.preventRowClickHandler}
        style={{
          ...(column.width ? {width: column.width, maxWidth: column.maxWidth} : {}),
          ...(components?.Cell?.style ?? {}),
          ...(column.bodyCellStyle ?? {}),
        }}
      >
        {cellValue}
      </DataGridTable.Cell>
    )
  })
}

type GroupedRowsProps<Row extends RowItemBase, Tab extends number = number> = {
  columns: AppTableProps<Row, Tab>['columns']
  data: AppTableProps<Row, Tab>['data']
  getCheckboxRowId: AppTableProps<Row, Tab>['getCheckboxRowId']
  getId: AppTableProps<Row, Tab>['getId']
  components: AppTableProps<Row, Tab>['components']
  checkedMap: Record<string, true>
  groupBy: Required<AppTableProps<Row, Tab>>['groupBy']
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void
  noSideBorders: AppTableProps<Row, Tab>['noSideBorders']
  onRowClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, row: Row) => void
}

type GroupedRowItemProps<Row extends RowItemBase, Tab extends number = number> = {
  row: Row
} & Omit<GroupedRowsProps<Row, Tab>, 'data' | 'groupBy'>

function GroupedRowItem<Row extends RowItemBase, Tab extends number = number>({
  columns,
  row,
  components,
  getCheckboxRowId,
  getId,
  checkedMap,
  handleCheckboxChange,
  noSideBorders,
  onRowClick,
}: GroupedRowItemProps<Row, Tab>) {
  const rowId = getId(row)

  const handleRowClick: MouseEventHandler<HTMLTableRowElement> = useCallback(
    (e) => {
      onRowClick(e, row)
    },
    [row, onRowClick]
  )

  return (
    <DataGridRow {...(components?.Row ?? {})} key={`row-${rowId}`} onClick={handleRowClick}>
      {getTableRowContent(
        columns,
        row,
        getCheckboxRowId,
        rowId,
        checkedMap,
        handleCheckboxChange,
        components,
        noSideBorders
      )}
    </DataGridRow>
  )
}

function GroupedRows<Row extends RowItemBase, Tab extends number = number>({
  data,
  columns,
  groupBy: groupByProp,
  noSideBorders,
  getId,
  ...others
}: GroupedRowsProps<Row, Tab>) {
  const grouped = useMemo(() => groupBy(data, groupByProp.getIdentity), [data, groupByProp])

  const entries = Object.entries(grouped)

  // const sortedGroups = sortBy(entries, ([x]) => x, groupByProp.desc ? 'desc' : 'asc')

  return (
    <>
      {entries.map(([group, rows]) => (
        <tr key={`row-group-${group}`}>
          <td className="custom" colSpan={columns.length} style={{padding: 0}}>
            <RowAccordion
              defaultOpen={
                groupByProp.getDefaultOpen
                  ? groupByProp.getDefaultOpen(group, rows)
                  : entries.length === 1
              }
              name={group}
            >
              <DataGridTable
                styles={{
                  overflowX: 'auto',
                  width: '100%',
                  ...(noSideBorders && {borderLeft: 0, borderRight: 0}),
                }}
              >
                <tbody>
                  {rows.map((row) => (
                    <GroupedRowItem
                      key={`row-${getId(row)}`}
                      columns={columns}
                      getId={getId}
                      row={row}
                      {...others}
                      noSideBorders={noSideBorders}
                    />
                  ))}
                </tbody>
              </DataGridTable>
            </RowAccordion>
          </td>
        </tr>
      ))}
    </>
  )
}

type UncheckAllButtonProps = {
  checkedLength: number
  handleUncheckAllClick: () => void
}

function UncheckAllButton({checkedLength, handleUncheckAllClick}: UncheckAllButtonProps) {
  return (
    <>
      {checkedLength > 0 && (
        <FlexboxRow sx={{gap: '1px', alignItems: 'center'}}>
          <Typography variant="p2">Выбрано: {checkedLength}</Typography>
          <IconButton
            IconComponent={<CloseIcon />}
            size="small"
            variant="text"
            onClick={handleUncheckAllClick}
          />
        </FlexboxRow>
      )}
    </>
  )
}

type RenderHeadHelperProps<Row extends RowItemBase, Tab extends number = number> = {
  renderHead: AppTableProps<Row, Tab>['renderHead']
  checkedLength: number
  handleUncheckAllClick: () => void
}

function RenderHeadHelper<Row extends RowItemBase, Tab extends number = number>({
  renderHead,
  checkedLength,
  handleUncheckAllClick,
}: RenderHeadHelperProps<Row, Tab>) {
  const uncheckWithDividerFn = useCallback(
    (isLeftSide = false) => {
      const dividerNode = (checkedLength > 0 && <StyledDivider />) || <></>

      const uncheckAllNode = (
        <UncheckAllButton
          checkedLength={checkedLength}
          handleUncheckAllClick={handleUncheckAllClick}
        />
      )

      return (
        <UncheckAllButtonWrapper>
          {isLeftSide && dividerNode}
          {uncheckAllNode}
          {!isLeftSide && dividerNode}
        </UncheckAllButtonWrapper>
      )
    },
    [checkedLength, handleUncheckAllClick]
  )

  if (isFunction(renderHead)) {
    return renderHead({
      uncheckAllNode: (
        <UncheckAllButton
          checkedLength={checkedLength}
          handleUncheckAllClick={handleUncheckAllClick}
        />
      ),
      uncheckWithDividerFn,
    })
  } else {
    return renderHead
  }
}

const RenderHeadHelperMemo = genericMemo(RenderHeadHelper)

function Base<Row extends RowItemBase, Tab extends number = number>({
  columns,
  data: freshData,
  cachedData,
  components,
  loading,
  customLoader,
  getId,
  getCheckboxRowId,
  style,
  pagination,
  noSideBorders,
  tabs,
  onChange,
  customEmpty,
  error,
  customErrorStub,
  formState,
  checkedRows,
  renderHead,
  groupBy: groupByProp,
  patchFilterCollectorColumns,
  onRowClick: onRowClickProp,
  sort = [],
}: AppTableProps<Row, Tab>) {
  const data = loading ? cachedData : freshData

  checkedRows ??= []

  const theadDimensions = useDimensions<HTMLTableSectionElement>()

  useEffect(() => {
    validateColumns(columns)
  }, [columns])

  const composedBackObj = useRef<AppTableState<Row, Tab>>(
    mutateTableState({}, pagination, formState, tabs, sort, checkedRows)
  )

  useEffect(() => {
    mutateTableState(composedBackObj.current, pagination, formState, tabs, sort, checkedRows)
  }, [formState, pagination, tabs, sort, checkedRows])

  const hasAnyFilterableColumns = useMemo(
    () => patchFilterCollectorColumns != null || columns.some((x) => x.filterableOptions != null),
    [columns, patchFilterCollectorColumns]
  )

  const hasCheckBoxColumn = useMemo(() => columns.some((x) => x.isCheckbox), [columns])

  const handleCurrentPageChanged: SetAction<number> = useCallback(
    (payload) => {
      if (composedBackObj.current.pages == null) {
        return
      }

      const currentPage = extractValueFromAction<number>(
        composedBackObj.current.pages.currentPage,
        payload
      )

      onChange?.(
        {
          ...composedBackObj.current,
          pages: {
            ...composedBackObj.current.pages,
            currentPage,
          },
        },
        eventTypes.pages
      )
    },
    [onChange]
  )

  const handleCurrentPageSizeChanged: SetAction<number> = useCallback(
    (payload) => {
      if (composedBackObj.current.pages == null) {
        return
      }

      const pageSize = extractValueFromAction<number>(
        composedBackObj.current.pages.pageSize,
        payload
      )

      onChange?.(
        {
          ...composedBackObj.current,
          pages: {
            currentPage: 1,
            pageSize,
          },
        },
        eventTypes.pages
      )
    },
    [onChange]
  )

  const handleTabChange: UncontrolledSegmentedControlsProps['onChange'] = useCallback(
    (tab) => {
      if (composedBackObj.current.currentTab == null) {
        return
      }

      onChange?.(
        {
          ...composedBackObj.current,
          currentTab: tab as Tab,
        },
        eventTypes.currentTab
      )
    },
    [onChange]
  )

  const handleFilterChange: MemoFilterCollectorProps<Row>['onChange'] = useCallback(
    (filterValues) => {
      onChange?.(
        {
          ...composedBackObj.current,
          filterValues,
        },
        eventTypes.filterChanged
      )
    },
    [onChange]
  )

  const handleFilterReset: MemoFilterCollectorProps<Row>['onReset'] = useCallback(() => {
    onChange?.(
      {
        ...composedBackObj.current,
        filterValues: [],
      },
      eventTypes.filterReset
    )
  }, [onChange])

  const handleFilterApply: MemoFilterCollectorProps<Row>['onApply'] = useCallback(
    (filterValues) => {
      onChange?.(
        {
          ...composedBackObj.current,
          filterValues,
        },
        eventTypes.filterReset
      )
    },
    [onChange]
  )

  const handleSortChange: AppTableHeadCellProps['onSortChange'] = useCallback(
    (name, direction) => {
      onChange?.(
        {
          ...composedBackObj.current,
          sort: [
            {
              name: name as keyof Row,
              value: direction,
            },
          ],
          pages: {
            ...composedBackObj.current.pages,
            currentPage: 1,
          },
        },
        eventTypes.sort
      )
    },
    [onChange]
  )

  const handleCheckboxSelectAllClick: ChangeEventHandler<HTMLInputElement> = useCallback(() => {
    let checkedRows = []

    if (composedBackObj.current?.checkedRows?.length !== data.length) {
      checkedRows = data.map(getId)
    }

    onChange?.(
      {
        ...composedBackObj.current,
        checkedRows,
      },
      eventTypes.checkboxSelectAll
    )
  }, [data, getId, onChange])

  const handleUncheckAllClick = useCallback(() => {
    onChange?.(
      {
        ...composedBackObj.current,
        checkedRows: [],
      },
      eventTypes.checkboxSelect
    )
  }, [onChange])

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, row: Row) => {
      onRowClickProp?.(row)
    },
    [onRowClickProp]
  )

  const checkedMap = useMemo(
    () =>
      (checkedRows ?? []).reduce(
        (acc, row) => {
          acc[row] = true
          return acc
        },
        {} as Record<string, true>
      ),
    [checkedRows]
  )

  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.stopPropagation()

      const tdNode = e.currentTarget.closest('td')

      const dataRowId = tdNode.getAttribute('data-row-id')
      if (dataRowId == null) {
        const msg = 'Row id is unspecified for a checkbox cell'
        console.error(msg, e.currentTarget)
        throw new Error(msg)
      }

      const checked = checkedMap[dataRowId] != null

      let checkedRows = composedBackObj.current?.checkedRows ?? []

      if (checked) {
        checkedRows = checkedRows.filter((x) => x !== dataRowId)
      } else {
        checkedRows = [...checkedRows, dataRowId]
      }
      onChange?.(
        {
          ...composedBackObj.current,
          checkedRows,
        },
        eventTypes.checkboxSelectAll
      )
    },
    [checkedMap, onChange]
  )

  const showHeader = tabs != null || hasAnyFilterableColumns

  const hasAnyData = data.length > 0

  const showEmpty = !loading && !hasAnyData && !error

  if (hasCheckBoxColumn && renderHead == null) {
    renderHead = ({uncheckAllNode}) => (
      <FlexboxRow sx={{justifyContent: 'end', gap: '16px', flex: 1}}>{uncheckAllNode}</FlexboxRow>
    )
  }

  return (
    <Root style={style}>
      {showHeader && (
        <Header>
          <FlexboxRow
            {...components?.HeadFlexboxRow}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 'x4',
              px: 'x8',
              height: '48px',
              ...(components?.HeadFlexboxRow?.sx ?? {}),
            }}
          >
            <FlexboxRow sx={{gap: '8px'}}>
              {tabs != null && (
                <TabsWrap className={clsx({[dividerClass]: hasAnyFilterableColumns})}>
                  <UncontrolledSegmentedControls
                    items={tabs.list}
                    size={SegmentedControlSizes.small}
                    value={tabs.current}
                    onChange={handleTabChange}
                  />
                  {hasAnyFilterableColumns && <TabsDivider />}
                </TabsWrap>
              )}
              {hasAnyFilterableColumns && (
                <MemoFilterCollector
                  columns={columns}
                  filters={formState ?? []}
                  patchFilterCollectorColumns={patchFilterCollectorColumns}
                  onApply={handleFilterApply}
                  onChange={handleFilterChange}
                  onReset={handleFilterReset}
                  {...(components?.FilterCollector ?? {})}
                />
              )}
            </FlexboxRow>
            {renderHead && (
              <RenderHeadHelperMemo
                checkedLength={checkedRows.length}
                handleUncheckAllClick={handleUncheckAllClick}
                renderHead={renderHead}
              />
            )}
          </FlexboxRow>
        </Header>
      )}

      <TableWrapper>
        <LoaderOverlay
          active={loading}
          customLoader={customLoader}
          heightOffset={theadDimensions?.dimensions?.height ?? 0}
        />

        <DataGridTable
          styles={{
            // TODO: MOVE STYLES AWAY
            overflowX: 'auto',
            ...(noSideBorders && {borderLeft: 0, borderRight: 0}),
          }}
        >
          <DataGridTable.Head ref={theadDimensions.ref} {...(components?.Head ?? {})}>
            {columns.map((column) => {
              if (column.isCheckbox) {
                return (
                  <th
                    key={`th-checkbox`}
                    style={{
                      ...(column.width ? {width: column.width, maxWidth: column.maxWidth} : {}),
                      verticalAlign: 'middle',
                    }}
                    title={column.title}
                  >
                    <CheckboxCellContent
                      checked={checkedRows?.length === data.length && data.length !== 0}
                      onChange={handleCheckboxSelectAllClick}
                    />
                  </th>
                )
              }

              const sortName = column.dataIndex ?? column.key

              return (
                <AppTableHeadCell
                  {...omitWhenNotNull(components?.HeadCell, 'style')}
                  key={`th-${getColumnKey(column)}`}
                  name={sortName}
                  noSideBorders={noSideBorders}
                  style={{
                    ...(components?.HeadCell?.style ?? {}),
                    ...(column.headCellStyle ?? {}),
                    ...(column.width ? {width: column.width, maxWidth: column.maxWidth} : {}),
                  }}
                  title={column.titleNode ?? column.title}
                  {...(column.sortable?.enabled
                    ? {
                        sortValue: sort.find((sortItem) => sortItem.name === sortName)?.value,
                        sortName,
                        sortOptions: column.sortable?.text ?? SortTextKind.default,
                        onSortChange: handleSortChange,
                      }
                    : {})}
                />
              )
            })}
          </DataGridTable.Head>
          <DataGridTable.Section {...(components?.Section ?? {})}>
            {groupByProp && (
              <GroupedRows
                checkedMap={checkedMap}
                columns={columns}
                components={components}
                data={data}
                getCheckboxRowId={getCheckboxRowId}
                getId={getId}
                groupBy={groupByProp}
                handleCheckboxChange={handleCheckboxChange}
                noSideBorders={noSideBorders}
                onRowClick={handleRowClick}
              />
            )}
            {!groupByProp &&
              data.map((row) => {
                const rowId = getId(row)

                return (
                  <DataGridRow
                    {...(components?.Row ?? {})}
                    key={`row-${rowId}`}
                    onClick={(e) => handleRowClick(e, row)}
                  >
                    {getTableRowContent(
                      columns,
                      row,
                      getCheckboxRowId,
                      rowId,
                      checkedMap,
                      handleCheckboxChange,
                      components,
                      noSideBorders
                    )}
                  </DataGridRow>
                )
              })}
          </DataGridTable.Section>
        </DataGridTable>
      </TableWrapper>

      {error && (customErrorStub ? customErrorStub : <ErrorTableStub />)}

      {showEmpty && (customEmpty ? customEmpty : <EmptyTableStub />)}

      {pagination && (
        <TablePagination
          pageNumber={pagination.currentPage}
          pageSizeActual={data.length}
          pageSizeOptions={pagination.pageSizeOptions ?? [10, 20, 40, 60, 80, 100]}
          selectedPageSizeOption={pagination.pageSize}
          setPageNumber={handleCurrentPageChanged}
          setSelectedPageSizeOption={handleCurrentPageSizeChanged}
          style={{width: '100%'}}
          totalPages={pagination.totalPages}
          totalSize={pagination.totalCount}
        />
      )}
    </Root>
  )
}

export type UseAppStateReturnType<Row extends RowItemBase, Tab extends number = number> = {
  tableState: AppTableState<Row, Tab>
  tableProps: Partial<AppTableProps<Row, Tab>>
  setFormState: SetAction<AppTableState<Row, Tab>['filterValues']>
  setTableState: SetAction<Omit<AppTableState<Row, Tab>, 'filterValues'>>
}

const clean = <Row extends RowItemBase, Tab extends number = number>(x: AppTableState<Row, Tab>) =>
  omit(x, 'filterValues')

export function useAppTable<Row extends RowItemBase, Tab extends number = number>(
  initialState: AppTableState<Row, Tab>
): UseAppStateReturnType<Row, Tab> {
  const [formState, setFormState] = useState(initialState.filterValues ?? [])
  const [tableState, setTableState] = useState(initialState)

  const onChange: AppTableProps<Row, Tab>['onChange'] = useCallback((payload, event) => {
    if (payload == null) {
      return
    }

    setFormState(payload.filterValues)

    if (event === 'filterChanged') {
      if (isEmpty(payload.filterValues)) {
        setTableState({...payload, filterValues: []})
      }
      return
    }

    setTableState(payload)
  }, [])

  const setTableStateWrapper: UseAppStateReturnType<Row, Tab>['setTableState'] = useCallback(
    (actionArg) => {
      if (isFunction(actionArg)) {
        setTableState((prev) => clean(actionArg(prev)))
      } else {
        setTableState(clean(actionArg))
      }
    },
    []
  )

  const tableProps: Partial<AppTableProps<Row, Tab>> = {
    onChange,
    sort: tableState.sort,
    formState: formState,
    checkedRows: tableState.checkedRows,
  }

  return {
    tableState: tableState,
    setTableState: setTableStateWrapper,
    setFormState,
    tableProps,
  }
}

export const AppTable = genericMemo(Base)

export default AppTable
