import {useComparativeReportContext} from '../context'

import {ComponentProps, FC, PropsWithChildren, ReactNode} from 'react'
import {Typography} from 'ui-kit'

import {isEmpty} from 'lodash'

type Props = PropsWithChildren<{
  label?: string
  value: ReactNode
  typography?: ComponentProps<typeof Typography>
}>

export const EditableField: FC<Props> = ({label, value, children, typography}) => {
  const {isEdit} = useComparativeReportContext()

  if (isEdit) {
    return <>{children}</>
  }

  return (
    <Typography {...typography}>
      {label ? label + ' ' : ''} {isEmpty(value) ? '-' : value}
    </Typography>
  )
}
