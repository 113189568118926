import {FC, Fragment} from 'react'
import {Image, Text, View} from '@react-pdf/renderer'
import {GraphWithImageData} from '@root/features/reports/types'
import {combineStyles, Hr} from '@root/pdf-components'
import {styles} from './common'
import {isEmpty} from 'lodash'
import PdfTextUrlEnhancer from '@root/pdf-components/PdfTextUrlEnhancer'

type Props = {
  graphs: GraphWithImageData[]
}

export const StatisticsSection: FC<Props> = ({graphs = []}) => (
  <View style={{gap: '24px', flexDirection: 'column'}}>
    {graphs.map((x, i) => {
      const hasImage = !isEmpty(x.imageBuffer)
      const hasComment = !isEmpty(x.comment)

      const show = hasImage || hasComment

      if (show)
        return (
          <Fragment key={i}>
            <View style={{gap: '24px', flexDirection: 'column'}}>
              {hasImage && (
                <>
                  <Text style={styles.text}>{x.graphName}</Text>
                  <Image src={x.imageBuffer} />
                </>
              )}

              {hasComment && (
                <View style={{gap: '16px', flexDirection: 'column'}}>
                  <Text style={combineStyles(styles.text, {fontWeight: 500})}>Комментарий:</Text>
                  <Text style={styles.text}>
                    <PdfTextUrlEnhancer text={x.comment} />
                  </Text>
                </View>
              )}
            </View>
            {i === graphs.length - 1 ? null : <Hr />}
          </Fragment>
        )
    })}
  </View>
)
