import {Button, Tab, TabList, Tabs, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import CommonParamsSection from './CommonParamsSection'
import TechParamsSection from './TechParamsSection'
import ChangeDatesModal from './modal/ChangeDates'
import ToolParamsSection from './ToolParamsSection'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {Link, Typography} from 'ui-kit'
import {Task} from '@root/features/tasks/types'
import DisplayAssignee from './modal/DisplayAssignee'
import {match, P} from 'ts-pattern'
import ChangeAssigneeModal from './modal/ChangeAssignee'

const {colors} = baseTheme

const TaskParamsTabLabels = {
  common: 'Общие данные заявки',
  tech: 'Технические параметры',
  tools: 'Инструменты НТ',
  // reports: 'Отчёты',
  // history: 'История действий'
}

const EditModals = {
  dates: 'dates',
  assignee: 'assignee',
}

const StakeholdersField = ({task}: {task: Task}) => {
  const [modalShown, setModalShown] = useState(false)
  return (
    <>
      <FlexboxColumn sx={{width: 'fit-content', gap: '4px'}}>
        <Typography>Доступ к заявке</Typography>
        {match(task.usersWithAccess?.length)
          .with(
            P.when((count) => count === 0),
            () => <Typography style={{color: colors.grey[60]}}>Не выбраны</Typography>
          )
          .with(
            P.when((count) => count <= 2),
            () => (
              <Typography>
                {task.usersWithAccess.map((p) => p.email || p.name).join('; ')}
              </Typography>
            )
          )
          .otherwise(() => (
            <Typography>
              <span>
                {task.usersWithAccess
                  .slice(0, 2)
                  .map((p) => p.email || p.name)
                  .join('; ')}
              </span>
              <Link onClick={() => setModalShown(true)}>
                {' '}
                и ещё {task.usersWithAccess.length - 2}
              </Link>
            </Typography>
          ))}
        {/* {task.stakeholders?.length ? (
          <Typography>{task.stakeholders.map((p) => p.email || p.name).join('; ')}</Typography>
        ) : (
          <Typography style={{color: colors.grey[60]}}>Не выбраны</Typography>
        )} */}
      </FlexboxColumn>
      <DisplayAssignee open={modalShown} task={task} onClose={() => setModalShown(false)} />
    </>
  )
}

const TaskInfoTab = () => {
  const {taskId} = useParams()
  const {data: task} = useGetTaskByIdQuery(taskId)

  const {data: userInfo} = useGetUserinfoQuery()
  const costShown = userInfo.isAdmin || userInfo.isManager

  const [editModal, setEditModal] = useState(null)

  const [paramsTab, setParamsTab] = useState(TaskParamsTabLabels.common)
  let currentTabContent: React.ReactElement
  if (paramsTab === TaskParamsTabLabels.common) currentTabContent = <CommonParamsSection />
  else if (paramsTab === TaskParamsTabLabels.tech) currentTabContent = <TechParamsSection />
  else if (paramsTab === TaskParamsTabLabels.tools) currentTabContent = <ToolParamsSection />

  return (
    <>
      <FlexboxRow sx={{gap: '24px'}}>
        <Section sx={{mb: 'x0', width: '588px'}}>
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography variant="h3">Даты проведения и затраты НТ</Typography>
              {(userInfo?.isAdmin || userInfo?.isManager) && (
                <Button variant="text" onClick={() => setEditModal(EditModals.dates)}>
                  Редактировать
                </Button>
              )}
            </FlexboxRow>
            <FlexboxRow sx={{my: 'x0', justifyContent: 'space-between'}}>
              <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
                <Typography>Период проведения НТ</Typography>
                {!task.startAt && !task.finishedAt ? (
                  <Typography style={{color: colors.grey[60]}}>Ещё не назначено</Typography>
                ) : (
                  <Typography>
                    {task.startAt} - {task.finishedAt}
                  </Typography>
                )}
              </FlexboxColumn>
            </FlexboxRow>
            {costShown && (
              <FlexboxColumn sx={{gap: '4px'}}>
                <Typography>Стоимость</Typography>
                {task.workCost ? (
                  <Typography>{task.workCost}</Typography>
                ) : (
                  <Typography style={{color: colors.grey[60]}}>Ещё не назначено</Typography>
                )}
              </FlexboxColumn>
            )}
          </FlexboxColumn>
        </Section>
        <Section sx={{mb: 'x0', width: '588px'}}>
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography variant="h3">Работа с заявкой</Typography>
              {(userInfo?.isAdmin || userInfo?.isManager) && (
                <Button variant="text" onClick={() => setEditModal(EditModals.assignee)}>
                  Редактировать
                </Button>
              )}
            </FlexboxRow>
            <FlexboxColumn sx={{width: '500px', gap: '4px'}}>
              <Typography>Менеджер НТ</Typography>
              {task?.manager ? (
                <Typography>{task.manager.email}</Typography>
              ) : (
                <Typography style={{color: colors.grey[60]}}>Не выбран</Typography>
              )}
            </FlexboxColumn>
            <StakeholdersField task={task} />
          </FlexboxColumn>
        </Section>
      </FlexboxRow>
      <Section sx={{my: 'x12'}}>
        <Tabs value={paramsTab} onChange={(e, newTab: string) => setParamsTab(newTab)}>
          <TabList>
            <Tab label={TaskParamsTabLabels.common} value={TaskParamsTabLabels.common} />
            <Tab label={TaskParamsTabLabels.tech} value={TaskParamsTabLabels.tech} />
            <Tab label={TaskParamsTabLabels.tools} value={TaskParamsTabLabels.tools} />
          </TabList>
        </Tabs>
        {currentTabContent}
      </Section>
      <ChangeAssigneeModal
        open={editModal === EditModals.assignee}
        onClose={() => setEditModal(null)}
        onOpen={() => setEditModal(EditModals.assignee)}
      />
      <ChangeDatesModal open={editModal === EditModals.dates} onClose={() => setEditModal(null)} />
    </>
  )
}

export default TaskInfoTab
