const symbolsRegex = /([^\wА-ЯЁа-яё-])+/
const shapeRegex = /([\wА-ЯЁа-яё]+)-([\wА-ЯЁа-яё]+)-([\wА-ЯЁа-яё]+)/i

export const cppFieldRules = {
  required: 'Поле обязательно для заполнения',
  validate: {
    noSymbols: (value: string) => {
      if (symbolsRegex.test(value)) {
        return 'Значение не может содержать символы (например: %, № , ", ? и т.д.)'
      }
    },
    shape: (value: string) => {
      if (!shapeRegex.test(value)) {
        return 'Не является номером СПП. Пример: A-041114-05'
      }
    },
    minLength: (value: string) => {
      if ((value ?? '').length < 9) {
        return 'Минимум 9 символов'
      }
    },
  },
}
