import {AppTableState} from '@root/components/appTable'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {System} from '@root/features/systems/types'
import {components, SortRequestType} from '@root/openapi'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'

// TODO: Same code structure as from LaunchHistoryTable's fieldMapper. Could be refactored, but later

function sortValueMapper(tableFormat: ExtractConstValues<typeof sortDirections>): SortRequestType {
  switch (tableFormat) {
    case 'asc':
      return SortRequestType.ASC
    case 'desc':
      return SortRequestType.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof System | string): string {
  if (field === 'editor') {
    return 'editorEmail'
  }
  return field
}

function getExpressionForField(
  field: FilterCollectorFilter
): [string, ExtractConstValues<components['schemas']['UnionReportFilters']>] {
  const fieldName = field.name
  const value = field.value as any

  return [fieldName, value]
}

export function fieldMapper(
  tableState: AppTableState<System>
): components['schemas']['GetUnionReportRequest'] {
  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, value]) => !isEmpty(value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  const sortExpressions: GetLaunchesPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  return {
    filters: filterExpressions,
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 10,
  }
}
