import * as yup from 'yup'
import {uploaderStatuses} from 'ui-kit'

export const filesWithCommentValidator = (requiredMessage, impUploadFailedMessage) =>
  yup.array().of(
    yup
      .object({
        name: yup.string().required(requiredMessage),
        comment: yup.string().optional().nullable(),
        file: yup
          .array()
          .of(
            yup
              .object({
                objectURL: yup.string().required(impUploadFailedMessage),
                status: yup
                  .string()
                  .test('status', impUploadFailedMessage, (val) => val === uploaderStatuses.done),
              })
              .required(impUploadFailedMessage)
          )
          .min(1)
          .max(1)
          .required(impUploadFailedMessage),
      })
      .optional()
      .nullable()
  )
