import React, {
  forwardRef,
  useState,
  useContext,
  useMemo,
  useCallback,
  CSSProperties,
  MouseEventHandler,
} from 'react'
import clsx from 'clsx'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {
  ChevronRight as ChevronRightIcon,
  ChevronDown as ChevronDownIcon,
} from '@x5-react-uikit/icons'
import {
  cellAlignValues,
  cellSideComponentValues,
  DataGridBase,
  stateClasses,
} from '@x5-react-uikit/core'
import {ControlCell} from '../controls/ControlCell'
import {isNestedCell} from '../helpers'
import {StyledCell, StyledExpanderCell, cellClasses} from '../styles'
import {StyledButtonCell} from '../controls/styles'
import {TableContext} from '../context'

import type {DataGridCellProps, DataGridControlCellProps} from 'ui-kit'

const TableLabel = DataGridBase.Label

const tableExpanderValues = {
  close: 'close',
  open: 'open',
  none: 'none',
}

const getSideComponents = ({startComponent, endComponent, id, disabled, compacted, onClick}) => {
  let StartComponent = null
  let EndComponent = null

  if (startComponent?.type === cellSideComponentValues.icon) {
    const Icon = startComponent.component
    StartComponent = <Icon className={cellClasses.start} size={sizeTokenValues.small} />
  }

  if (startComponent?.type === cellSideComponentValues.label) {
    StartComponent = (
      <TableLabel className={cellClasses.start} color={startComponent.color}>
        {startComponent.value}
      </TableLabel>
    )
  }

  if (endComponent?.type === cellSideComponentValues.icon) {
    const Icon = endComponent.component
    EndComponent = <Icon className={cellClasses.end} size={sizeTokenValues.small} />
  }

  if (endComponent?.type === cellSideComponentValues.label) {
    EndComponent = (
      <TableLabel className={cellClasses.end} color={endComponent.color}>
        {endComponent.value}
      </TableLabel>
    )
  }

  if (endComponent?.type === cellSideComponentValues.button) {
    const ButtonIcon = endComponent.component
    EndComponent = (
      <StyledButtonCell
        aria-label={ButtonIcon.displayName.slice(0, -4)}
        className={cellClasses.end}
        data-id={`btn-${id}`}
        data-mode={compacted ? 'single' : 'default'}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation()
          onClick({...event, target: event.currentTarget})
        }}
      >
        <ButtonIcon size={sizeTokenValues.small} />
      </StyledButtonCell>
    )
  }

  return [StartComponent, EndComponent]
}

type Props = DataGridCellProps & {
  shouldFocusOnClick?: boolean
  noSideBorders?: boolean
  preventRowClickHandler?: boolean
} & {style: CSSProperties}

export const TableBodyCell = forwardRef<HTMLTableCellElement, Props>(
  (
    {
      id,
      mode,
      align = cellAlignValues.left,
      disabled,
      indented = true,
      error,
      children,
      startComponent = null,
      endComponent = null,
      qa: _qa,
      expander,
      depth,
      onClick,
      onCellChange,
      onExpanderClick,
      shouldFocusOnClick = true,
      noSideBorders,
      preventRowClickHandler,
      ...props
    },
    ref
  ) => {
    const hasContent = Boolean(children)
    const averaged =
      startComponent?.type === cellSideComponentValues.label ||
      endComponent?.type === cellSideComponentValues.label
    const compacted =
      !startComponent && !hasContent && endComponent?.type === cellSideComponentValues.button
    const context = useContext(TableContext)
    const [focused, setFocused] = useState(false)
    const nested = useMemo(() => isNestedCell(mode), [mode])
    const [StartComponent, EndComponent] = getSideComponents({
      startComponent,
      endComponent,
      id,
      disabled,
      compacted,
      onClick,
    })

    const handleNestedFocus = () => setFocused(true)
    const handleNestedBlur = () => setFocused(false)
    const handleClick: MouseEventHandler<HTMLTableCellElement> = (event) => {
      if (preventRowClickHandler) {
        event.stopPropagation()
      }
      if (shouldFocusOnClick) {
        const target = event.target as Element
        const cell = target.closest('[data-id]')
        context._setCurrentCellId((cell as any)?.dataset.id)
      }
      onClick?.(event)
    }

    const controlCellProps: DataGridControlCellProps = useMemo(
      () => ({
        cellId: id,
        mode,
        value: children as string,
        disabled,
        error,
        align,
        onFocus: handleNestedFocus,
        onBlur: handleNestedBlur,
        onChange: onCellChange,
      }),
      [id, mode, children, align, disabled, error, onCellChange]
    )

    const ExpandedIcon = expander === tableExpanderValues.open ? ChevronDownIcon : ChevronRightIcon

    const WrapCellContent = useCallback(
      ({children}) => {
        const noExpander = expander === tableExpanderValues.none
        const isExpanded = typeof depth === 'number'
        if (StartComponent || EndComponent || isExpanded) {
          return (
            <div className={cellClasses.compound}>
              {isExpanded && (
                <StyledExpanderCell
                  hasContent
                  depth={depth}
                  onClick={!noExpander ? onExpanderClick : null}
                >
                  {!noExpander && <ExpandedIcon size={sizeTokenValues.small} />}
                </StyledExpanderCell>
              )}
              {StartComponent}
              <div className={cellClasses.inner}>{children}</div>
              {EndComponent}
            </div>
          )
        }

        return children
      },

      [EndComponent, StartComponent, ExpandedIcon, expander, depth, onExpanderClick]
    )

    const cellContent = useMemo(() => {
      return (
        <WrapCellContent>
          <ControlCell {...controlCellProps}>{children}</ControlCell>
        </WrapCellContent>
      )
    }, [children, controlCellProps, WrapCellContent])

    return (
      <StyledCell
        {...(props as any)}
        ref={ref}
        align={align}
        averaged={averaged}
        className={clsx(
          disabled && stateClasses.isDisabled,
          focused && stateClasses.isFocused,
          noSideBorders && 'no-side-borders'
        )}
        compacted={compacted}
        data-id={id}
        disabled={disabled}
        error={error}
        hasContent={hasContent}
        indented={indented}
        nested={nested}
        shouldFocusOnClick={shouldFocusOnClick}
        tabIndex={0}
        onClick={handleClick}
      >
        {cellContent}
      </StyledCell>
    )
  }
)

export default TableBodyCell
