import {View, StyleSheet, Text} from '@react-pdf/renderer'
import type {ReactNode} from 'react'
import type {Style} from '@react-pdf/types'
import {notEmpty} from '@root/utils'
import {baseTheme} from '@root/components/kit'
import {withLineHeight} from './utils'
import {fontX5Ui} from '@root/pdf-components/constants'

type RowItemBase = object

type OnCellReturnType = {
  colSpan?: number
  rowSpan?: number
}

export type Column<Row = unknown> = {
  // Fallback when data object has no id
  key?: string
  dataIndex?: keyof Row & string
  title?: string
  width?: Style['width']
  onCell?: (index: number, row: Row) => OnCellReturnType
  renderCell?: (
    val: undefined | Row[keyof Row],
    row: Row,
    column: Omit<Column<Row>, 'render'>
  ) => ReactNode
}

export type TableProps<Row extends RowItemBase> = {
  data: Row[]
  columns: Column<Row>[]
  noSideBorders?: boolean
  renderCell?: (
    val: undefined | Row[keyof Row],
    row: Row,
    column: Omit<Column<Row>, 'render'>
  ) => ReactNode
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },

  cell: {
    padding: '12px',
    display: 'flex',
    alignContent: 'center',
  },

  thead: {
    flexDirection: 'row',
  },

  tbody: {},

  row: {
    flexDirection: 'row',
  },

  headCell: {
    border: `1px solid ${baseTheme.colors.grey[10]}`,
    borderBottom: 0,
  },

  bodyCell: {
    border: `1px solid ${baseTheme.colors.grey[10]}`,
  },

  headCellText: withLineHeight(14, 16, {
    fontFamily: fontX5Ui,
    fontWeight: 500,
    letterSpacing: '0.08px',
    color: baseTheme.colors.grey[100],
  }),

  bodyCellText: withLineHeight(14, 16, {
    fontFamily: fontX5Ui,
    fontWeight: 400,
    letterSpacing: '0.08px',
    color: baseTheme.colors.grey[100],
  }),
})

export function Table<Row extends RowItemBase>({
  columns,
  data,
  noSideBorders,
  renderCell,
}: TableProps<Row>) {
  const renderThead = columns.some((x) => notEmpty(x.title))

  return (
    <View style={styles.root}>
      {renderThead && (
        <View style={styles.thead}>
          {columns.map((column, index) => {
            const firstChild = index === 0
            const lastChild = index === columns.length - 1

            const disableBorderLeft = firstChild && noSideBorders
            const disableBorderRight = !lastChild || (noSideBorders && lastChild)

            return (
              <View
                key={`head-cell-${index}`}
                style={[
                  styles.cell,
                  styles.headCell,
                  column.width ? {width: column.width} : {flex: 1},
                  disableBorderLeft ? {borderLeft: 0} : {},
                  disableBorderRight ? {borderRight: 0} : {},
                ]}
              >
                <Text style={styles.headCellText}>{column.title ?? 'Empty'}</Text>
              </View>
            )
          })}
        </View>
      )}
      <View style={styles.tbody}>
        {data?.map((row, rowIndex) => {
          const lastRow = rowIndex === data.length - 1

          return (
            <View key={`row-${rowIndex}`} style={[styles.row]}>
              {columns.map((column, index) => {
                const firstChild = index === 0
                const lastCol = index === columns.length - 1
                const colKey = column.dataIndex ?? column.key

                const disableBorderLeft = firstChild && noSideBorders
                const disableBorderRight = !lastCol || (noSideBorders && lastCol)

                const renderFn = renderCell ?? column.renderCell

                return (
                  <View
                    key={`row-${rowIndex}-${colKey}`}
                    style={[
                      styles.cell,
                      styles.bodyCell,
                      column.width ? {width: column.width} : {flex: 1},
                      disableBorderLeft ? {borderLeft: 0} : {},
                      disableBorderRight ? {borderRight: 0} : {},
                      !lastRow ? {borderBottom: 0} : {},
                    ]}
                  >
                    <Text style={styles.bodyCellText}>
                      {renderFn
                        ? renderFn(row[column.dataIndex], row, column)
                        : row[column.dataIndex]}
                    </Text>
                  </View>
                )
              })}
            </View>
          )
        })}
      </View>
    </View>
  )
}
