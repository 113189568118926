import {isObject} from 'lodash'

export const LaunchesTags = {
  Launches: 'Launches',
} as const

export const ReportTags = {
  UnionReportsList: 'UnionReportsList',
  Reports: 'Reports',
  ReportById: 'ReportById',
  ComparativeReports: 'ComparativeReports',
  ComparativeById: 'ComparativeById',
} as const

export const Tags = {
  ReportTags,
  LaunchesTags,
} as const

type TagsType = typeof Tags

type DeepTagValues<T> = T extends Record<string, infer Value> ? DeepTagValues<Value> : T

function unwrapObject<Object = TagsType>(tagsObj: Object): DeepTagValues<Object>[] {
  return Object.entries(tagsObj).flatMap(([_, value]) =>
    isObject(value) ? unwrapObject(value) : value
  )
}

export const tagsArray = unwrapObject(Tags)
