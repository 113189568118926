import * as yup from 'yup'
import {isEmpty} from 'lodash'

export const urlValidator = (required: boolean = false) =>
  yup.string().test('is-url', 'Не является ссылкой', (input) => {
    try {
      if (isEmpty(input)) {
        return !required
      }

      new URL(input)
      return true
    } catch {
      return false
    }
  })
