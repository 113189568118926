import useNotify from '@root/hooks/useNotify'
import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'
import {useCallback} from 'react'
import useUpdateReport from './useUpdateReport'
import {match} from 'ts-pattern'

type Params = {
  updated: boolean
  reportId: string
  discardChanges: boolean
  isDirty: boolean
  getValues: () => any
}

export const useSaveReportBeforeTeardown = ({
  updated,
  reportId,
  isDirty,
  discardChanges,
  getValues,
}: Params) => {
  const {notifySuccess, notifyError} = useNotify()

  const {update} = useUpdateReport()
  const saveAction = useCallback(async () => {
    if (updated) {
      return
    }

    const updateData = getValues()
    try {
      await update(updateData, reportId)
      notifySuccess('Изменения сохранены')
    } catch (e) {
      console.error(e)
      notifyError('Не удалось автоматически сохранить отчет')
    }
  }, [getValues, notifyError, notifySuccess, reportId, update, updated])

  const shouldBlock = match(discardChanges)
    .with(true, () => false)
    .otherwise(() => isDirty && !updated)

  useSaveChangesBeforeTeardown({shouldBlock, saveAction})
}
