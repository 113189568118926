import {FilterCollectorUserCombobox} from '@root/features/users/FilterCollectorUserCombobox'
import {ComponentProps, FC, useCallback} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'

type FilterCollectorUserComboboxProps = ComponentProps<typeof FilterCollectorUserCombobox>
type OptionsMapFn = FilterCollectorUserComboboxProps['optionsMap']

const FilterCollectorEditorCombobox: FC<CustomFilterInputProps> = ({onChange, value, size}) => {
  const optionsMap: OptionsMapFn = useCallback(
    (items) =>
      items?.map((x) => ({
        name: x.email,
        value: x.id,
      })) || [],
    []
  )

  return (
    <FilterCollectorUserCombobox
      comboboxProps={{
        usePortal: true,
      }}
      filter={{
        pageNumber: 1,
        pageSize: 9999999,
        filters: {}, // TODO: LEFT UNDONE. DON'T KNOW HOW TO GET EDITORS
      }}
      optionsMap={optionsMap}
      size={size}
      value={value}
      onChange={onChange}
    />
  )
}

export default FilterCollectorEditorCombobox
