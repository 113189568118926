import {Tooltip, Typography, baseTheme} from '@x5-react-uikit/core'
import {Info} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {InputTypes, createEmptyListElement, getInputDefaultValue} from '../utils/formUtils'
import FormInputQuestionRadio from './FormInputQuestionRadio'
import {createLogger} from '@root/logger'

const log = createLogger('QuestionRow.jsx')

const {colors} = baseTheme

const getAnswersRadioGroup = (meta) => {
  const group = [
    {
      label: meta.true.label,
      value: true,
    },
    {
      label: meta.false.label,
      value: false,
    },
  ]
  if (meta.null)
    group.push({
      label: meta.null.label,
      value: null,
    })
  return group
}

const QuestionRow = ({fieldMeta, fieldIndex, fieldValue, stepIndex, form, rootRegister = ''}) => {
  const handleAnswerChange = (answer) => {
    const newAnswerMeta = fieldMeta.question[answer]
    newAnswerMeta.values.forEach((valueMeta, valueMetaIndex) => {
      let fieldValueByType = fieldValue.values[valueMeta.type]
      if (Array.isArray(fieldValueByType)) {
        if (fieldValueByType.find((inputValue) => inputValue.id === valueMeta.id)) return
      } else if (fieldValueByType) {
        return
      }

      let value
      let valueRegister = `${rootRegister ? rootRegister + '.' : ''}${stepIndex}.fields.${fieldIndex}.values.${valueMeta.type}`

      switch (valueMeta.type) {
        case InputTypes.comment:
          log.debug('InputTypes.comment')
          valueRegister = `${valueRegister}.value`
          value = getInputDefaultValue(valueMeta)
          break
        case InputTypes.text:
          log.debug('InputTypes.text')
          valueRegister = `${valueRegister}.${valueMetaIndex}`
          value = getInputDefaultValue(valueMeta)
          break
        case InputTypes.list:
          log.debug('InputTypes.list')
          valueRegister = `${valueRegister}.${valueMetaIndex}`
          value = createEmptyListElement(1, valueMeta)
          break
        default:
          throw new Error(`can not handle input with type "${valueMeta.type}"`)
      }

      log.debug('form.setValue(valueRegister, value)', valueRegister, value)
      form.setValue(valueRegister, value)
    })
  }

  if (!fieldMeta.question) return null

  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      <FlexboxRow sx={{gap: '4px'}}>
        <Typography variant="p1">{fieldMeta.question.label}</Typography>
        {fieldMeta.question.description && (
          <Tooltip content={fieldMeta.question.description}>
            <Info style={{color: colors.grey[60]}} />
          </Tooltip>
        )}
      </FlexboxRow>
      <FormInputQuestionRadio
        boxSx={{display: 'flex', gap: '32px', mb: 'x12'}}
        control={form.control}
        group={getAnswersRadioGroup(fieldMeta.question)}
        name={`${rootRegister}.${stepIndex}.fields.${fieldIndex}.question`}
        onSelect={handleAnswerChange}
      />
    </FlexboxColumn>
  )
}

export default QuestionRow
