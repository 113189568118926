import SpringAccordion, {ExpanderNodeProps} from '@root/components/SpringAccordion'
import {FC} from 'react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import {ChevronDown} from '@x5-react-uikit/icons'
import {Box, Typography} from 'ui-kit'

type Props = {
  name: string
  defaultOpen?: boolean
}

const iconClass = 'icon'
const activeClass = 'active'
const wrapClass = 'wrap'

const StyledExpanderContent = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  & > .${wrapClass} {
    display: flex;
    gap: 4px;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .${iconClass} {
    transition: 200ms transform ease;
    svg {
      display: block;
      path {
        fill: ${({theme}) => theme.colors.grey[70]};
      }
    }
  }

  &.${activeClass} {
    .${iconClass} {
      transform: rotate(180deg);
    }
  }
`

const ExpanderNode: (name: string) => FC<ExpanderNodeProps> =
  (name) =>
  ({open}) => {
    return (
      <StyledExpanderContent className={clsx({[activeClass]: open})}>
        <div className="wrap">
          <div className={iconClass}>
            <ChevronDown />
          </div>
          <Typography variant="h3">{name}</Typography>
        </div>
      </StyledExpanderContent>
    )
  }

export const Accordion: FC<Props> = ({children, name, defaultOpen}) => {
  return (
    <SpringAccordion
      defaultOpen={defaultOpen}
      expanderNode={ExpanderNode(name)}
      overflowElementStyle={{margin: '-3px', padding: '3px'}}
    >
      <Box sx={{pt: 'x12'}}>{children}</Box>
    </SpringAccordion>
  )
}
