import {axiosClient} from '@root/axiosClient'
import {match} from 'ts-pattern'
import {FileType} from '@root/features/fileUpload'
import {result} from '@root/core/result'
import {getErrorFromAxios, getErrorFromQuery} from '@root/core/serverResultParser'
import {AxiosError, isAxiosError, ResponseType} from 'axios'

const basePath = 'file'

function getUrlPathByFileType(fileType: FileType) {
  return match(fileType)
    .with(FileType.IMAGE, () => 'image')
    .with(FileType.ATTACHMENT, () => 'attachment')
    .otherwise(() => {
      throw new Error('FileType out of range')
    })
}

async function uploadTmpFile(fileType: FileType, file: string | Blob) {
  const form = new FormData()

  form.append('file', file)

  return axiosClient.post<string>(`/${basePath}/temp/${getUrlPathByFileType(fileType)}`, form)
}

async function getProtectedImage<TRes = Blob>(url: string, responseType: ResponseType = 'blob') {
  return axiosClient.get<TRes>(url, {responseType})
}

async function getProtectedImageResult<TRes = Blob>(
  url: string,
  responseType: ResponseType = 'blob'
) {
  try {
    const res = await getProtectedImage<TRes>(url, responseType)

    return result.ok(res.data)
  } catch (err) {
    if (isAxiosError(err)) {
      return getErrorFromAxios(err)
    } else {
      throw err
    }
  }
}

export const fileServiceApi = {
  uploadTmpFile,
  getProtectedImage,
  getProtectedImageResult,
}
