import {ComponentType, Fragment, PropsWithChildren, useMemo} from 'react'
import {enhanceTextWithUrl} from '@root/utils'
import {match} from 'ts-pattern'

type HrefRequirements = PropsWithChildren<{
  href?: string
}>

type TextRequirements = PropsWithChildren<unknown>

type Props<TLinkProps extends HrefRequirements, TTextProps extends TextRequirements> = {
  LinkComponent: ComponentType<TLinkProps>
  TextComponent: ComponentType<TTextProps>
  text: string
  linkProps?: Partial<Omit<TLinkProps, 'href' | 'children'>>
  textProps?: Partial<Omit<TTextProps, 'children'>>
}

type TextProps<T> = T extends undefined ? {text: string | undefined} : {text: string}

export type ImplProps<
  TLinkProps extends HrefRequirements,
  TTextProps extends TextRequirements,
  TText = string,
> = {
  linkProps?: Partial<TLinkProps>
  textProps?: Partial<TTextProps>
} & TextProps<TText>

export function AbstractTextUrlEnhancer<
  TLinkProps extends HrefRequirements,
  TTextProps extends TextRequirements,
>({LinkComponent, TextComponent, text, linkProps, textProps}: Props<TLinkProps, TTextProps>) {
  const res = useMemo(() => enhanceTextWithUrl(text), [text])

  return (
    <>
      {res.map((x, i) => (
        <Fragment key={i}>
          {match(x)
            .with({type: 'string'}, ({value}) => (
              <TextComponent {...(textProps as TTextProps)}>{value}</TextComponent>
            ))
            .with({type: 'url'}, ({url}) => (
              <LinkComponent {...(linkProps as TLinkProps)} href={url}>
                {url}
              </LinkComponent>
            ))
            .exhaustive()}
        </Fragment>
      ))}
    </>
  )
}
