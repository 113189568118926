import React, {FC, Fragment, MouseEvent, useCallback} from 'react'
import {
  Select,
  RenderDropDownContent,
  SelectProps,
  ListCheckedItem,
  ListItem,
  RenderOptionFn,
} from 'ui-kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {getTaskStatusDisplay, TaskStatuses} from '@root/constants'
import SelectList from 'ui-kit/src/Select/SelectList'
import {selectMenuClasses} from 'ui-kit/src/Select/styles'
import TaskStatusChip from '@root/pages/tasks/TaskListPage/components/TaskStatusChip'

type TaskStatus = ExtractConstValues<typeof TaskStatuses>

type Props = Partial<
  Omit<SelectProps, 'label' | 'options' | 'onChange' | 'onClearClick' | 'chipText'>
> & {
  onChange: (value: TaskStatus) => void
}

const statusToOption = (status: TaskStatus) => ({
  name: getTaskStatusDisplay(status),
  value: status,
})

const renderDropDownContent: RenderDropDownContent = ({
  options,
  value,
  multiple,
  noWrap,
  ...props
}) => {
  const defaultRenderOption: RenderOptionFn = (option, isActive, onClick) => {
    let element = null
    const commonProps = {
      disabled: option.disabled,
      adornment: option.icon,
      noWrap,
      onClick: (event: MouseEvent<HTMLElement>) => !option.disabled && onClick(event, option),
      text: <TaskStatusChip status={option.value as TaskStatus} />,
    }

    if (multiple) {
      element = <ListCheckedItem {...commonProps} checked={isActive} />
    } else {
      element = <ListItem {...commonProps} selected={isActive} />
    }

    return (
      <Fragment key={option.value}>
        {option.label ? <div className={selectMenuClasses.label}>{option.label}</div> : null}
        {element}
        {option.caption ? <div className={selectMenuClasses.caption}>{option.caption}</div> : null}
      </Fragment>
    )
  }

  return (
    <SelectList
      {...props}
      items={options}
      multiple={multiple}
      noWrap={noWrap}
      renderOption={defaultRenderOption}
      selected={value}
    />
  )
}

const options = Object.keys(TaskStatuses).map(statusToOption)

export const TaskStatusSelect: FC<Props> = ({onChange, value, ...props}) => {
  const handleChange: SelectProps['onChange'] = useCallback(
    (_e, value) => {
      onChange(value as TaskStatus)
    },
    [onChange]
  )

  const onClear = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Select
      chipText="Выбрано: "
      label="Статус заявки"
      options={options}
      renderDropDownContent={renderDropDownContent}
      value={value}
      onChange={handleChange}
      onClearClick={onClear}
      {...props}
    />
  )
}
