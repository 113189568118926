import {useCallback, useRef, useState} from 'react'
import {pdf} from '@react-pdf/renderer'
import {PdfReport} from '../pdf/PdfReport'
import {Report} from '../types'
import {saveFile} from '@root/utils'
import reportsApi from '@root/redux/api/report/reportsApi'
import {enhanceReportWithImages} from '@root/features/reports/reportStatisticsImages'
import {match} from 'ts-pattern'
import useNotify from '@root/hooks/useNotify'

export const useDownloadReportPdf = (report?: Report | null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [getStatisticsImageLinks] = reportsApi.useLazyGetReportStatisticsImageLinksQuery()
  const promise = useRef<Promise<Blob>>()
  const {notifyError} = useNotify()

  const download = useCallback(() => {
    if (report === null || promise.current != null) {
      return
    }

    setLoading(true)

    promise.current = enhanceReportWithImages(report, (id) =>
      getStatisticsImageLinks(id).unwrap()
    ).then((x) =>
      match(x)
        .returnType<Promise<Blob>>()
        .with({isOk: true}, ({value}) => pdf(<PdfReport report={value} />).toBlob())
        .with({isError: true}, () => {
          notifyError('Не удалось загрузить статистику для отчета')
          throw new Error('Images failed to fetch')
        })
        .exhaustive()
    )

    promise.current
      .then((blob) => {
        saveFile(blob, `Отчет ${report.name}`)
      })
      .catch((err) => {
        console.error(`Unexpected error while forming report pdf:`)
        console.error(err)
        notifyError('Не удалось сформировать pdf')
        setError(true)
      })
      .finally(() => {
        setLoading(false)
        promise.current = null
      })
  }, [getStatisticsImageLinks, notifyError, report])

  return {
    download,
    disabled: report === null,
    loading,
    error,
  }
}
