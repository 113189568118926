import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import {RequiredRule} from '@root/constants'
import EditCommentField from '@root/features/reports/components/EditCommentField'
import EditDynamicField from '@root/features/reports/components/EditDynamicField'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {getDefaultFilter as getSystemFilters} from '@root/features/systems/components/SystemsCombobox'
import {useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {Typography} from '@x5-react-uikit/core'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import {Button} from 'ui-kit'
import FormInputSystems from './FormInputSystems'

const DYNAMIC_FIELDS_REGISTER = 'reportData.dynamicFields'

const DataStep = ({form}) => {
  const {isLoading: isSystemsLoading} = useGetSystemsQuery({filter: getSystemFilters({})})

  const addDynamicField = () => {
    let number = 1
    const dynamicFields = form.getValues(DYNAMIC_FIELDS_REGISTER) || []
    if (dynamicFields?.length > 0) {
      number =
        dynamicFields.sort((fieldA, fieldB) => (fieldB.number > fieldA.number ? -1 : 1)).at(-1)
          .number + 1
    }
    dynamicFields.push({
      values: {comment: {value: ''}},
      label: '',
      number,
    })
    form.setValue(DYNAMIC_FIELDS_REGISTER, dynamicFields)
  }

  return (
    <Section sx={{display: 'flex', flexDirection: 'column'}}>
      <FlexboxColumn sx={{gap: '24px', mb: 'x12'}}>
        <Typography variant="h3">Основные данные конфигурации отчета</Typography>
        {isSystemsLoading ? (
          <LoaderFrame height="48px" />
        ) : (
          <>
            <FlexboxRow sx={{gap: '16px'}}>
              <FormInputText
                required
                control={form.control}
                label="Название конфигурации"
                name="name"
                rules={RequiredRule}
                width="568px"
              />
              <FormInputSystems
                // error={!form.getValues('systemId') && !isEmpty(form.formState.errors)}
                form={form}
              />
            </FlexboxRow>
          </>
        )}
        <Divider />
      </FlexboxColumn>
      {[
        ReportCommonFieldsMeta.goal.id,
        ReportCommonFieldsMeta.approach.id,
        ReportCommonFieldsMeta.limit.id,
      ].map((fieldId, fieldIndex) => (
        <FlexboxColumn key={fieldId} sx={{gap: '24px', mb: 'x12'}}>
          <EditCommentField
            fieldIndex={fieldIndex}
            form={form}
            meta={ReportCommonFieldsMeta[fieldId]}
          />
          <Divider />
        </FlexboxColumn>
      ))}

      {form.getValues(DYNAMIC_FIELDS_REGISTER) && (
        <FlexboxColumn sx={{gap: '24px', mb: 'x12'}}>
          {form.getValues(DYNAMIC_FIELDS_REGISTER).map((dynamicField) => (
            <EditDynamicField key={dynamicField.number} form={form} number={dynamicField.number} />
          ))}
        </FlexboxColumn>
      )}
      <Button
        startIcon={<AddIcon size="small" />}
        style={{width: 'fit-content'}}
        variant="text"
        onClick={addDynamicField}
      >
        Добавить новое текстовое поле
      </Button>
    </Section>
  )
}

export default DataStep
