import {createContext, useContext} from 'react'
import type {UseFormGetValues, UseFormRegister, UseFormSetValue} from 'react-hook-form'
import type {FormData} from './types'
import type {ComparativeReport} from '@root/features/reports/types'
import type {Control} from 'react-hook-form/dist/types/form'

export type ComparativeReportContext = {
  isEdit: boolean
  reportId: string
  comparativeReport: ComparativeReport
  register: UseFormRegister<FormData>
  control: Control<FormData, unknown>
  setValue: UseFormSetValue<FormData>
  getValues: UseFormGetValues<FormData>
}

export const comparativeReportContext = createContext<ComparativeReportContext | null>(null)
export const ComparativeReportContextProvider = comparativeReportContext.Provider

export const useComparativeReportContext = () => {
  const ctx = useContext(comparativeReportContext)

  if (ctx == null) {
    throw new Error('ComparativeReportContext was null!')
  }

  return ctx
}
