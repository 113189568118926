import {Roles} from '@root/constants'
import {FilterCollectorUserCombobox} from '@root/features/users/FilterCollectorUserCombobox'
import {FilterItemType} from '@root/openapi'
import {useCallback} from 'react'

const FilterCollectorTemplateCreatorCombobox = ({onChange, value, size}) => {
  const optionsMap = useCallback(
    (items) =>
      items?.map((x) => ({
        name: x.email,
        value: x.id,
      })) || [],
    []
  )

  return (
    <FilterCollectorUserCombobox
      comboboxProps={{
        usePortal: true,
      }}
      filter={{
        pageNumber: 1,
        pageSize: 9999999,
        filters: {
          role: {
            value: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER] as any,
            type: FilterItemType.EQUAL,
          },
        },
      }}
      optionsMap={optionsMap}
      size={size}
      value={value}
      onChange={onChange}
    />
  )
}

export default FilterCollectorTemplateCreatorCombobox
