import {FC, useEffect, useState} from 'react'
import {PDFViewer} from '@react-pdf/renderer'
import {PdfReport} from '@root/features/reports/pdf/PdfReport'
import {useParams} from 'react-router-dom'
import {reportsApi, useGetReportByIdQuery} from '@root/redux/api/report/reportsApi'
import {enhanceReportWithImages} from '@root/features/reports/reportStatisticsImages'
import {match} from 'ts-pattern'
import {ReportWithStatisticsImages} from '@root/features/reports/types'

export const ReportPdfPreviewPage: FC = () => {
  const {reportId} = useParams()
  const [reportWithImages, setReportWithImages] = useState<ReportWithStatisticsImages | null>(null)
  const {data: report} = useGetReportByIdQuery(reportId)
  const [getStatisticsImageLinks] = reportsApi.useLazyGetReportStatisticsImageLinksQuery()

  useEffect(() => {
    if (!report) {
      return
    }
    enhanceReportWithImages(report, (id) => getStatisticsImageLinks(id).unwrap()).then((x) =>
      match(x)
        .with({isOk: true}, ({value}) => setReportWithImages(value))
        .with({isError: true}, () => {
          throw new Error('Images failed to fetch')
        })
        .exhaustive()
    )
  }, [getStatisticsImageLinks, report])

  if (!reportWithImages) {
    return <>"Loading"</>
  }

  return (
    <PDFViewer width="100%">
      <PdfReport report={reportWithImages} />
    </PDFViewer>
  )
}
