import {FC, PropsWithChildren, useCallback, useEffect, useMemo} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {isArray, isEmpty, uniqBy} from 'lodash'
import useNotify from '@root/hooks/useNotify'
import {useGetReportsByIdQuery} from '@root/redux/api/report/reportsApi'
import {Report} from '@root/features/reports/types'
import {formatDateRuLocale} from '@root/utils'
import {match} from 'ts-pattern'
import {hasSameSystemResult, inBoundsResult} from '@root/features/reports/reportComparison'
import {Result, result} from '@root/core/result'
import Breadcrumbs from '@root/components/Breadcrumbs'
import {Box, Button, Loader, Typography} from 'ui-kit'
import Section from '@root/components/Section'
import styled from '@emotion/styled'
import RouterLink from '@root/components/RouterLink'
import {useCreateComparativeReportMutation} from '@root/redux/api/report/comparativeReportApi'
import {isServerError, matchServerErrorToString, ServerError} from '@root/core/serverResultParser'

enum ErrorType {
  INVALID_ARRAY,
  SERVER_ERROR,
  DIFFERENT_SYSTEMS,
}

type ValidationError =
  | {type: ErrorType.INVALID_ARRAY}
  | {type: ErrorType.SERVER_ERROR; body: ServerError}
  | {type: ErrorType.DIFFERENT_SYSTEMS}

type IdList = string[] | unknown | undefined

type TaskItem = {
  id: string
  number: string
}

type TestInfoItem = {
  id: string
  number: string
  finishedAt: string
}

function isValidIdArray(idArr: IdList): Result<null, ValidationError> {
  if (isEmpty(idArr) || !isArray(idArr)) {
    return result.error({type: ErrorType.INVALID_ARRAY})
  }

  return result.mapError(inBoundsResult(idArr), () => ({type: ErrorType.INVALID_ARRAY}))
}

function isServerErrorMapped(isError: boolean, error: unknown): Result<null, ValidationError> {
  return result.mapError(isServerError(isError, error), (err) => ({
    type: ErrorType.SERVER_ERROR,
    body: err,
  }))
}

function isValidReportsArray(reports: Report[] | undefined): Result<null, ValidationError> {
  if (reports == null) {
    return result.ok(null)
  }

  return result.mapError(hasSameSystemResult(reports), () => ({type: ErrorType.DIFFERENT_SYSTEMS}))
}

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const GreyTypography = styled(Typography)`
  color: ${({theme}) => theme.colors.grey[80]};
`

const HintTypography: FC<PropsWithChildren<unknown>> = ({children}) => (
  <GreyTypography variant="p3">{children}</GreyTypography>
)

const TextWithDelimiter = styled(Typography)`
  display: block;
  & > span {
    display: inline-block;
    position: relative;
    margin-right: 33px;

    &:after {
      background-color: ${({theme}) => theme.colors.grey[30]};
      position: absolute;
      display: block;
      content: '';
      height: 24px;
      width: 1px;
      right: -17px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
`

const DelimiterTypography: FC<PropsWithChildren<unknown>> = ({children}) => (
  <TextWithDelimiter variant="p1compact">{children}</TextWithDelimiter>
)

const ComparativeCreatePage: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {notifyError, notifySuccess} = useNotify()
  const idList: IdList = searchParams.getAll('id')

  const [createReport, {isLoading: isCreating}] = useCreateComparativeReportMutation()

  const handleCreateClick = useCallback(() => {
    createReport({comparableReportsIds: idList as string[]})
      .unwrap()
      .then((x) => {
        notifySuccess(
          `Сравнительный отчет №${x.reportNumber} сформирован`,
          'Вы сможете посмотреть его также в разделе отчетов или выгрузить в формате .pdf'
        )
        navigate('/reports')
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }, [createReport, idList, navigate, notifyError, notifySuccess])

  const {
    isError,
    error,
    data,
    isFetching: reportsLoading,
  } = useGetReportsByIdQuery(idList as string[])

  useEffect(() => {
    let validationResult = result.bind(isValidIdArray(idList), (_) =>
      isServerErrorMapped(isError, error)
    )
    validationResult = result.bind(validationResult, (_) => isValidReportsArray(data))

    if (!result.isError(validationResult)) {
      return
    }

    const errMessage = match(validationResult.value)
      .with({type: ErrorType.SERVER_ERROR}, ({body}) => matchServerErrorToString(body))
      .with({type: ErrorType.INVALID_ARRAY}, () => 'Invalid id array')
      .with({type: ErrorType.DIFFERENT_SYSTEMS}, () => 'Different systems')
      .otherwise(() => 'Unknown error')

    console.error('validation error', validationResult.value)
    notifyError(errMessage)
    navigate('/reports')
  }, [data, error, idList, isError, navigate, notifyError])

  const tasksSet = useMemo(() => {
    if (data == null) {
      return null
    }

    return uniqBy(
      data.map<TaskItem>((x) => ({id: x.taskId, number: x.taskNumber.toString()})),
      'id'
    )
  }, [data])

  const testInfoSet = useMemo(() => {
    if (data == null) {
      return null
    }

    return uniqBy(
      data.map<TestInfoItem>((x) => ({
        id: x.launchId,
        number: x.testNumber,
        finishedAt: x.testFinishedAt,
      })),
      'id'
    )
  }, [data])

  const system = data ? data[0].system : undefined

  return (
    <Box sx={{mx: 'x8'}}>
      <Breadcrumbs
        routes={[
          {to: '/tasks', label: 'Главная'},
          {to: '/reports', label: 'Отчеты портала'},
        ]}
      />
      <Typography variant="h2">
        Формирование сравнительного отчета нагрузочного тестирования
      </Typography>
      <Section
        sx={{
          position: 'relative',
          p: 'x12',
          my: 'x12',
          mx: 'x0',
          minWidth: 1200,
          minHeight: 336,
        }}
      >
        {reportsLoading ? (
          <StyledLoader />
        ) : (
          <>
            <Box sx={{mb: 'x12'}}>
              <Box sx={{mb: 'x4'}}>
                <Typography variant="h3">Вводные данные</Typography>
              </Box>
              <HintTypography>
                Сверьтесь с вводной информацией для формирования сравнительного отчета
              </HintTypography>
            </Box>

            <Box
              style={{display: 'flex', flexDirection: 'column', gap: '24px', marginBottom: '40px'}}
            >
              <Box>
                <Box sx={{mb: 'x4'}}>
                  <Typography variant="h4">Информационная система</Typography>
                </Box>
                <Typography variant="p1compact">
                  {system.systemTag ?? system.systemName} {system.cod && `КЕ ${system.cod}`}
                </Typography>
              </Box>

              <Box>
                <Box sx={{mb: 'x4'}}>
                  <Typography variant="h4">Заявки, использованные в сравнении</Typography>
                </Box>
                <DelimiterTypography>
                  {tasksSet!.map((x) => (
                    <span key={x.id}>#{x.number}</span>
                  ))}
                </DelimiterTypography>
              </Box>

              <Box>
                <Box sx={{mb: 'x4'}}>
                  <Typography variant="h4">Тестирования</Typography>
                </Box>
                <DelimiterTypography>
                  {testInfoSet!.map((x) => (
                    <span key={x.id}>
                      ID {x.number} от {formatDateRuLocale(x.finishedAt)}
                    </span>
                  ))}
                </DelimiterTypography>
              </Box>
            </Box>

            <Box style={{display: 'flex', gap: '16px'}}>
              <RouterLink noUnderline to="/reports">
                <Button variant="secondary">Отмена</Button>
              </RouterLink>
              <Button
                disabled={isCreating}
                loading={isCreating}
                variant="primary"
                onClick={handleCreateClick}
              >
                Сформировать отчет
              </Button>
            </Box>
          </>
        )}
      </Section>
    </Box>
  )
}

export default ComparativeCreatePage
