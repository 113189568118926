import React, {
  FC,
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  DragEventHandler,
  ChangeEventHandler,
  memo,
} from 'react'
import {Download as DownloadIcon} from '@x5-react-uikit/icons'
import {FieldCaption} from '../FieldCaption'
import {Link} from '../Link'
import {ChipProps} from '../Chip/types'
import {FileUploaderProps} from './types'
import {DefaultPreviews} from './DefaultPreviews'
import {StyledUploader, uploaderClasses} from './styles'
import {getQaAttribute} from '../utils'
import {
  formatToMb,
  getUniqueFilename,
  mimeTypes,
  normalizeFileList,
  pluralizeCountFile,
  prepareFileTypesToText,
} from './helpers'

const Component: FC<FileUploaderProps> = ({
  qa = 'uploader',
  items = [],
  maxFilesCount = 10,
  maxFileSize = 50,
  multiple = false,
  accept = ['jpeg', 'png', 'gif'],
  text,
  disabled: disabledProp = false,
  error: errorProp,
  textError: textErrorProp,
  maxLabelLength = 20,
  width = 480,
  renderPreviewsContent,
  onSelect,
  onRemove,
}) => {
  const getQA = getQaAttribute(qa)
  const [files, setFiles] = useState<File[]>([])
  const [disabled, setDisabled] = useState(disabledProp)
  const [error, setError] = useState(errorProp)
  const [textError, setTextError] = useState(textErrorProp)
  const [overed, setOvered] = useState(false)

  const countFile = multiple ? maxFilesCount : 1
  const textErrorFilesCount = `Загружать можно не более ${pluralizeCountFile(countFile)}`
  const inputAccept = accept ? accept.map((type) => mimeTypes[type]).join(',') : null
  const acceptMimeTypes = Object.entries(mimeTypes)
    .filter(([key]) => accept?.includes(key as FileUploaderProps['accept'][number]))
    .map(([, mime]) => mime)
  const validateMaxSize = useCallback(
    (file: File) => formatToMb(file.size) < maxFileSize,
    [maxFileSize]
  )
  const isExistingFile = (file: File) =>
    files.some(({name, type, size}) => name + type + size === file.name + file.type + file.size)
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    setFiles(normalizeFileList(items))
  }, [items])

  const extensionsWithMimeTypes: Set<string> = useMemo(
    () =>
      new Set([
        ...acceptMimeTypes.map((x) => x.toLowerCase()),
        ...accept.map((x) => x.toLowerCase()),
      ]),
    [accept, acceptMimeTypes]
  )

  useEffect(() => {
    setDisabled(disabledProp)
    if (errorProp) {
      setError(errorProp)
      setTextError(textErrorProp)
      return
    }
    const hasMaxFilesCountError = maxFilesCount !== null && items.length > countFile
    const hasFileSizeError = files.some((file) => !validateMaxSize(file))
    const hasFileTypesError =
      accept && files.some((file) => !extensionsWithMimeTypes.has(file.type.toLowerCase()))
    let textError = ''
    if (hasFileTypesError || hasMaxFilesCountError || hasFileSizeError) {
      setError(true)
      setDisabled(true)
    } else {
      setError(false)
      setDisabled(disabledProp !== undefined ? disabledProp : false)
    }
    if (hasFileTypesError) {
      textError += `Формат файла не поддерживается, разрешены только ${prepareFileTypesToText(accept)}. `
      setTextError(textError)
    }
    if (hasFileSizeError) {
      textError += `Размер файла не должен превышать ${maxFileSize} Mb. `
      setTextError(textError)
    }
    if (hasMaxFilesCountError) {
      textError += textErrorFilesCount + ' '
      setTextError(textError)
    }
  }, [
    items,
    errorProp,
    disabledProp,
    textErrorProp,
    maxFilesCount,
    countFile,
    files,
    accept,
    validateMaxSize,
    maxFileSize,
    textErrorFilesCount,
    extensionsWithMimeTypes,
  ])

  const handleDragEnter: DragEventHandler = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDragOver: DragEventHandler = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!disabled && !overed) {
      setOvered(true)
    }
  }

  const handleDragLeave: DragEventHandler = () => {
    if (!disabled && overed) setOvered(false)
  }

  const handleDrop: DragEventHandler = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (disabled) return
    const fileList = event.dataTransfer.files
    const canDrop = multiple || fileList?.length === 1
    if (canDrop) {
      handleFilesChange(fileList)
      setError(false)
      setTextError('')
    } else {
      setError(true)
      setTextError(textErrorFilesCount)
    }
  }

  const handleFilesChange = (fileList: FileList) => {
    const currentFiles = [...files]
    Array.from(fileList).forEach((file: File) => {
      if (!isExistingFile(file)) currentFiles.push(file)
    })

    setOvered(false)
    onSelect?.(currentFiles)
  }

  const handleInputFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    handleFilesChange(event.target.files)
  }

  const clearInputElement = () => (inputRef.current.value = '')

  const handleDelete: ChipProps['onDelete'] = (name) => {
    const removedFile = files.find((file) => getUniqueFilename(file) === name)
    clearInputElement()
    onRemove?.(removedFile)
  }

  const RenderPreviews = renderPreviewsContent ?? (
    <DefaultPreviews items={items} maxLabelLength={maxLabelLength} onDelete={handleDelete} />
  )

  const renderDefaultText = useMemo(
    () =>
      `Не более ${pluralizeCountFile(countFile)}, размером до ${maxFileSize} Мб${
        accept ? ', ' + prepareFileTypesToText(accept) : '.'
      }`,
    [accept, countFile, maxFileSize]
  )

  return (
    <StyledUploader
      cssWidth={width}
      data-qa={getQA()}
      disabled={disabled}
      error={error}
      overed={overed}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className={uploaderClasses.wrap}>
        <label className={uploaderClasses.label} data-qa={getQA('target')}>
          <input
            ref={inputRef}
            accept={inputAccept}
            className={uploaderClasses.input}
            data-qa={getQA('input')}
            disabled={disabled}
            multiple={multiple}
            type="file"
            onChange={handleInputFileChange}
          />
        </label>
        <DownloadIcon className={uploaderClasses.icon} />
        <div className={uploaderClasses.root}>
          Перетащите файл{multiple ? 'ы' : null} сюда или{' '}
          <Link
            className={uploaderClasses.link}
            component="span"
            disabled={disabled}
            onClick={() => inputRef.current.click()}
          >
            выберите на компьютере
          </Link>
        </div>
        <div className={uploaderClasses.info}>{text ? text : renderDefaultText}</div>
        <div className={uploaderClasses.preview} data-qa={getQA('files')}>
          {!overed || (Array.isArray(files) && !!files.length) ? RenderPreviews : null}
        </div>
      </div>
      {error && textError && <FieldCaption error={error} value={textError} width={width} />}
    </StyledUploader>
  )
}

export const FileUploader = memo(Component)

export default FileUploader
