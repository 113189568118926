import {Combobox} from '@root/components/kit'
import {FilterItemType} from '@root/openapi'
import {ChangeEvent, useMemo, useState} from 'react'
import {GetReportsListPayload} from '@root/redux/api/report/reportsApi'
import {useGetConfigurationsQuery} from '@root/redux/api/reportConfigurationsApi'
import {ReportConfiguration} from '@root/features/reportConfiguration/types'

const getDefaultFilter = (
  fieldFilters: GetReportsListPayload['filters'] | undefined
): GetReportsListPayload => {
  const filters = {pageNumber: 1, pageSize: 9999999, filters: {}} as GetReportsListPayload
  if (fieldFilters) filters.filters = fieldFilters
  return filters
}

export interface ConfigurationsComboboxProps {
  required?: boolean
  onChange: (e: MouseEvent | KeyboardEvent | ChangeEvent, s: string) => void
  onClear: () => void
  isError: boolean
  selectedConfId?: string
  width?: number | string
  caption?: string
  filters?: GetReportsListPayload['filters']
}

const ConfigurationsCombobox = ({
  onChange,
  onClear,
  selectedConfId,
  width = '400px',
  caption,
  isError,
  ...props
}: ConfigurationsComboboxProps) => {
  const [filter, setFilter] = useState<GetReportsListPayload>(getDefaultFilter(props.filters))

  const {data: configurations = {items: []}, isFetching: isConfigurationsFetching} =
    useGetConfigurationsQuery({filter})
  const options = useMemo(
    () => configurations.items.map((conf) => ({name: conf.name, value: conf.id})),
    [configurations]
  )

  const onChangeInput = (event) => {
    const confName = event.target.value
    const conf = (configurations.items as ReportConfiguration[]).find((c) => c.name === confName)
    onChange(event, conf?.id || null)
    setFilter(
      conf
        ? getDefaultFilter(props.filters)
        : (prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              name: {value: confName, type: FilterItemType.LIKE},
            },
          })
    )
  }

  return (
    <Combobox
      required
      stretch
      caption={caption}
      error={isError}
      label="Конфигурация отчёта"
      options={options}
      preMatching={isConfigurationsFetching}
      textError="Неккоректный формат конфигурации. Выберите значение из списка."
      value={selectedConfId}
      width={width}
      onChange={(e, confId) => {
        onChange(e as globalThis.MouseEvent, confId as string)
        setFilter(getDefaultFilter(props.filters))
      }}
      onClearClick={onClear}
      onFilterChange={onChangeInput}
    />
  )
}

export default ConfigurationsCombobox
