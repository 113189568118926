import {
  AppTable,
  AppTableColumn,
  AppTableTab,
  PatchFilterCollectorColumnsFn,
  SortTextKind,
  useAppTable,
} from '@root/components/appTable'
import {LineLimitedCell} from '@root/components/appTable/components/LineLimitedCell'
import {Button} from '@root/components/kit'
import {TaskStatuses} from '@root/constants'
import {ExtractConstValues} from '@root/core/helperTypes'
import useDownloadFile from '@root/hooks/useDownloadFile'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {Download as DownloadIcon} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'
import type {Task, TaskListItem} from '@root/features/tasks/types'
import {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useGetTasksByFilterQuery} from '@root/redux/api/tasksApi'
import {TaskRoleTabKind, TaskStatusTabKind} from '../types'
import FilterCollectorCreatorCombobox from './FilterCollectorCreatorCombobox'
import FilterCollectorManagerCombobox from './FilterCollectorManagerCombobox'
import FilterCollectorPerformerCombobox from './FilterCollectorPerformerCombobox'
import TaskStatusChip from './TaskStatusChip'
import {fieldMapper} from './fieldMapper'
import {FilterCollectorSystemsCombobox} from '@root/features/systems/components/FilterCollectorSystemsCombobox'
import {getTagOrName} from '@root/features/systems/utils'
import {match} from 'ts-pattern'
import {isEmpty} from 'lodash'
import FilterCollectorStatusCombobox from './FilterCollectorStatusCombobox'

const roleTabs: AppTableTab<TaskRoleTabKind>[] = [
  {
    label: 'Все',
    value: TaskRoleTabKind.all,
  },
  {
    label: 'Мои',
    value: TaskRoleTabKind.mine,
  },
  {
    label: 'Не назначенные',
    value: TaskRoleTabKind.notAssigned,
  },
]

const columns: AppTableColumn<TaskListItem>[] = [
  {
    dataIndex: 'number',
    title: '№ заявки',
    filterableOptions: {
      type: 'text',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.default,
    },
    width: '12.5%', // 150 / 1200
    valueRenderType: 'string',
    renderCell: (val) => <LineLimitedCell lines={1} value={`№${val}`} />,
  },
  {
    dataIndex: 'creator',
    title: 'Заказчик НТ',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorCreatorCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    width: '15.83%', // 190 / 1200
    renderCell: (val: Task['creator']) => <LineLimitedCell lines={1} value={val.email} />,
    bodyCellStyle: {
      padding: '12px',
    },
  },
  {
    dataIndex: 'performers',
    title: 'Специалист НТ',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorPerformerCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    width: '15.83%', // 190 / 1200
    renderCell: (val: TaskListItem['performers']) => (
      <LineLimitedCell lines={val?.length || 1} value={val?.map((p) => p.email).join('\n')} />
    ),
    bodyCellStyle: {
      whiteSpace: 'pre',
      padding: '12px',
    },
  },
  {
    dataIndex: 'manager',
    title: 'Менеджер НТ',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorManagerCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    width: '15.83%', // 190 / 1200
    renderCell: (val: Task['manager']) => <LineLimitedCell lines={1} value={val?.email} />,
    bodyCellStyle: {
      padding: '12px',
    },
  },
  {
    dataIndex: 'status',
    title: 'Статус',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorStatusCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.lifeCycle,
    },
    width: '16.66%', // 200 / 1200
    renderCell: (val: Task['status']) => (
      <TaskStatusChip status={val as ExtractConstValues<typeof TaskStatuses>} />
    ),
  },
  {
    dataIndex: 'startAt',
    title: 'Дата начала НТ',
    filterableOptions: {
      type: 'date',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
    width: '15.416%',
    renderCell: (val: Task['startAt']) => <LineLimitedCell lines={1} value={val} />,
  },
  {
    dataIndex: 'factFinishedDate',
    title: 'Дата завершения НТ',
    filterableOptions: {
      type: 'date',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
    width: '15.416%',
    renderCell: (val: Task['startAt']) => <LineLimitedCell lines={1} value={val} />,
  },
  {
    dataIndex: 'CPP',
    title: 'СПП',
    filterableOptions: {
      type: 'text',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    width: '13.3%',
    renderCell: (val: Task['CPP']) => <LineLimitedCell lines={1} value={val} />,
  },
]

function getId(task: Task) {
  if (task.id == null) {
    throw new Error('Id was null')
  }
  return task.id
}

function taskGroupByIdentity(task: Task) {
  return getTagOrName(task!.system)
}

const patchFilterCollectorColumns: PatchFilterCollectorColumnsFn = (columns) => {
  return [
    {
      name: 'Информационная система',
      value: 'system',
      type: 'custom',
      customInputComponent: FilterCollectorSystemsCombobox,
    },
    ...columns,
  ]
}

const TasksTable = ({statusTab}: {statusTab: TaskStatusTabKind}) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const systemIdFromParams = searchParams.get('systemId')

  const {tableState, tableProps} = useAppTable<TaskListItem>({
    pages: {
      currentPage: 1,
      pageSize: 20,
    },
    sort: [
      {
        name: 'number',
        value: 'desc',
      },
    ],
    filterValues: match(isEmpty(systemIdFromParams))
      .with(true, () => [])
      .with(false, () => [
        {
          name: 'system',
          value: systemIdFromParams,
        },
      ])
      .exhaustive(),
    currentTab: TaskRoleTabKind.all,
  })

  const {email: userEmail} = useSelector((state: any) => state.auth.user)
  const {data: userInfo} = useGetUserinfoQuery()
  const preparedFilter = useMemo(
    () => fieldMapper(tableState, statusTab, userInfo.role, userEmail),
    [tableState, statusTab, userEmail, userInfo.role]
  )

  const {
    data: cachedTasks,
    currentData: tasks,
    isFetching: isTasksLoading,
    isError: isTasksError,
  } = useGetTasksByFilterQuery(preparedFilter)

  const {download: handleDownloadTasks, loading: exportLoading} = useDownloadFile({
    path: '/v1/tasks/xlsx',
    body: preparedFilter,
    defaultName: 'tasks.xlsx',
  })

  const onRowClick = useCallback(
    (task: Task) => {
      let to = `/tasks/${task.id}`
      if (task.status === TaskStatuses.DRAFT) {
        to = `/tasks/draft/${task.id}/steps/1`
      } else if (task.status === TaskStatuses.WITHDRAWN) {
        to = `/tasks/withdrawn/${task.id}/steps/1`
      }
      navigate(to)
    },
    [navigate]
  )

  return (
    <AppTable
      noSideBorders
      cachedData={cachedTasks?.items ?? []}
      columns={columns}
      components={{
        Row: {
          style: {cursor: 'pointer'},
        },
        FilterCollector: {
          size: 'large',
        },
        HeadFlexboxRow: {
          sx: {
            py: 'x8',
            height: 'auto',
          },
        },
        Cell: {
          style: {
            padding: '12px',
          },
        },
        Head: {
          style: {
            verticalAlign: 'top',
            whiteSpace: 'unset',
          },
        },
        HeadCell: {
          style: {
            padding: '12px',
          },
        },
      }}
      data={tasks?.items ?? []}
      error={isTasksError}
      getCheckboxRowId={getId}
      getId={getId}
      groupBy={{
        getIdentity: taskGroupByIdentity,
        getDefaultOpen: () => true,
      }}
      loading={isTasksLoading}
      pagination={
        cachedTasks
          ? {
              currentPage: tableState.pages?.currentPage ?? 1,
              pageSize: tableState.pages?.pageSize ?? 20,
              totalCount: cachedTasks.totalElements,
              totalPages: cachedTasks.totalPages,
            }
          : undefined
      }
      patchFilterCollectorColumns={patchFilterCollectorColumns}
      renderHead={
        <FlexboxRow sx={{gap: '16px'}}>
          {(userInfo.isManager || userInfo.isAdmin) && (
            <Button
              disabled={exportLoading}
              loading={exportLoading}
              startIcon={<DownloadIcon />}
              variant={userInfo.isAdmin ? 'secondary' : 'primary'}
              onClick={handleDownloadTasks}
            >
              Выгрузить в excel
            </Button>
          )}
          {(userInfo.isBusiness || userInfo.isAdmin) && (
            <Button onClick={() => navigate('/tasks/create')}>Создать заявку</Button>
          )}
        </FlexboxRow>
      }
      style={{
        width: '1480px',
        maxWidth: '1480px',
        minHeight: 400,
      }}
      tabs={
        (userInfo.isManager || userInfo.isAdmin) && {
          current: tableState.currentTab,
          list: roleTabs,
        }
      }
      onRowClick={onRowClick}
      {...tableProps}
    />
  )
}

export default TasksTable
