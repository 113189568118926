import {Modal, Typography, baseTheme} from '@x5-react-uikit/core'
import {FC} from 'react'

const {typography} = baseTheme

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}

export const CancelEditModal: FC<Props> = ({isOpen, onSubmit, onClose}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onClose,
          side: 'right',
          text: 'Продолжить редактирование',
        },
        {
          onClick: onSubmit,
          side: 'right',
          text: 'Отменить редактирование',
        },
      ]}
      isOpen={isOpen}
      size="medium"
      title="Отменить редактирование отчёта?"
      onClose={onClose}
    >
      <Typography variant="p1">
        <span>Все внесенные изменения </span>
        <span style={typography.h4}>не будут сохранены. </span>
        <span>Отчёт останется с прежними данными. Вы сможете отредактировать отчёт повторно.</span>
      </Typography>
    </Modal>
  )
}
