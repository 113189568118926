import {Box, Button, Typography, baseTheme} from '@root/components/kit'
import Accordion from '@root/components/Accordion'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {Fragment, useEffect, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getFieldReadonlyComponent} from '../utils/componentUtils'
import {getBlankFields} from '../utils/formUtils'
import SectionInitiator from './SectionInitiator'
import WorkDatesSection from './WorkDatesSection'

const {colors} = baseTheme

const StyledAccordion = ({children, title, error, message, onReturnClick}) => (
  <Accordion
    headerSx={{
      py: 'x16',
      px: 'x12',
      alignItems: 'center',
      backgroundColor: 'white',
    }}
    titleEl={
      <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        <FlexboxColumn>
          <Typography style={{cursor: 'pointer', userSelect: 'none'}} variant="h3">
            {title}
          </Typography>
          {error && (
            <Typography style={{color: colors.error}} variant="p2">
              {error}
            </Typography>
          )}
          {message && (
            <Typography style={{color: colors.grey[60]}} variant="p2">
              {message}
            </Typography>
          )}
        </FlexboxColumn>
        <Button variant="text" onClick={onReturnClick}>
          Вернуться к пункту
        </Button>
      </FlexboxRow>
    }
  >
    {children}
  </Accordion>
)

const StepReview = ({form, meta}) => {
  const {taskId} = useParams()
  const navigate = useNavigate()
  const formValue = Object.values(form.getValues())

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {requiredBlankFields, notRequiredBlankFields} = useMemo(() => {
    const fields = getBlankFields(formValue, meta)
    return {
      requiredBlankFields: fields.filter((field) => field.required),
      notRequiredBlankFields: fields.filter((field) => !field.required),
    }
  }, [formValue, meta])
  const allBlankFields = [...requiredBlankFields, ...notRequiredBlankFields]

  return (
    <Box sx={{mb: 'x12'}}>
      <>
        <WorkDatesSection
          blankFields={!!requiredBlankFields.length}
          formDataForm={form}
          formDataMeta={meta}
        />
        <StyledAccordion
          title="Заказчик"
          onReturnClick={() => navigate(`/tasks/create/${taskId}/steps/1#initiator`)}
        >
          <Box sx={{backgroundColor: 'white', px: 'x12', pb: 'x12'}}>
            <SectionInitiator />
          </Box>
        </StyledAccordion>
        <Divider />
      </>
      {meta.fields
        .filter((fieldMeta) => fieldMeta.step === 1 || fieldMeta.step === 2)
        .map((fieldMeta, fieldMetaIndex) => {
          const hasSibling = fieldMetaIndex + 1 !== meta.fields.length
          const hasBlankInputs = allBlankFields.some((bf) => bf.id === fieldMeta.id)
          return (
            <Fragment key={fieldMetaIndex}>
              <StyledAccordion
                error={
                  hasBlankInputs &&
                  fieldMeta.required &&
                  'Есть незаполненные обязательные пункты. Без их заполнения отправка невозможна.'
                }
                message={
                  hasBlankInputs &&
                  !fieldMeta.required &&
                  'Есть незаполненные необязательные пункты. Их незаполненность не блокирует отправку заявки.'
                }
                title={fieldMeta.label}
                onReturnClick={() =>
                  navigate(`/tasks/create/${taskId}/steps/${fieldMeta.step}#${fieldMeta.id}`)
                }
              >
                <Box sx={{backgroundColor: 'white', px: 'x12', pb: 'x16'}}>
                  {getFieldReadonlyComponent({
                    fieldMeta,
                    form,
                    commentTitle: null,
                    fileBaseUrl: `/v1/tasks/${taskId}/form/files/${fieldMeta.id}`,
                  })}
                </Box>
              </StyledAccordion>
              {hasSibling && <Divider />}
            </Fragment>
          )
        })}
    </Box>
  )
}

export default StepReview
