import FlexboxColumn from '@root/components/FlexboxColumn'
import {Datepicker, Loader, Modal, Input} from '@root/components/kit'
import {DATE_FORMAT, TaskStatuses} from '@root/constants'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import useNotify from '@root/hooks/useNotify'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useGetTaskByIdQuery, useUpdateTaskMutation} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

dayjs.extend(customParseFormat)

const ChangeDatesModal = ({open, onClose}) => {
  const {taskId} = useParams()
  const {notifySuccess, notifyError, notifyWarning} = useNotify()

  const {data: userInfo} = useGetUserinfoQuery()

  const [updateTask, {isLoading: isTaskUpdating}] = useUpdateTaskMutation()
  const [ltPeriodDates, setLtPeridodDates] = useState(undefined)
  const [workCost, setWorkKost] = useState(undefined)

  const [inputSeed, setInputSeed] = useState(Math.random())

  const {data: task} = useGetTaskByIdQuery(taskId)
  useEffect(() => {
    if (task) {
      setLtPeridodDates([task.startAt, task.finishedAt])
      setWorkKost(task.workCost || null)
    }
  }, [task])

  const handleCancelEdit = () => {
    setLtPeridodDates([task.startAt, task.finishedAt])
    setInputSeed(Math.random())
    onClose()
  }

  const handleSubmit = () => {
    const update = {
      startAt: ltPeriodDates[0],
      finishedAt: ltPeriodDates[1],
      workCost,
    }

    updateTask({taskId, update})
      .unwrap()
      .then((_updated) => {
        notifySuccess('Изменения сохранены')
        onClose()
      })
      .catch((error) => {
        console.log(error)
        notifyError()
      })
  }

  const handleEditDate = (newValue) => {
    if (newValue[1]) {
      const startAt = dayjs(newValue[0], DATE_FORMAT)
      const finishAt = dayjs(newValue[1], DATE_FORMAT)
      if (!startAt.isBefore(finishAt, 'day')) {
        notifyWarning('Дата окончания работ не может быть раньше или равной дате начала работ')
        return
      }
    }
    setLtPeridodDates(newValue)
  }

  if (ltPeriodDates === undefined || workCost === undefined) return <Loader />

  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: handleCancelEdit,
          side: 'right',
          text: 'Отмена',
        },
        {
          onClick: handleSubmit,
          side: 'right',
          text: 'Сохранить изменения',
          props: {
            disabled: isTaskUpdating,
            loading: isTaskUpdating,
          },
        },
      ]}
      description="Отредактируйте поля и сохраните, чтобы изменения вступили в силу"
      isOpen={open}
      size="medium"
      title="Даты проведения и затраты НТ"
      onClose={handleCancelEdit}
    >
      <FlexboxColumn sx={{gap: '24px'}}>
        <Datepicker
          key={inputSeed}
          date={ltPeriodDates}
          delimiter=" — "
          DropdownProps={{updateOnOpen: true} as any}
          InputProps={{
            label: 'Период проведения НТ',
          }}
          width="100%"
          onChange={handleEditDate}
        />
        {(userInfo.isManager || userInfo.isAdmin) && (
          <Input
            required
            caption="Цена в рублях за выполнение нагрузочного тестирования"
            disabled={task.status !== TaskStatuses.IN_REVIEW}
            label="Стоимость работ"
            value={workCost?.toString()}
            width="100%"
            onChange={(e) => setWorkKost(e.target.value)}
          />
        )}
      </FlexboxColumn>
    </Modal>
  )
}

export default ChangeDatesModal
