import TmpFileUpload from '@root/components/inputs/TmpFileUpload'
import {Controller, ControllerProps, FieldPath, FieldValues} from 'react-hook-form'
import {FileType} from '@root/features/fileUpload'
import {genericMemo} from '@root/utils'

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  type: FileType
  multiple?: boolean
} & Pick<ControllerProps<TFieldValues>, 'control' | 'rules'>

function FormTmpFileUpload<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  type,
  multiple,
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState: {error: _error}}) => (
        <TmpFileUpload multiple={multiple} type={type} value={value} onChange={onChange} />
      )}
      rules={rules}
    />
  )
}

export default genericMemo(FormTmpFileUpload)
