import {FileType} from './types'
import {match} from 'ts-pattern'
import {mimeTypes} from 'ui-kit'

export function getAllowedExtensions(fileType: FileType) {
  return match(fileType)
    .returnType<Array<keyof typeof mimeTypes>>()
    .with(FileType.IMAGE, () => ['jpg', 'jpeg', 'png'])
    .with(FileType.ATTACHMENT, () => ['xls', 'xlsx', 'pdf', 'zip'])
    .otherwise(() => {
      throw new Error('Get allowed extensions out of range')
    })
}

export * from './types'
