import {Document, Page, View, Text} from '@react-pdf/renderer'
import {Section, Heading, Link, Title, Hr, combineStyles} from '@root/pdf-components'
import {ComparativeReport, ComparativeReportWithImages, Report} from '../types'
import {FC, Fragment, useMemo} from 'react'
import {formatDateRuLocale, formatDateTimeRuLocale} from '@root/utils'
import {ConfigurationTable} from './ConfigurationTable'
import {VariablesList} from './VariablesList'
import {ComparisonTable} from './ComparisonTable'
import {ReportAccordion} from './ReportAccordion'

import {type Anchor, styles, getCommonFieldsMap, getAnchorsWithDynamicFields} from './common'
import {isEmpty, uniq, uniqBy} from 'lodash'
import {match} from 'ts-pattern'
import {StatisticsSection} from '@root/features/reports/pdf/StatisticsSection'
import {ImagesSection} from '@root/features/reports/pdf/ImagesSection'
import {noDataStub} from '@root/pdf-components/shared'
import PdfTextUrlEnhancer from '@root/pdf-components/PdfTextUrlEnhancer'

type Props = {
  comparativeReport: ComparativeReportWithImages
}

enum AnchorsKind {
  ReportsToCompare = '0',
  TestingObjective = '-1',
  Conclusions = '-2',
  Statistics = 'Statistics',
  // TestingApproach = '-3',
  TestingLimitations = '-4',
  EnvironmentConfiguration = '-5',
  TestVariables = '-6',
}

const getAnchors = (dynamicAnchors: Anchor[], showStatistics: boolean): Anchor[] => [
  {
    text: 'Сравниваемые отчеты',
    href: AnchorsKind.ReportsToCompare,
  },
  {
    text: 'Цель тестирования',
    href: AnchorsKind.TestingObjective,
  },
  {
    text: 'Выводы',
    href: AnchorsKind.Conclusions,
  },
  ...(showStatistics
    ? [
        {
          text: 'Статистика',
          href: AnchorsKind.Statistics,
        },
      ]
    : []),
  // {
  //   text: 'Подход к тестированию',
  //   href: AnchorsKind.TestingApproach,
  // },
  {
    text: 'Ограничения тестирования',
    href: AnchorsKind.TestingLimitations,
  },
  {
    text: 'Конфигурация среды',
    href: AnchorsKind.EnvironmentConfiguration,
  },
  ...dynamicAnchors,

  {
    text: 'Переменные теста',
    href: AnchorsKind.TestVariables,
  },
]

function createReportMapById<T>(
  comparativeReport: ComparativeReport,
  mapper: (report: Report) => T
): Record<string, T> {
  return comparativeReport.reportsToCompare.reduce(
    (acc, next) => {
      acc[next.id] = mapper(next)
      return acc
    },
    {} as Record<string, T>
  )
}

export const PdfComparativeReport: FC<Props> = ({comparativeReport}) => {
  const commonFieldsByReportMap = useMemo(
    () => createReportMapById(comparativeReport, getCommonFieldsMap),
    [comparativeReport]
  )

  const dynamicFieldsMap = useMemo(() => {
    return (comparativeReport?.data?.dynamicFields ?? []).reduce(
      (acc, next) => {
        acc[next.label] = next
        return acc
      },
      {} as Record<string, ComparativeReport['data']['dynamicFields'][0]>
    )
  }, [comparativeReport])

  const statisticsHasImages = useMemo(
    () => comparativeReport?.data.graphs.some((x) => !isEmpty(x)),
    [comparativeReport?.data.graphs]
  )

  const hasScreenshots = useMemo(
    () => comparativeReport?.data.files.some((x) => !isEmpty(x)),
    [comparativeReport?.data.files]
  )

  const showStatistics = useMemo(() => {
    const hasComment = !isEmpty(comparativeReport?.data?.commonData?.statisticsComment)

    return hasComment || statisticsHasImages || hasScreenshots
  }, [comparativeReport?.data?.commonData?.statisticsComment, hasScreenshots, statisticsHasImages])

  const anchorsWithDynamicFields: Anchor[] = useMemo(
    () => getAnchorsWithDynamicFields(dynamicFieldsMap, getAnchors, showStatistics),
    [dynamicFieldsMap, showStatistics]
  )

  const formDateTimeFormatted = formatDateTimeRuLocale(comparativeReport.createdAt)

  const tasksSet = useMemo(
    () =>
      uniqBy(
        comparativeReport.reportsToCompare.map((x) => ({
          id: x.taskId,
          number: x.taskNumber.toString(),
        })),
        'id'
      ),
    [comparativeReport]
  )

  const launchTestSet = useMemo(
    () =>
      uniqBy(
        comparativeReport.reportsToCompare.map((x) => ({
          id: x.testNumber,
          date: formatDateRuLocale(x.testFinishedAt),
        })),
        'id'
      ),
    [comparativeReport]
  )

  const templateSet = useMemo(
    () => uniq(comparativeReport.reportsToCompare.map((x) => x.variablesTemplateName)),
    [comparativeReport]
  )

  const configurationSet = useMemo(
    () => uniq(comparativeReport.reportsToCompare.map((x) => x.reportConfigurationName)),
    [comparativeReport]
  )

  return (
    <Document>
      <Page wrap dpi={72} size={[1600]} style={styles.page}>
        <Section style={{gap: '16px'}}>
          <Heading
            subtitle={`Сформирован ${comparativeReport.creator.email} ${formDateTimeFormatted}`}
            title={comparativeReport.name}
          />

          <View style={styles.listItem}>
            <Text style={styles.text}>
              {match(launchTestSet.length)
                .with(1, () => `Тестирование`)
                .otherwise(() => 'Тестирования')}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.text}>
                {launchTestSet.map((x) => `ID ${x.id} от ${x.date}`).join(', ')}
              </Text>
            </Text>
          </View>

          <View style={styles.listItem}>
            <Text style={styles.text}>
              {match(templateSet.length)
                .with(1, () => `Шаблон переменных`)
                .otherwise(() => 'Шаблоны переменных')}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.text}>{templateSet.join(', ')}</Text>
            </Text>
          </View>

          <View style={styles.listItem}>
            <Text style={styles.text}>Информационная система</Text>
            <Text style={styles.text}>
              {comparativeReport.system.systemTag ?? comparativeReport.system.systemName}
            </Text>
          </View>

          <View style={styles.listItem}>
            <Text style={styles.text}>
              {match(configurationSet.length)
                .with(1, () => `Конфигурация`)
                .otherwise(() => 'Конфигурации')}
            </Text>
            <Text style={styles.text}>{configurationSet.join(', ')}</Text>
          </View>

          <View style={styles.listItem}>
            <Text style={styles.text}>
              {match(tasksSet.length)
                .with(1, () => `Заявка`)
                .otherwise(() => 'Заявки')}
            </Text>
            <Text style={styles.text}>{tasksSet.map((x) => '№ ' + x.number).join(', ')}</Text>
          </View>

          <View style={styles.listItem}>
            <Text style={styles.text}>Задача в Kaiten</Text>
            <Text style={styles.text}>
              {comparativeReport.kaitenLink ? (
                <Link href={comparativeReport.kaitenLink}>Перейти к связанным задачам</Link>
              ) : (
                noDataStub
              )}
            </Text>
          </View>
        </Section>

        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Оглавление</Title>
            </View>
            <View>
              {anchorsWithDynamicFields.map((x, i) => (
                <Link key={i} src={`#${x.href}`}>
                  • {x.text}
                </Link>
              ))}
            </View>
          </View>
        </Section>

        <ComparisonTable
          id={AnchorsKind.ReportsToCompare}
          reports={comparativeReport.reportsToCompare}
        />
        <Section style={{gap: '24px'}}>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Цель тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingObjective} style={styles.text}>
              <PdfTextUrlEnhancer text={comparativeReport.data?.commonData?.goal} />
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Выводы</Title>
            </View>
            <Text id={AnchorsKind.Conclusions} style={styles.text}>
              <PdfTextUrlEnhancer text={comparativeReport.data?.commonData?.conclusion} />
            </Text>
          </View>

          {showStatistics && (
            <View>
              <View id={AnchorsKind.Statistics} style={{marginBottom: '24px'}}>
                <Title level="h2">Статистика</Title>
              </View>
              <View style={{gap: '24px', flexDirection: 'column'}}>
                {statisticsHasImages && (
                  <StatisticsSection graphs={comparativeReport.data.graphs} />
                )}

                {hasScreenshots && <ImagesSection images={comparativeReport.data.files} />}

                <Hr />

                <View style={{gap: '16px', flexDirection: 'column'}}>
                  <Text style={combineStyles(styles.text, {fontWeight: 500})}>
                    Общий комментарий:
                  </Text>
                  <Text style={styles.text}>
                    <PdfTextUrlEnhancer
                      text={comparativeReport.data?.commonData?.statisticsComment}
                    />
                  </Text>
                </View>
              </View>
            </View>
          )}

          <Hr />

          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Ограничения тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingLimitations} style={styles.text}>
              <PdfTextUrlEnhancer text={comparativeReport.data?.commonData?.limitations} />
            </Text>
          </View>

          <Hr />

          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Конфигурация среды</Title>
            </View>
            <View id={AnchorsKind.EnvironmentConfiguration}>
              <ReportAccordion reports={comparativeReport.reportsToCompare}>
                {(report) => (
                  <ConfigurationTable commonFieldsMap={commonFieldsByReportMap[report.id]} />
                )}
              </ReportAccordion>
            </View>
          </View>
          {Object.values(dynamicFieldsMap).length > 0 && <Hr />}
          {Object.values(dynamicFieldsMap).map((x, i) => (
            <Fragment key={`dynamic-section-${x.id}`}>
              <View>
                <View style={{marginBottom: '24px'}}>
                  <Title level="h2">{x.label}</Title>
                </View>
                <Text id={x.id} style={styles.text}>
                  <PdfTextUrlEnhancer text={x.values?.comment?.value} />
                </Text>
              </View>
              {Object.values(dynamicFieldsMap).length - 1 !== i && <Hr />}
            </Fragment>
          ))}
        </Section>
        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Переменные теста</Title>
            </View>

            <View id={AnchorsKind.TestVariables}>
              <ReportAccordion reports={comparativeReport.reportsToCompare}>
                {(report) => <VariablesList key={report.id} report={report} />}
              </ReportAccordion>
            </View>
          </View>
        </Section>
      </Page>
    </Document>
  )
}
