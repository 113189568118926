import {FC, Fragment, ReactNode} from 'react'
import {Report} from '../types'
import {View} from '@react-pdf/renderer'
import {Accordion} from '@root/pdf-components/Accordion'

type ReportSwitchProps = {
  reports: Report[]
  children: (report: Report) => ReactNode
  hide?: (x: Report) => boolean
}

export const ReportAccordion: FC<ReportSwitchProps> = ({reports, children, hide}) => {
  return (
    <View style={{gap: '24px'}}>
      {reports.map((x, i) =>
        hide?.(x) ? (
          <Fragment key={i}></Fragment>
        ) : (
          <Accordion key={x.id} text={`Отчет №${i + 1}`}>
            {children(x)}
          </Accordion>
        )
      )}
    </View>
  )
}
