import {View} from '@react-pdf/renderer'
import type {ComponentProps, FC} from 'react'
import {combineStyles} from '@root/pdf-components/utils'
import {omit} from 'lodash'

export const Section: FC<ComponentProps<typeof View>> = ({children, ...other}) => {
  return (
    <View
      {...omit(other, 'style')}
      style={combineStyles(
        {
          paddingHorizontal: '39px',
          paddingVertical: '24px',
          backgroundColor: '#fff',
          borderRadius: '8px',
        },
        other.style
      )}
    >
      {children}
    </View>
  )
}
