import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'
import {emptyApi} from './emptyApi'
import {LaunchesTags} from './tags'

const basePath = (systemId: string, taskId: string) =>
  `/systems/${systemId}/tasks/${taskId}/launches`

type SystemIdTaskId = {
  systemId: string
  taskId: string
}

type LaunchUrl = `/v1/systems/{systemId}/tasks/{taskId}/launches`

export type GetLaunchesResponse = OpenApiResponseType<`${LaunchUrl}/list`, 'post'>
export type GetLaunchByIdResponse = OpenApiResponseType<`${LaunchUrl}/{id}`>
export type GetLastLaunchResponse = OpenApiResponseType<`${LaunchUrl}/last`>
export type StopLaunchResponse = OpenApiResponseType<`${LaunchUrl}/stop`, 'post'>
export type StartLaunchResponse = OpenApiResponseType<`${LaunchUrl}/start`, 'post'>

export type GetLaunchesPayload = SystemIdTaskId & {
  filter: OpenApiRequestBody<`${LaunchUrl}/list`>
}
export type GetLaunchByIdPayload = SystemIdTaskId & {
  launchId: string
}
export type GetLastLaunchPayload = SystemIdTaskId
export type StartLaunchPayload = SystemIdTaskId
export type StopLaunchPayload = SystemIdTaskId

export const launchesApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getLaunches: build.query<GetLaunchesResponse, GetLaunchesPayload>({
      query: ({systemId, taskId, filter}) => ({
        url: `${basePath(systemId, taskId)}/list`,
        method: 'POST',
        body: filter,
      }),
      providesTags: [LaunchesTags.Launches],
    }),

    getLaunchById: build.query<GetLaunchByIdResponse, GetLaunchByIdPayload>({
      query: ({systemId, taskId, launchId}) => `${basePath(systemId, taskId)}/${launchId}`,
      providesTags: [LaunchesTags.Launches],
    }),

    getActiveLaunch: build.query<GetLastLaunchResponse, GetLastLaunchPayload>({
      query: ({systemId, taskId}) => `${basePath(systemId, taskId)}/last`,
      providesTags: [LaunchesTags.Launches],
    }),

    startLaunch: build.mutation<StartLaunchResponse, StartLaunchPayload>({
      query: ({systemId, taskId}) => ({
        url: `${basePath(systemId, taskId)}/start`,
        method: 'POST',
      }),
      invalidatesTags: [LaunchesTags.Launches],
    }),

    stopLaunch: build.mutation<StopLaunchResponse, StopLaunchPayload>({
      query: ({systemId, taskId}) => ({
        url: `${basePath(systemId, taskId)}/stop`,
        method: 'POST',
      }),
      invalidatesTags: [LaunchesTags.Launches],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetLaunchByIdQuery,
  useGetLaunchesQuery,
  useGetActiveLaunchQuery,
  useStartLaunchMutation,
  useStopLaunchMutation,
} = launchesApi

export default launchesApi
