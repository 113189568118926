import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'

const Tags = {
  Tasks: 'Tasks',
  TaskMetadata: 'TaskMetadata',
  Systems: 'Systems',
  TaskFormData: 'TaskFormData',
} as const

export type GetTasksByFilterResponse = OpenApiResponseType<'/v1/tasks/list', 'post'>
export type GetTasksByFilterPayload = OpenApiRequestBody<'/v1/tasks/list'>

export type GetTaskByIdResponse = OpenApiResponseType<'/v1/tasks/{taskId}'>

export type CreateTaskResponse = OpenApiResponseType<'/v1/tasks', 'post'>
export type CreateTaskPayload = OpenApiRequestBody<'/v1/tasks'>

export type UpdateTaskResponse = OpenApiResponseType<'/v1/tasks/{taskId}', 'patch'>
export type UpdateTaskPayload = {
  taskId: string
  update: OpenApiRequestBody<'/v1/tasks/{taskId}', 'patch'>
}

export type UpdateTaskStatusResponse = OpenApiResponseType<
  '/v1/tasks/{taskId}/change-state',
  'post'
>
export type UpdateTaskStatusPayload = {
  taskId: string
  updateData: OpenApiRequestBody<'/v1/tasks/{taskId}/change-state'>
}

export type DeleteTaskResponse = OpenApiResponseType<'/v1/tasks/{taskId}', 'delete'>
export type CopyTaskResponse = OpenApiResponseType<'/v1/tasks/{taskId}/copy', 'post'>

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  tagTypes: Object.values(Tags),
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getTasksByFilter: build.query<GetTasksByFilterResponse, GetTasksByFilterPayload>({
      query: (filter) => ({
        url: '/tasks/list',
        method: 'POST',
        body: filter,
      }),
      providesTags: [Tags.Tasks],
    }),

    getTaskById: build.query<GetTaskByIdResponse, string>({
      query: (taskId) => `/tasks/${taskId}`,
      providesTags: [Tags.Tasks],
    }),

    createTask: build.mutation<CreateTaskResponse, CreateTaskPayload>({
      query: (payload) => ({
        url: '/tasks',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Tasks]),
    }),

    updateTask: build.mutation<UpdateTaskResponse, UpdateTaskPayload>({
      query: ({taskId, update}) => ({
        url: `/tasks/${taskId}`,
        method: 'PATCH',
        body: update,
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Tasks]),
    }),

    updateTaskStatus: build.mutation<UpdateTaskStatusResponse, UpdateTaskStatusPayload>({
      query: ({taskId, updateData}) => ({
        url: `/tasks/${taskId}/change-state`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Tasks]),
    }),

    deleteTask: build.mutation<DeleteTaskResponse, string>({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Tasks]),
    }),

    copyTask: build.mutation<CopyTaskResponse, string>({
      query: (taskId) => ({
        url: `/tasks/${taskId}/copy`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => (error ? [] : [Tags.Tasks]),
    }),
  }),
})

export const {
  useGetTasksByFilterQuery,
  useGetTaskByIdQuery,
  useCopyTaskMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
  useDeleteTaskMutation,
} = tasksApi

export const invalidateTasksAction = tasksApi.util.invalidateTags([Tags.Tasks])
