export enum TaskRoleTabKind {
  all,
  mine,
  notAssigned,
}

export enum TaskStatusTabKind {
  active,
  draft,
  withdrawn,
}
