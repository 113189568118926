import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'
import {UserRolesInfo} from '@root/core/UserRolesInfo'
import {components} from '@root/openapi'

const tags = ['Users']

export type UserInfoResponse = OpenApiResponseType<'/v1/userinfo'>
export type GetUsersByIdResponse = OpenApiResponseType<'/v1/users/ids'>
export type GetAllRolesResponse = OpenApiResponseType<'/v1/users/roles'>

type GetList = '/v1/users/list'

export type UserFilterPayload = OpenApiRequestBody<GetList>
export type UserListResponse = OpenApiResponseType<GetList, 'post'>
type TransformedUserInfo = components['schemas']['UserResponse'] & {
  name: string
  value: string
}

export type TransformedUserInfoList = TransformedUserInfo[]

export type PatchUserPayload = {
  userId: string
  updateData: OpenApiRequestBody<'/v1/users/{userId}', 'patch'>
}

export type UpdateUserPayload = OpenApiResponseType<'/v1/users/{userId}', 'patch'>

export const userApi = createApi({
  reducerPath: 'user',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(null),
  endpoints: (build) => ({
    getAllRoles: build.query<GetAllRolesResponse, void>({query: () => '/v1/users/roles'}),

    getUserinfo: build.query<UserRolesInfo, void>({
      query: () => 'v1/userinfo',
      transformResponse: (response: UserInfoResponse, _arg) => new UserRolesInfo(response.role),
    }),

    getUsersByIds: build.query<GetUsersByIdResponse, string[]>({
      query: (ids) => ({
        url: 'v1/users/ids',
        method: 'POST',
        body: ids,
      }),
    }),

    // TODO: HAD TO USE THESE COMPLEX TYPES JUST BECAUSE OF THESE TRANSFORMATIONS
    getUsersByFilter: build.query<
      UserListResponse | TransformedUserInfoList | undefined,
      {filter: UserFilterPayload; comboboxOptions?: boolean}
    >({
      query: ({filter}) => ({
        url: 'v1/users/list',
        method: 'POST',
        body: filter,
      }),

      // TODO: MOVE TRANSFORMATIONS TO ANY OTHER PLACE
      transformResponse: (response: UserListResponse, _meta, arg) => {
        if (arg.comboboxOptions) {
          return response?.items?.map((user) => ({name: user.email, value: user.email}))
        }

        return response
      },

      providesTags: tags,
    }),

    updateUser: build.mutation<UpdateUserPayload, PatchUserPayload>({
      query: ({userId, updateData}) => ({
        url: `v1/users/${userId}`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),
  }),
})

export const {
  useGetAllRolesQuery,
  useGetUserinfoQuery,
  useGetUsersByIdsQuery,
  useGetUsersByFilterQuery,
  useUpdateUserMutation,
} = userApi
