import styled from '@emotion/styled'
import {Typography} from '@root/components/kit'
import SpringAccordion, {ExpanderNodeProps} from '@root/components/SpringAccordion'
import {baseTheme} from '@x5-react-uikit/core'
import {ChevronDown} from '@x5-react-uikit/icons'
import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  name: string
  defaultOpen: boolean
}

const StyledAccordion = styled(SpringAccordion)`
  //margin-top: -1px;
`

const iconClass = 'icon'
const activeClass = 'active'
const wrapClass = 'wrap'

const StyledExpanderContent = styled.div`
  box-sizing: border-box;
  background-color: ${baseTheme.colors.accent[10]};
  border-bottom: 1px solid ${baseTheme.colors.accent[20]};
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;

  & > .${wrapClass} {
    display: flex;
    gap: 4px;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .${iconClass} {
    transition: 200ms transform ease;
    svg {
      display: block;
    }
  }

  &.${activeClass} {
    .${iconClass} {
      transform: rotate(180deg);
    }
  }
`

const ExpanderNode: (name: string) => FC<ExpanderNodeProps> =
  (name) =>
  ({open}) => {
    return (
      <StyledExpanderContent className={clsx({[activeClass]: open})}>
        <div className="wrap">
          <div className={iconClass}>
            <ChevronDown size="small" />
          </div>
          <Typography variant="p2">{name}</Typography>
        </div>
      </StyledExpanderContent>
    )
  }

export const RowAccordion: FC<Props> = ({children, name, defaultOpen}) => {
  return (
    <StyledAccordion defaultOpen={defaultOpen} expanderNode={ExpanderNode(name)}>
      {children}
    </StyledAccordion>
  )
}
