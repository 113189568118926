import Divider from '@root/components/Divider'
import TypographyGrey from '@root/components/typography/TypographyGrey'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {getDynamicFieldMeta} from '@root/features/reports/utils'
import {Typography, baseTheme} from '@x5-react-uikit/core'
import {Fragment, useMemo} from 'react'
import ConfDataAccordion from './ConfDataAccordion'

const {colors} = baseTheme

const getTypoByValue = (value, notFilledValue = 'Не заполнено') =>
  value ? (
    <Typography variant="p1">{value}</Typography>
  ) : (
    <TypographyGrey>{notFilledValue}</TypographyGrey>
  )

type FieldsMap = {
  [key in keyof typeof ReportCommonFieldsMeta]: any
}

const ReviewReportDataTab = ({form}) => {
  form.watch()

  const fields: FieldsMap = useMemo(() => {
    return Object.keys(ReportCommonFieldsMeta).reduce((fields, fieldId) => {
      fields[fieldId] = form
        .getValues('reportData.commonFields')
        .find((field) => field.id === fieldId)?.values?.comment?.value
      return fields
    }, {} as FieldsMap)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ConfDataAccordion navigateToEdit form={form} meta={ReportCommonFieldsMeta.goal}>
        {getTypoByValue(fields?.goal)}
      </ConfDataAccordion>
      <Divider />
      <ConfDataAccordion form={form} meta={ReportCommonFieldsMeta.conclusion}>
        <Typography style={{color: colors.grey[60]}} variant="p1">
          Заполняется путем редактирования сформированного отчета
        </Typography>
      </ConfDataAccordion>
      <Divider />
      <ConfDataAccordion navigateToEdit form={form} meta={ReportCommonFieldsMeta.approach}>
        {getTypoByValue(fields?.approach)}
      </ConfDataAccordion>
      <Divider />
      <ConfDataAccordion navigateToEdit form={form} meta={ReportCommonFieldsMeta.limit}>
        {getTypoByValue(fields?.limit)}
      </ConfDataAccordion>
      <Divider />
      <ConfDataAccordion form={form} meta={ReportCommonFieldsMeta.configuration}>
        <Typography style={{color: colors.grey[60]}} variant="p1">
          Автоматически заполняется информацией из методики при формировании отчета
        </Typography>
      </ConfDataAccordion>
      <Divider />
      {form.getValues('reportData.dynamicFields')?.map((field, fieldIndex) => {
        const value = field.values.comment.value
        const meta = getDynamicFieldMeta(field)
        // FIXME: обращаться к form.formState.errors
        const error =
          (!value || !meta.label) &&
          'Есть незаполненные обязательные пункты. Без их заполнения отправка невозможна.'
        meta.label ||= `Безымянное поле №${fieldIndex + 1}`
        const last = fieldIndex === form.getValues('reportData.dynamicFields').length - 1
        return (
          <Fragment key={fieldIndex}>
            <ConfDataAccordion navigateToEdit error={error} form={form} meta={meta as any}>
              {getTypoByValue(value)}
            </ConfDataAccordion>
            {!last && <Divider />}
          </Fragment>
        )
      })}
    </>
  )
}

export default ReviewReportDataTab
