import {ComparativeReport, Report, UnionReport} from '@root/features/reports/types'
import {match} from 'ts-pattern'
import {allEqual} from '@root/utils'
import {Result, result, aggregateResults} from '@root/core/result'

export enum CompareErrorReason {
  DifferentSystems,
  Min,
  Max,
  NotReport,
}

export type ValidationResult = Result<null, CompareErrorReason>

export function isComparativeReport(
  report: Report | ComparativeReport
): report is ComparativeReport {
  return (report as ComparativeReport).reportsToCompare != null
}

export function isUnionReport(report: Report | UnionReport): report is UnionReport {
  return (report as UnionReport).isComparative != null
}

export function isReport(report: Report | UnionReport): report is Report {
  return !isUnionReport(report)
}

export function isReportComparative(report: Report | UnionReport) {
  if (isReport(report)) {
    return false
  }

  if (isUnionReport(report)) {
    return report.isComparative
  }
}

export function isCommonReport(report: Report | UnionReport) {
  return !isReportComparative(report)
}

export function inBoundsResult(reports: UnionReport[] | Report[] | string[]): ValidationResult {
  if (reports.length < 2) {
    return result.error(CompareErrorReason.Min)
  }
  if (reports.length > 5) {
    return result.error(CompareErrorReason.Max)
  }

  return result.ok(null)
}

function hasSameSystem(reports: Report[] | UnionReport[]): boolean {
  const systemSelector = match<boolean>(true)
    .with(reports.every(isUnionReport), () => (x: UnionReport) => x.systemId)
    .with(reports.every(isReport), () => (x: Report) => x.system.systemId)
    .otherwise(() => {
      throw new Error('Invalid reports array.')
    })

  return allEqual(reports.map(systemSelector))
}

export function hasSameSystemResult(reports: Report[] | UnionReport[]): ValidationResult {
  if (hasSameSystem(reports)) {
    return result.ok(null)
  }

  return result.error(CompareErrorReason.DifferentSystems)
}

function areAllObjectsCommonReports(reports: UnionReport[] | Report[]): ValidationResult {
  if (reports.every(isCommonReport)) {
    return result.ok(null)
  }

  return result.error(CompareErrorReason.NotReport)
}

export function canCompare(reports: UnionReport[] | Report[]): Result<null, CompareErrorReason[]> {
  return result.map(
    aggregateResults([
      inBoundsResult(reports),
      hasSameSystemResult(reports),
      areAllObjectsCommonReports(reports),
    ]),
    () => null
  )
}
