import {forwardRef, HTMLProps, useEffect, useState} from 'react'
import {useIsMounted} from '@root/hooks/useIsMounted'
import {fileServiceApi} from '@root/api/fileServiceApi'
import {isEmpty} from 'lodash'

export type ProtectedImageProps = {
  src: string
  alt: string
  shouldFetch?: boolean
  onLoadingChange?: (loading: boolean) => void
  onError?: (error: Error) => void
} & HTMLProps<HTMLImageElement>

const ProtectedImage = forwardRef<HTMLImageElement, ProtectedImageProps>(
  ({src, alt, onLoadingChange, onError, shouldFetch = true, ...imgProps}, ref) => {
    const [blobUrl, setBlobUrl] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const isMountedRef = useIsMounted()

    useEffect(() => {
      if (!src) {
        return
      }

      setBlobUrl(null)
    }, [src])

    useEffect(() => {
      return () => {
        if (!blobUrl) {
          return
        }

        URL.revokeObjectURL(blobUrl)
      }
    }, [blobUrl])

    useEffect(() => {
      if (!(shouldFetch && isEmpty(blobUrl))) {
        return
      }

      setError(null)
      setLoading(true)
      fileServiceApi
        .getProtectedImage(src)
        .then((x) => {
          if (isMountedRef.current) {
            setBlobUrl(URL.createObjectURL(x.data))
          }
        })
        .catch((e: Error) => {
          if (isMountedRef.current) {
            setError(e)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setLoading(false)
          }
        })
    }, [blobUrl, isMountedRef, shouldFetch, src])

    useEffect(() => {
      onLoadingChange?.(loading)
    }, [loading, onLoadingChange])

    useEffect(() => {
      onError?.(error)
    }, [error, onError])

    return (
      <div style={{display: blobUrl ? 'block' : 'none'}}>
        <img {...imgProps} ref={ref} alt={alt} src={blobUrl} />
      </div>
    )
  }
)

export default ProtectedImage
