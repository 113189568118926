import {Input, Modal, Typography} from 'ui-kit'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {MODAL_INPUT_WIDTH_PX, INPUT_EMPTY_MESSAGE} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {ChangeEventHandler, FC, useCallback, useEffect, useState} from 'react'
import {useUpdateSystemMutation} from '@root/redux/api/systemsApi'
import type {System} from '@root/features/systems/types'

type Props = {
  isOpen: boolean
  system: System
  onCancel: () => void
}

const SystemTagChangeModal: FC<Props> = ({isOpen, system, onCancel}) => {
  const {notifyError, notifySuccess} = useNotify()
  const [tag, setTag] = useState(system?.tag)

  useEffect(() => {
    setTag(isOpen ? system?.tag : null)
  }, [isOpen, system?.tag])

  const [tagError, setTagError] = useState(null)

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const val = event.target.value
      if (val && tagError) setTagError(null)
      setTag(val)
    },
    [tagError]
  )

  const [updateSystem] = useUpdateSystemMutation()
  const handleSave = () => {
    if (!tag) {
      setTagError(INPUT_EMPTY_MESSAGE)
      return
    }
    updateSystem({systemId: system.id, updateData: {tag}})
      .unwrap()
      .then((_updated) => {
        notifySuccess('Изменения сохранены', 'Новый тег отображен в столбце со тегами ИС.')
        onCancel()
      })
      .catch((error) => {
        if (error.status === 409) setTagError('Тег должен быть уникальным')
        else {
          notifyError()
          console.log(error)
        }
      })
  }

  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onCancel,
          side: 'right',
          text: 'Закрыть',
        },
        {
          onClick: handleSave,
          side: 'right',
          text: 'Сохранить изменения',
        },
      ]}
      description="Тег ИС - короткое, понятное название информационной системы"
      isOpen={isOpen}
      size="medium"
      title="Изменение тега ИС"
      onClose={onCancel}
    >
      <FlexboxColumn sx={{gap: '24px'}}>
        <FlexboxRow sx={{gap: '40px'}}>
          <Typography style={{width: '210px'}} variant="h4">
            Информационная система
          </Typography>
          <Typography variant="p1">{system?.name || '-'}</Typography>
        </FlexboxRow>
        <Input
          key={system?.id}
          required
          error={!!tagError}
          label="Тег ИС"
          textError={tagError}
          value={tag || ''}
          width={MODAL_INPUT_WIDTH_PX}
          onChange={handleChangeInput}
        />
      </FlexboxColumn>
    </Modal>
  )
}

export default SystemTagChangeModal
