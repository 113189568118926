import {System} from '@root/features/systems/types'
import {getTagOrName} from '@root/features/systems/utils'
import {Typography} from '@x5-react-uikit/core'
import {Del} from '@x5-react-uikit/icons'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {Modal, ModalButton} from '@root/components/kit'
import VariablesList from '@root/components/VariablesList'
import {formatDateRuLocale} from 'utils'
import {Template} from '../../types'
import styled from '@emotion/styled'

interface TemplateViewModalProps {
  system: System
  template: Template | null
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
}

const StyledList = styled.dl`
  width: 100%;
  display: grid;
  grid-template-columns: 204px 1fr;
  justify-content: center;
  align-items: center;
  gap: 16px 32px;
  margin: 0;

  dt,
  dd {
    margin: 0;
    padding: 0;

    & > * {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

const TemplateViewModal = ({
  system,
  template,
  onClose,
  onEdit,
  onDelete,
}: TemplateViewModalProps) => {
  const buttons: ModalButton[] = [
    {
      onClick: onDelete,
      side: 'left' as const,
      props: {
        startIcon: <Del />,
      } as any,
      text: 'Удалить шаблон',
    },
    {
      onClick: onEdit,
      side: 'right' as const,
      text: 'Редактировать шаблон',
      variant: 'outlined',
    },
  ]

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={buttons}
        isOpen={!!template}
        size="medium"
        title={`Шаблон настроек: ${template?.name}`}
        variant="sideModal"
        onClose={onClose}
      >
        {template && (
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{gap: '32px'}}>
              <StyledList>
                <dt>
                  <Typography variant="h4">Информационная система</Typography>
                </dt>
                <dd>
                  <Typography variant="p2">{getTagOrName(system)}</Typography>
                </dd>
                <dt>
                  <Typography variant="h4">Автор шаблона</Typography>
                </dt>
                <dd>
                  <Typography variant="p2">
                    {template.creator
                      ? `${template.creator.name} (${template.creator.email})`
                      : '-'}
                  </Typography>
                </dd>
                <dt>
                  <Typography variant="h4">Дата создания</Typography>
                </dt>
                <dd>
                  <Typography variant="p2">
                    {template.createdDate && formatDateRuLocale(template.createdDate)}
                  </Typography>
                </dd>
              </StyledList>
            </FlexboxRow>
            <Divider />
            <VariablesList variables={template.variables} />
          </FlexboxColumn>
        )}
      </Modal>
    </>
  )
}

export default TemplateViewModal
