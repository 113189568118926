import React, {useMemo, cloneElement, forwardRef, ReactElement, NamedExoticComponent} from 'react'
import {Close as CloseIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues, placements} from '@x5-react-uikit/tokens'

import {buttonVariants} from '../Button'
import {InnerButton, innerButtonSizes, InnerButtonProps} from '../InnerButton'
import {Tooltip} from '../Tooltip'

import {StyledChip, classes} from './styles'
import {ChipProps} from './types'

export enum chipVariants {
  outlined = 'outlined',
  filled = 'filled',
}

export enum chipSizes {
  small = sizeTokenValues.small,
  large = sizeTokenValues.large,
}

const defaultShorteningFunc = (label: string, length: number) => `${label.slice(0, length - 3)}...`

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      name,
      label: labelProp,
      variant,
      size = chipSizes.large,
      checked,
      error,
      icon,
      maxLabelLength = 25,
      tooltip,
      shorteningFunc,
      onDelete,
      onChange,
    },
    ref
  ) => {
    const shortLabel = shorteningFunc || defaultShorteningFunc

    const label = useMemo(
      () => (labelProp.length > maxLabelLength ? shortLabel(labelProp, maxLabelLength) : labelProp),
      [maxLabelLength, labelProp, shortLabel]
    )

    const handleClick = () => onChange?.({name, checked: !checked})

    const handleRemoveClick: InnerButtonProps['onClick'] = (event) => {
      event.stopPropagation()
      onDelete?.(name)
    }

    const IconComponent = useMemo(() => {
      if (!icon) return null
      const iconSize =
        (icon as ReactElement<SVGSVGElement, NamedExoticComponent>).type.displayName === 'Loader'
          ? size === chipSizes.large
            ? '15px'
            : '12px'
          : '12px'

      return <span className={classes.icon}>{cloneElement(icon, {size: iconSize})}</span>
    }, [icon, size])

    const tooltipContent = tooltip ?? (labelProp.length > maxLabelLength ? labelProp : null)

    return (
      <Tooltip content={tooltipContent} placement={placements.bottomStart}>
        <StyledChip
          ref={ref}
          checked={checked}
          error={error}
          icon={icon}
          removable={Boolean(onDelete)}
          selectable={Boolean(onChange)}
          size={size}
          variant={variant}
          onClick={handleClick}
        >
          {IconComponent}
          <span className={classes.label}>{label}</span>
          {onDelete && (
            <InnerButton
              IconComponent={<CloseIcon size={sizeTokenValues.small} />}
              size={size === chipSizes.large ? innerButtonSizes.xsmall : innerButtonSizes.xxsmall}
              variant={buttonVariants.text}
              onClick={handleRemoveClick}
            />
          )}
        </StyledChip>
      </Tooltip>
    )
  }
)

Chip.displayName = 'Chip'

export default Chip
