import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'
import {PathsV1ReportsProjectionListPostParametersQueryGetReportType} from '@root/openapi'
import {emptyApi} from '../emptyApi'
import {ReportTags} from '../tags'

export type GetUnionReportsResponse = OpenApiResponseType<`/v1/reports/projection/list`, 'post'>

export type GetUnionReportsPayload = {
  getReportType: PathsV1ReportsProjectionListPostParametersQueryGetReportType
  requestBody: OpenApiRequestBody<`/v1/reports/projection/list`>
}

export const unionReportsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getUnionReports: build.query<GetUnionReportsResponse, GetUnionReportsPayload>({
      query: ({getReportType, requestBody}) => ({
        url: `/reports/projection/list`,
        method: 'POST',
        body: requestBody,
        params: {getReportType},
      }),
      providesTags: [ReportTags.UnionReportsList],
    }),
  }),
})

export const {useGetUnionReportsQuery} = unionReportsApi

export default unionReportsApi
