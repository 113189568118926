import type {FC} from 'react'
import VariablesList from '@root/components/VariablesList'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/report/reportsApi'

const LaunchVariablesTab: FC = () => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  return (
    <div style={{marginTop: '24px'}}>
      <VariablesList variables={report.variables} />
    </div>
  )
}

export default LaunchVariablesTab
