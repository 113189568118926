import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'
import {emptyApi} from '../emptyApi'
import {ReportTags} from '../tags'
import {components} from '@root/openapi'

export type CreateComparativeReportResponse = OpenApiResponseType<`/v1/reports/comparative`, 'post'>
export type UpdateComparativeReportResponse = OpenApiResponseType<
  `/v1/reports/comparative/{reportId}`,
  'post'
>
export type LockComparativeReportResponse = OpenApiResponseType<
  '/v1/reports/comparative/{reportId}/edit',
  'post'
>
export type UnlockComparativeReportResponse = OpenApiResponseType<
  '/v1/reports/comparative/{reportId}/unlock',
  'post'
>

export type DeleteComparativeReportResponse = OpenApiResponseType<
  '/v1/reports/comparative/{reportId}',
  'delete'
>

export type GetComparativeReportsByIdResponse = OpenApiResponseType<
  '/v1/reports/comparative/by-id',
  'post'
>

export type GetComparativeReportByIdResponse =
  OpenApiResponseType<'/v1/reports/comparative/{reportId}'>

export type CreateComparativeReportPayload = OpenApiRequestBody<`/v1/reports/comparative`>
export type UpdateComparativeReportPayload = {
  reportId: string
  body: OpenApiRequestBody<`/v1/reports/comparative/{reportId}`, 'patch'>
}

export type GetComparativeReportsByIdPayload = components['schemas']['IdListRequest']['ids']

export const comparativeReportApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createComparativeReport: build.mutation<
      CreateComparativeReportResponse,
      CreateComparativeReportPayload
    >({
      query: (body) => ({
        url: `/reports/comparative`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ReportTags.UnionReportsList],
    }),
    updateComparativeReport: build.mutation<
      UpdateComparativeReportResponse,
      UpdateComparativeReportPayload
    >({
      query: ({reportId, body}) => ({
        url: `/reports/comparative/${reportId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, {reportId}) => [
        {type: ReportTags.ComparativeById, id: reportId},
        ReportTags.ComparativeReports,
        ReportTags.UnionReportsList,
      ],
    }),
    getByIdComparativeReport: build.query<GetComparativeReportByIdResponse, string>({
      query: (reportId) => `/reports/comparative/${reportId}`,
      providesTags: (result) => (result ? [{type: ReportTags.ComparativeById, id: result.id}] : []),
    }),
    getComparativeReportsById: build.query<
      GetComparativeReportsByIdResponse,
      GetComparativeReportsByIdPayload
    >({
      query: (filter) => ({
        url: '/reports/comparative/by-id',
        method: 'POST',
        body: {ids: filter},
      }),
    }),
    lockComparative: build.mutation<LockComparativeReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/comparative/${reportId}/edit`,
        method: 'POST',
      }),
      invalidatesTags: [ReportTags.ComparativeReports, ReportTags.UnionReportsList],
    }),
    unlockComparative: build.mutation<UnlockComparativeReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/comparative/${reportId}/unlock`,
        method: 'POST',
      }),
      async onQueryStarted(reportId, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled
          // Дожидаемся выполнения запросов, чтобы инвалидация сработала
          await Promise.all(dispatch(emptyApi.util.getRunningQueriesThunk()))
          dispatch(emptyApi.util.invalidateTags([ReportTags.UnionReportsList, ReportTags.Reports]))
        } catch {
          console.warn('unable to invalidate queries')
        }
      },
      invalidatesTags: [ReportTags.ComparativeReports, ReportTags.UnionReportsList],
    }),
    deleteComparativeReport: build.mutation<DeleteComparativeReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/comparative/${reportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        {type: ReportTags.ComparativeById, id},
        ReportTags.ComparativeReports,
        ReportTags.UnionReportsList,
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateComparativeReportMutation,
  useGetByIdComparativeReportQuery,
  useDeleteComparativeReportMutation,
  useUpdateComparativeReportMutation,
  useLockComparativeMutation,
  useUnlockComparativeMutation,
} = comparativeReportApi

export default comparativeReportApi
