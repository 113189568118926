import React, {FC, ReactElement} from 'react'
import {InputCell} from './InputCell'
import {isNestedCell} from '../helpers'
import {type DataGridControlCellProps} from 'ui-kit'
import {isEmpty, isString} from 'lodash'

export const ControlCell: FC<DataGridControlCellProps> = ({
  mode,
  children,
  doNotClone,
  ...props
}) => {
  if (isNestedCell(mode)) {
    const inputValue = React.Children.map(children, (child: ReactElement) => {
      if (!isString(child)) return child.props.children
      return child
    }).join('')

    if (doNotClone && isString(props.value) && !isEmpty(props.value)) {
      return (
        // @ts-ignore
        <InputCell {...props} doNotClone={doNotClone} type={mode}>
          {children}
        </InputCell>
      )
    }

    return (
      // @ts-ignore
      <InputCell {...props} doNotClone={doNotClone} type={mode} value={inputValue}>
        {children}
      </InputCell>
    )
  }

  return children as JSX.Element
}

export default ControlCell
