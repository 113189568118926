import FlexboxColumn from '@root/components/FlexboxColumn'
import {Task} from '@root/features/tasks/types'
import {Modal, baseTheme} from '@x5-react-uikit/core'
import React from 'react'
import {Typography} from 'ui-kit'
import {displayUser} from '@root/features/users/displayUser'

const {typography, colors} = baseTheme

type Props = {
  open: boolean
  onClose: () => void
  task: Task
}

const DisplayAssignee: React.FC<Props> = ({open, onClose, task}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onClose,
          side: 'right',
          text: 'Закрыть',
        },
      ]}
      isOpen={open}
      size="medium"
      title="Работа с заявкой"
      onClose={onClose}
    >
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography>
          <span>Менеджер:&nbsp;</span>
          <span style={task.manager ? typography.h4 : {color: colors.grey[60]}}>
            {task.manager ? displayUser(task.manager) : 'Не выбран'}
          </span>
        </Typography>
        <Typography>
          <span>Доступ к заявке:&nbsp;</span>
          <span style={task.usersWithAccess?.length ? typography.h4 : {color: colors.grey[60]}}>
            {task.usersWithAccess?.filter(Boolean).length
              ? task.usersWithAccess
                  .filter(Boolean)
                  .map((u) => u.email)
                  .join(', ')
              : 'Не выбран'}
          </span>
        </Typography>
      </FlexboxColumn>
    </Modal>
  )
}

export default DisplayAssignee
