import {match} from 'ts-pattern'

export type Error<TError> = {value: TError; isError: true}
export type Ok<TValue> = {value: TValue; isOk: true}
export type Result<TValue, TError> = Ok<TValue> | Error<TError>

function ok<TValue>(value: TValue): Ok<TValue> {
  return {
    isOk: true,
    value,
  }
}

function error<TError>(value: TError): Error<TError> {
  return {
    isError: true,
    value,
  }
}

function isError<TValue, TError>(res: Result<TValue, TError>): res is Error<TError> {
  return (res as Error<TError>).isError === true
}

function isOk<TValue, TError>(res: Result<TValue, TError>): res is Ok<TValue> {
  return (res as Ok<TValue>).isOk === true
}

function map<TValue, TError, TMappedValue>(
  result: Result<TValue, TError>,
  mapper: (value: TValue) => TMappedValue
): Result<TMappedValue, TError> {
  return match(result)
    .returnType<Result<TMappedValue, TError>>()
    .with({isOk: true}, ({value}) => ok(mapper(value)))
    .with({isError: true}, (err) => err)
    .exhaustive()
}

function mapError<TValue, TError, TMappedError>(
  result: Result<TValue, TError>,
  mapper: (value: TError) => TMappedError
): Result<TValue, TMappedError> {
  return match(result)
    .returnType<Result<TValue, TMappedError>>()
    .with({isOk: true}, (okValue) => okValue)
    .with({isError: true}, ({value}) => error(mapper(value)))
    .exhaustive()
}

function bind<T, U, TError>(
  result: Result<T, TError>,
  binder: (previousValue: T) => Result<U, TError>
): Result<U, TError> {
  return match(result)
    .with({isOk: true}, ({value}) => binder(value))
    .with({isError: true}, ({value}) => error(value))
    .exhaustive()
}

export const result = {
  ok,
  isError,
  isOk,
  error,
  map,
  mapError,
  bind,
}

export function aggregateResults<TValue, TError>(
  resList: Result<TValue, TError>[]
): Result<TValue[], TError[]> {
  return resList.reduce<Result<TValue[], TError[]>>((acc, next) => {
    return match([acc, next])
      .returnType<Result<TValue[], TError[]>>()
      .with([{isOk: true}, {isOk: true}], ([{value: okList}, {value: okValue}]) =>
        ok([...okList, okValue])
      )
      .with([{isOk: true}, {isError: true}], ([_, {value}]) => error([value]))
      .with([{isError: true}, {isError: true}], ([{value: errorList}, {value: errorValue}]) =>
        error([...errorList, errorValue])
      )
      .with([{isError: true}, {isOk: true}], ([{value}, _]) => error(value))
      .exhaustive()
  }, ok([]))
}
