import {components} from '@root/openapi'
import {FileUploaderItem} from 'ui-kit'

export enum FileType {
  IMAGE,
  ATTACHMENT,
}

export type FileWithComment = components['schemas']['FileWithCommentDto']

export type FormFileWithComment = FileWithComment & {
  file: [] | [FileUploaderItem]
}
