import {Typography} from '@x5-react-uikit/core'
import {useParams} from 'react-router-dom'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {useGetAllRolesQuery} from '@root/redux/api/userApi'

const SectionInitiator = () => {
  const {taskId} = useParams()

  const {data: task} = useGetTaskByIdQuery(taskId)
  const {data: rolesMap} = useGetAllRolesQuery()

  return (
    <>
      <Typography style={{marginBottom: '4px'}} variant="p1">
        {task?.creator?.email || 'Почта неизвестна.'}
      </Typography>
      <Typography style={{marginBottom: '4px'}} variant="p2">
        {rolesMap?.[task?.creator?.role] || 'Роль'}
      </Typography>
    </>
  )
}

export default SectionInitiator
