import {Roles} from '@root/constants'
import {FilterCollectorUserCombobox} from '@root/features/users/FilterCollectorUserCombobox'
import {FilterItemType} from '@root/openapi'
import {FC, useCallback} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'

const FilterCollectorManagerCombobox: FC<CustomFilterInputProps> = ({onChange, value, size}) => {
  const optionsMap = useCallback(
    (items) =>
      items?.map((x) => ({
        name: x.email,
        value: x.email,
      })) || [],
    []
  )

  return (
    <FilterCollectorUserCombobox
      comboboxProps={{
        usePortal: true,
      }}
      filter={{
        pageNumber: 1,
        pageSize: 9999999,
        filters: {role: {value: [Roles.MANAGER] as any, type: FilterItemType.EQUAL}},
      }}
      optionsMap={optionsMap}
      size={size}
      value={value}
      onChange={onChange}
    />
  )
}

export default FilterCollectorManagerCombobox
