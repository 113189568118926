import FlexboxColumn from '@root/components/FlexboxColumn'
import StepTitle from '@root/components/StepTitle'
import {TaskStatuses} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
} from '@root/redux/api/tasksApi'
import {Loader, Modal, Typography} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {match} from 'ts-pattern'
import {AssigneeFormValue} from '../../types'
import StakeholdersComboboxList from '../StakeholdersComboboxList'
import SubmitChangeStatus from './SubmitChangeStatus'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

const performerSelectWidthPx = 524

const ChangeToInProgressModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifySuccess, notifyError} = useNotify()
  const {data: userInfo} = useGetUserinfoQuery()

  const form = useForm<AssigneeFormValue>()
  form.watch()

  const {data: task} = useGetTaskByIdQuery(taskId)
  useEffect(() => {
    if (task?.usersWithAccess) form.reset({usersWithAccess: task.usersWithAccess})
  }, [form, task.usersWithAccess])
  const selectedStakeholders = form.getValues('usersWithAccess')

  const [approveModalShown, setApproveModalShown] = useState(false)
  const showApproveModal = () => {
    setApproveModalShown(true)
    onClose()
  }
  const closeApproveModal = () => setApproveModalShown(false)
  const cancelApproveModal = () => {
    closeApproveModal()
    onOpen()
  }

  const [updateTask, {isLoading: isTaskUpdating}] = useUpdateTaskMutation()
  const [updateTaskStatus, {isLoading: isTaskStatusUpdating}] = useUpdateTaskStatusMutation()
  const handleSetInProgress = async () => {
    if (!selectedStakeholders || isEmpty(selectedStakeholders)) {
      notifyError('Необходимо указать хотя бы одного специалиста')
      cancelApproveModal()
      return
    }

    try {
      if (form.formState.dirtyFields.usersWithAccess) {
        await updateTask({
          taskId,
          update: {usersWithAccess: selectedStakeholders.map((s) => s.id)},
        }).unwrap()
      }
      await updateTaskStatus({
        taskId,
        updateData: {nextTaskStatus: TaskStatuses.IN_PROGRESS as any},
      }).unwrap()
      notifySuccess(
        'Заявка в работе',
        'Статус заявки изменен на “В работе”. Вы можете продолжить работу с заявкой.'
      )
      closeApproveModal()
    } catch (error) {
      match(error)
        .with({status: 409, data: {messages: ['You need to add an performers']}}, () => {
          notifyError('Необходимо указать хотя бы одного специалиста')
          cancelApproveModal()
        })
        .with({status: 409, data: {messages: ['The system already has an active request']}}, () => {
          notifyError('Текущая ИС уже имеет активную заявку.')
        })
        .otherwise(() => {
          console.log(error)
          notifyError()
        })
    }
  }

  if (!userInfo) return <Loader />

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            disabled: !selectedStakeholders || isEmpty(selectedStakeholders),
            onClick: showApproveModal,
            side: 'right',
            text: 'Отправить в работу',
          },
        ]}
        description="Убедитесь, что все пункты для изменения статуса выполнены"
        isOpen={open}
        size="medium"
        title="Изменение статуса заявки"
        onClose={onClose}
      >
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxColumn sx={{gap: '16px'}}>
            <StepTitle
              completed={!!selectedStakeholders?.filter(Boolean).length}
              current={false}
              disabled={false}
              handleClick={() => {}}
              number={1}
              title="Найти специалиста для заявки"
            />
            <Typography variant="p1">
              <span>1.1. Осуществите поиск подходящего для заявки специалиста</span>
              <br />
              <span>
                1.2. После того, специалист найден, его необходимо выбрать среди прочих с помощью
                редактирования раздела заявки “Работа с заявкой”
              </span>
            </Typography>
            <StakeholdersComboboxList
              currentRole={userInfo.role}
              form={form}
              inputWidth={performerSelectWidthPx}
            />
          </FlexboxColumn>
        </FlexboxColumn>
      </Modal>
      <SubmitChangeStatus
        handleChangeStatus={handleSetInProgress}
        isSubmiting={isTaskUpdating || isTaskStatusUpdating}
        message="
          Статус заявки перейдет с “Поиск исполнителя” в “В работе”. 
          Вернуться к предыдущему статусу невозможно. 
          Участники заявки получат уведомление о смене статуса по почте
        "
        open={!open && approveModalShown}
        onCancel={cancelApproveModal}
        onClose={closeApproveModal}
      />
    </>
  )
}

export default ChangeToInProgressModal
