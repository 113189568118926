import FlexboxColumn from '@root/components/FlexboxColumn'
import {Typography} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import ReportImage from '@root/pages/report/ReportViewPage/components/ReportImage'
import {Report} from '@root/features/reports/types'
import {Fragment} from 'react'

type Props = {
  report: Report
}
export const StatisticsIframe = ({report}: Props) => {
  return (
    <>
      {report?.statistics.graphs?.map((stat, statIndex) => {
        const last = statIndex === report.statistics.graphs.length - 1
        return (
          <Fragment key={statIndex}>
            <FlexboxColumn sx={{gap: '16px'}}>
              <Typography variant="h3">{stat.graphName}</Typography>
              <iframe src={stat.graphUrl} style={{height: '400px'}} title={stat.graphName}></iframe>
            </FlexboxColumn>
            {!last && <Divider />}
          </Fragment>
        )
      })}
      {report?.statistics.fileFields?.map((fileField, fieldIndex) => {
        const last = fieldIndex === report.statistics.fileFields.length - 1
        return (
          <Fragment key={fieldIndex}>
            <ReportImage fileField={fileField} />
            {!last && <Divider />}
          </Fragment>
        )
      })}
    </>
  )
}
