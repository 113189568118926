import {Report} from './types'

export type ReportToCompareRow = {
  number: number
  id: string
  name: string
  task: {
    id: string
    number: string
  }
  test: {
    id: string
    number: string
  }
  template: {
    id: string
    name: string
  }
  testFinishedAt: string
}

export function reportToCompareRow(report: Report, number: number): ReportToCompareRow {
  return {
    number,
    id: report.id,
    name: report.name,
    task: {
      id: report.taskId,
      number: report.taskNumber.toString(),
    },
    template: {
      id: report.variablesTemplateId,
      name: report.variablesTemplateName,
    },
    test: {
      id: report.launchId,
      number: report.testNumber,
    },
    testFinishedAt: report.testFinishedAt,
  }
}

export function getId(x: ReportToCompareRow): string {
  return x.id
}
